
import {Component, Vue} from "vue-property-decorator";

import PanelWrapper from "@/components/PanelWrapper.vue";
import User from "@/components/User.vue";
import Skeleton from "@/components/Skeleton/index.vue";

import statList from "@/data/statList";

import { MailStateI } from "@/store/mail";

@Component({
  components: {
    PanelWrapper,
    User,
    Skeleton
  }
})
export default class StatInfo extends Vue {

  get dataPanel(): {
    id: string,
    status: string,
    count: number
  } {
    return this.$store.state.app.dataPanel;
  }

  get statName() {
    const index = statList.findIndex((item) => {
      return item.status === this.dataPanel.status;
    });

    return statList[index].name;
  }

  get stat(): MailStateI["stat"] {
    return this.$store.state.mail.stat;
  }

  async created() {
    const { id, status } = this.dataPanel;

    return this.$store.dispatch('loadMailStat', {
      id,
      params: {
        status,
        count: 100,
        offset: 0
      }
    });
  }

  sendStat() {
    this.$store.dispatch('setActivePanel', { panel: "statSend", dataPanel: this.dataPanel });
  }

}
