<template>
  <div class="avatar" :style="{ 'min-width': toPx(size), 'max-width': toPx(size), 'min-height': toPx(size), 'max-height': toPx(size) }">
    <img
      class="avatar-img"
      :src="avatarSrc"
      alt=""
      @error="replaceOnError"
    />
    <div v-if="indicatorExist" :class="`indicator indicator-${size}`">
      <slot name="indicator">
        <img v-if="transportIcon" :src="transportIcon" alt="" />
      </slot>
    </div>
  </div>
</template>

<script>
import cdnSource from "@/functions/cdnSource";

const transportMap = {
  FLChat: "@/assets/img/social/fl_chat.svg",
  WhatsApp: "@/assets/img/social/whatsapp.svg",
  Telegram: "@/assets/img/social/telegram.svg",
  VK: "@/assets/img/social/vk.svg",
  Viber: "@/assets/img/social/viber.svg"
};

export default {
  props: {
    size: {
      type: Number,
      default: 36
    },
    src: String,
    transport: [String, Boolean],
    userId: String
  },
  computed: {
    indicatorExist() {
      return !!this.transport;
    },
    transportIcon() {
      if (!this.indicatorExist) {
        return false;
      }

      return cdnSource(transportMap[this.transport]);
    },
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    avatarSrc() {
      if (this.src) {
        return this.src;
      }

      return `${this.baseUrl}/users/${this.userId}/avatar`;
    }
  },
  methods: {
    toPx(size) {
      return size + 'px';
    },
    replaceOnError(e) {
      e.target.src = cdnSource('@/assets/img/default_avatar.svg');
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;

  &-img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    border-radius: 50%;
  }

  .indicator {
    width: 18px;
    height: 18px;

    padding: 2px;

    position: absolute;
    right: -2px;
    bottom: -2px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: #ffffff;

    &-60 {
      width: 23px;
      height: 23px;

      right: -5px;
      bottom: -5px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
