<template>
  <div class="chat">
    <Chat :userId="selectedContact.user_id"></Chat>
  </div>
</template>

<script>
import Chat from "@/components/Chat";

export default {
  components: {
    Chat
  },
  computed: {
    selectedContact() {
      return this.$store.getters['getSelectedContact'];
    }
  }
};
</script>

<style lang="scss" scoped>
.chat {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
</style>
