import { TranslateResult } from "vue-i18n";

const statList: Array<{
  key: string,
  status: string,
  name: TranslateResult,
  percent?: number
}> = [
  {
    key: 'recipient_count',
    status: 'recipient',
    name: 'recipient'
  },
  {
    key: 'sent_count',
    status: 'sent',
    name: 'sent'
  },
  {
    key: 'delivered_count',
    status: 'delivered',
    name: 'delivered'
  },
  {
    key: 'read_count',
    status: 'read',
    name: 'read'
  },
  {
    key: 'not_delivered_count',
    status: 'not_delivered',
    name: 'notDelivered'
  },
  {
    key: 'failed_count',
    status: 'failed',
    name: 'failed'
  },
  {
    key: 'no_data_found_count',
    status: 'info_not_found',
    name: 'notFound'
  }
];

export default statList;
