var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:{
      'c-structure': true,
      'c-divider': _vm.divider,
      'c-structure-active': _vm.active,
      'c-structure-disabled': _vm.disabled
    }},[_c('div',{staticClass:"c-structure__inner",on:{"click":function($event){!_vm.disabled && _vm.$emit('click')}}},[_c('Avatar',{attrs:{"src":_vm.avatarUrl,"transport":_vm.source.preferred_transport,"size":36}}),_c('div',{staticClass:"info",style:(`margin-right: ${_vm.isAfterShown ? 30 : 0}px`)},[_c('div',{staticClass:"name"},[_c('span',{class:{ 'name-personal': _vm.disableBroadcast }},[_vm._v(_vm._s(_vm.source.full_name))]),(_vm.source.has_childs)?_c('img',{staticClass:"name__structure",attrs:{"src":_vm.cdn.hasChilds,"alt":""}}):_vm._e(),(_vm.source.broadcast_prohibition || _vm.source.personal_prohibition)?_c('img',{staticClass:"name__lock",attrs:{"src":_vm.cdn.lock,"alt":""}}):_vm._e()]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.source.rank))])])],1),(_vm.isAfterShown)?[(!_vm.isSelect)?_c('div',{staticClass:"after",on:{"mouseover":function($event){_vm.showAction = true},"mouseout":function($event){_vm.showAction = false}}},[_c('img',{attrs:{"src":_vm.cdn.after,"alt":""}})]):_vm._e(),(_vm.isSelect && !_vm.disableBroadcast)?_c('div',{staticClass:"after",on:{"mouseover":function($event){_vm.showAction = true},"mouseout":function($event){_vm.showAction = false}}},[_c('checkbox',{attrs:{"value":_vm.isSelected},nativeOn:{"click":function($event){return _vm.handleCheckbox.apply(null, arguments)}}})],1):_vm._e(),(!_vm.isSelect || !_vm.structureSelected && !_vm.isSelected)?_c('div',{class:{
          'action': true,
          'action-checkbox': true,
          'action-shown': _vm.showAction
        },on:{"mouseover":function($event){_vm.showAction = true},"mouseout":function($event){_vm.showAction = false}}},[_c('div',{staticClass:"action__popup"},[(!_vm.isSelect)?[_c('div',{staticClass:"item",on:{"click":_vm.goProfile}},[_vm._v(_vm._s(_vm.$t('structure.profile')))]),_c('div',{staticClass:"item",on:{"click":_vm.goChat}},[_vm._v(_vm._s(_vm.$t('structure.chat')))])]:[_c('div',{staticClass:"item",on:{"click":(e) => _vm.addUserToSelect(e, _vm.source, 'user')}},[_vm._v("Выбрать пользователя")]),_c('div',{staticClass:"item",on:{"click":(e) => _vm.addUserToSelect(e, _vm.source, 'structure')}},[_vm._v("В прямом подчинении")]),_c('div',{staticClass:"item",on:{"click":(e) => _vm.addUserToSelect(e, _vm.source, 'all')}},[_vm._v("Всю структуру")])]],2)]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"c-structure-list"},[_vm._t("list")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }