
import store from "@/store";
import { SegmentsUserI } from "@/types/segments";
import {Component, Prop, Vue} from "vue-property-decorator";

import Structure from "@/components/Structure.vue";

import getStructureSelected from "@/functions/getStructureSelected";

import { AppStateI } from "@/store/app";
import { StructureUserI } from "@/types/structure";

interface IProps {
  index?: number,
  source: {
    users: Array<StructureUserI>,
    childs: Array<StructureUserI>
  }
}

@Component({
  components: {
    Structure
  }
})
export default class StructureVirtual extends Vue {

  @Prop() index: IProps["index"];
  @Prop({ default: {} }) source!: IProps["source"];

  get me() {
    return this.$store.getters['getMe'];
  }

  get structure() {
    return this.$store.getters['getStructure'];
  }

  get user() {
    return this.structure.childs.user;
  }

  get app(): AppStateI {
    return this.$store.state.app;
  }

  get meIndex() {
    return this.source.users.findIndex((item) => item.user_id === this.me.user_id);
  }

  get isMailSelect() {
    return this.$store.getters['getSegmentsSelect'];
  }

  get segmentsSelected() {
    return this.$store.getters['getSegmentsSelected'];
  }

  get segmentsExcluded() {
    return this.$store.getters['getSegmentsExcluded'];
  }

  findUserInSelected(item: StructureUserI): {
    type: string,
    user: StructureUserI
  } | null {
    const segmentsSelected = { ...store.getters['getSegmentsSelected'] };

    if (!item) {
      return null;
    }

    let user = null;

    Object.keys(segmentsSelected).forEach((segment: string) => {
      segmentsSelected[segment].forEach((_segmentItem: SegmentsUserI | StructureUserI) => {
        const segmentItem = _segmentItem as StructureUserI;

        if (segmentItem.user_id === item.user_id) {
          user = {
            type: segment,
            user: segmentItem
          };
        }
      });
    });

    return user;
  }

  getStructureSelected(type: string, item: StructureUserI) {
    const findUser = this.findUserInSelected(item);

    let isSelected = false;

    if (findUser) {
      isSelected = true;
    }

    const findExcludeIndex = this.segmentsExcluded.findIndex((_item: StructureUserI) => _item.user_id === item.user_id);

    if (findExcludeIndex !== -1) {
      return false;
    }

    const { users } = this.source;

    let lastUserIndex = 0;

    let findLastUser = this.findUserInSelected(users[lastUserIndex]);

    while (!findLastUser && lastUserIndex < users.length) {
      lastUserIndex += 1;

      findLastUser = this.findUserInSelected(users[lastUserIndex]);
    }

    if (!findLastUser) {
      lastUserIndex = -1;
      findLastUser = this.findUserInSelected(this.user);
    }

    if (
      type === 'parent'
      || type === 'user'
    ) {
      const currentIndex = users.findIndex((_item) => _item.user_id === item.user_id);

      if (!findLastUser || (currentIndex < lastUserIndex && currentIndex !== -1)) {
        return isSelected;
      }

      if (findLastUser.type === 'all') {
        isSelected = true;
      }

      if (findLastUser.type === 'structure') {
        if (type === 'parent' && (currentIndex - lastUserIndex === 1)) {
          isSelected = true;
        }

        if (type === 'user' && lastUserIndex === users.length - 1) {
          isSelected = true;
        }
      }
    }

    if (type === 'child') {
      if (!findLastUser) {
        return isSelected;
      }

      if (findLastUser.type === 'all') {
        isSelected = true;
      }

      if (findLastUser.type === 'structure') {
        if (lastUserIndex === -1) {
          isSelected = true;
        }
      }
    }

    return isSelected;
  }

  isStructureSelected(type: string) {
    const { users } = this.source;

    let lastUserIndex = 0;
    let isSelected = false;

    let findLastUser = this.findUserInSelected(users[lastUserIndex]);

    while (!findLastUser && lastUserIndex < users.length) {
      lastUserIndex += 1;

      findLastUser = this.findUserInSelected(users[lastUserIndex]);
    }

    if (!findLastUser) {
      lastUserIndex = -1;
      findLastUser = this.findUserInSelected(this.user);
    }

    if (!findLastUser) {
      return isSelected;
    }

    if (findLastUser.type === 'all') {
      isSelected = true;
    }

    if (findLastUser.type === 'structure') {
      if (type === 'user' && lastUserIndex === users.length - 1) {
        isSelected = true;
      }

      if (type === 'child' && lastUserIndex === -1) {
        isSelected = true;
      }
    }

    return isSelected;
  }

  handleCheckbox({ item, selected }: { item: StructureUserI, selected: boolean }) {
    const segmentsSelected = { ...this.segmentsSelected };

    if (!selected) {
      Object.keys(segmentsSelected).forEach((segment) => {
        const findIndex = segmentsSelected[segment].findIndex((_item: StructureUserI) => _item.user_id === item.user_id);

        if (findIndex === -1) {
          return;
        }

        segmentsSelected[segment].splice(findIndex, 1);
      });

      this.$store.commit('setSegmentsSelected', segmentsSelected);
    } else {
      const segmentsExcluded = [ ...this.segmentsExcluded ];

      const findIndex = segmentsExcluded.findIndex((_item: StructureUserI) => _item.user_id === item.user_id);

      if (findIndex === -1) {
        segmentsExcluded.push({ ...item });
      } else {
        segmentsExcluded.splice(findIndex, 1);
      }

      this.$store.commit('setSegmentsExcluded', segmentsExcluded);
    }
  }

  changeStructure(userId: string) {
    this.$store.dispatch('loadStructure', { userId, updateParents: false });
  }

}
