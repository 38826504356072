<template>
  <div class="writebar" >
    <div v-if="file" class="preview">
      <img v-if="fileType === 'image'" :src="imagePreview" alt="" />
      <div v-else class="document">
        <img :src="types[fileType]" alt="">
        {{ file.name }}
      </div>
      <img class="remove" :src="icon.remove" alt="" @click="removeFile">
    </div>
    <div class="input">
      <input :accept="acceptFiles" type="file" ref="file" @change="fileSelected" hidden />
      <img
        :class="{ 'icon': true, 'icon-disabled': readOnly }"
        :title="$t('tooltips.attach')"
        :src="icon.clip"
        alt=""
        @click="!readOnly && choiceFile()"
      >
      <div :class="{ 'emoji': true, 'emoji-disabled': readOnly }">
        <img class="icon" :src="icon.emoji" alt="">
        <div class="emoji__wrapper">
          <div class="emoji__manager">
            <div
              v-for="item in emojiList"
              :key="item.slug"
            >
              <span class="emoji__caption">{{ $t(`emoji.${item.slug}`) }}</span>
              <div class="emoji__list">
              <span
                class="emoji__list__icon"
                v-for="emoji in item.emojis"
                :key="emoji"
                @click="pasteEmoji(emoji)"
              >
                {{ emoji }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <textarea
        v-model="text"
        ref="textarea"
        :placeholder="$t('placeholders.message')"
        :disabled="loading || readOnly"
        @keydown.enter.exact.prevent
        @keydown.enter.exact="send"
      ></textarea>
      <div class="send">
        <img
          v-if="!loading"
          :class="{ 'icon': true, 'icon-disabled': readOnly }"
          :title="$t('tooltips.send')"
          :src="icon.write"
          alt=""
          @click="!readOnly && send()"
        >
        <Spinner v-else class="loading" :absolute="false" />
      </div>
    </div>
  </div>
</template>
<script>
import cdnSource from "@/functions/cdnSource";

import Spinner from "@/components/Spinner";

import emojiList from "@/data/emojiList";
import getBase64 from "@/functions/getBase64";

export default {
  name: 'WriteBar',
  components: {
    Spinner
  },
  data() {
    return {
      acceptFiles: 'image/png,image/jpg,image/jpeg,image/heic,image/heif,application/zip,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,application/x-zip,application/x-zip-compressed',
      text: '',
      loading: false,
      file: null,
      imagePreview: null,
      icon: {
        clip: cdnSource('@/assets/img/writebar/clip.svg'),
        emoji: cdnSource('@/assets/img/writebar/emoji.svg'),
        write: cdnSource('@/assets/img/writebar/write.svg'),
        remove: cdnSource('@/assets/img/writebar/remove.svg')
      },
      types: {
        doc: cdnSource('@/assets/img/attachment/doc.svg'),
        docx: cdnSource('@/assets/img/attachment/doc.svg'),
        pptx: cdnSource('@/assets/img/attachment/pptx.svg'),
        xls: cdnSource('@/assets/img/attachment/xls.svg'),
        xlsx: cdnSource('@/assets/img/attachment/xlsx.svg'),
        zip: cdnSource('@/assets/img/attachment/zip.svg'),
        pdf: cdnSource('@/assets/img/attachment/zip.svg')
      },
      emojiList,
      lastCaretIndex: 0
    };
  },
  mounted() {
    this.$refs.textarea.focus();

    console.log('readOnly', this.readOnly);
  },
  computed: {
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    fromId() {
      return this.$store.getters['getMeUserId'];
    },
    fileType() {
      const file_name = this.file.name;
      const mime_type = this.file.type;

      const nameType = file_name && file_name.substring(file_name.lastIndexOf(".") + 1);
      const mimeType = mime_type && mime_type.substring(mime_type.lastIndexOf("/") + 1);
      const type = nameType || mimeType;

      if (['jpg', 'jpeg', 'png', 'heif', 'hevc'].indexOf(type) !== -1) {
        return "image";
      }

      return type ? type : "image";
    },
    readOnly() {
      return this.$store.getters['getReadOnly'];
    }
  },
  methods: {
    pasteEmoji(emoji) {
      const index = this.$refs.textarea.selectionEnd;

      const text = this.text;

      this.text = text.slice(0, index) + emoji + text.slice(index);

      this.$refs.textarea.focus();
      this.$nextTick(() => {
        this.$refs.textarea.setSelectionRange(index + emoji.length,  index + emoji.length);
      });
    },
    async fileSelected(e) {
      if (e.target.files.length > 0) {
        this.file = e.target.files[0];
      }

      if (!this.file) {
        return;
      }

      const { type, size } = this.file;

      if (this.acceptFiles.indexOf(type) === -1) {
        await this.$store.dispatch('sendNotification', {
          type: 'error',
          text: 'Добавление файлов такого типа запрещено'
        });

        this.removeFile();

        return;
      }

      if (
        size === 0
        || size > 1024000
      ) {
        await this.$store.dispatch('sendNotification', {
          type: 'error',
          text: size === 0 ? 'Файл пустой' : 'Файл слишком большой'
        });

        this.removeFile();

        return;
      }

      this.imagePreview = await getBase64(this.file);
    },
    imageSize(fileId) {
      const src = `${this.baseUrl}/file/${fileId}`;

      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
          const { naturalWidth: width, naturalHeight: height } = img;
          resolve({ width, height });
        };
        img.onerror = () => {
          reject('There was some problem with the image.');
        };

        img.src = src;
      });
    },
    choiceFile() {
      this.$refs.file.click();
    },
    removeFile() {
      this.imagePreview = null;
      this.file = null;
      this.$refs.file.value = "";
    },
    async send() {
      if (this.text.length === 0 && this.file === null) {
        return;
      }

      if (this.loading) {
        return;
      }

      this.loading = true;

      const {user_id} = this.$store.getters['getSelectedContact'];

      let file = undefined;

      if (this.file) {
        const { name, type } = this.file;
        const fileBase64 = await getBase64(this.file);
        const isImage = this.fileType === "image";

        file = {
          type: isImage ? "image" : "document",
          file_name: name,
          data: fileBase64.replace(`data:${this.file.type};base64,`, ""),
          mime_type: type
        };
      }

      const { data } = await this.$store.dispatch('sendMessage', {
        toUserId: user_id,
        text: this.text,
        file
      });

      await this.addMessage(data.message_id, data.file_id);
      this.$parent.setChatToBottom();

      this.text = '';
      this.loading = false;
    },
    async addMessage(messageId, fileId) {
      let file = {};

      if (fileId) {
        const { name, type } = this.file;

        let width, height = null;

        if (this.fileType === "image") {
          const size = await this.imageSize(fileId);

          width = size.width;
          height = size.height;
        }

        file = {
          file_id: fileId,
          file_name: name,
          width,
          height,
          mime_type: type
        };
      }

      this.removeFile();

      const msg = {
        file,
        from: this.fromId,
        id: messageId,
        incoming: false,
        is_read: false,
        text: this.text,
        tm: this.$moment().utcOffset(0).format(),
        transport: "FLChat"
      };

      this.$store.commit('addMessageItem', msg);
      this.$emit('message:add', msg);
    }
  }
};
</script>

<style lang="scss" scoped>
.writebar {
  width: 100%;

  position: relative;

  padding: 0 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 12px;
  background: #EFEFEF;

  .preview {
    padding: 10px 0;

    position: relative;

    display: flex;
    justify-content: center;

    &::before {
      width: calc(100% + 16px * 2);
      height: 1px;

      position: absolute;
      left: -16px;
      bottom: 0;

      content: "";

      background: #E7E8EC;
    }

    img {
      width: 77px;
      height: 77px;

      border-radius: 4px;
      background: #EFEFEF;
    }

    .remove {
      width: 20px;
      height: 20px;

      position: absolute;
      top: 10px;
      right: 0;

      cursor: pointer;
    }

    .document {
      display: flex;
      flex-direction: column;
      align-items: center;

      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 20px;

      text-align: center;

      img {
        width: 40px;
        height: 40px;

        margin: 10px 0;
      }
    }
  }

  .input {
    width: 100%;

    margin: 12px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .icon {
      cursor: pointer;

      &-disabled {
        opacity: .7;

        cursor: default;
      }
    }

    .send {
      position: relative;

      .loading {
        width: 17px;
        height: 17px;
      }
    }

    textarea {
      width: 100%;
      height: 24px;

      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      outline: none;
      border: none;

      resize: none;

      background: transparent;

      &::placeholder {
        color: #929292;
      }
    }
  }

  .emoji {
    position: relative;

    display: flex;

    &__wrapper {
      width: 200px;
      height: 250px;

      padding-bottom: 25px;

      position: absolute;
      left: -41px;
      bottom: 18px;

      opacity: 0;
      visibility: hidden;

      transition: .3s ease;
    }

    &__manager {
      width: 100%;
      height: 100%;

      padding: 10px;

      display: flex;
      flex-direction: column;
      gap: 15px;

      overflow-x: hidden;
      overflow-y: auto;

      border: 1px solid #EFEFEF;
      border-radius: 8px;

      background: #FFFFFF;
    }

    &__caption {
      padding: 0 3px;

      font-family: 'Proxima Nova', sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 12px;
      line-height: 15px;

      color: #585757;
    }

    &__list {
      margin-top: 5px;

      display: grid;
      grid-template-columns: repeat(7, 25px);
      gap: 5px 0;

      &__icon {
        width: 16px;
        height: 16px;

        display: flex;
        align-items: center;
        justify-content: center;

        user-select: none;

        cursor: pointer;

        transition: .3s ease;

        border-radius: 5px;

        &::v-deep {
          img {
            width: 16px;
            height: 16px;
          }
        }

        &:hover {
          background: #EFEFEF;
        }
      }
    }

    &:hover {
      .emoji__wrapper {
        opacity: 1;
        visibility: visible;
      }
    }

    &-disabled {
      &:hover {
        .emoji__wrapper {
          opacity: 0;
          visibility: hidden;
        }
      }

      .icon {
        opacity: .7;

        cursor: default;
      }
    }
  }
}
</style>
