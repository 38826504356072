
import cdnSource from "@/functions/cdnSource";
import { Component, Vue, Watch } from "vue-property-decorator";

import { NotificationStateI } from "@/store/notification";

interface IProps {
  timeout?: number
}

@Component
export default class Notification extends Vue {

  open = false;

  timeout: IProps["timeout"];
  timeoutEnded = false;

  cdn = {
    success: cdnSource('@/assets/img/notification/success.svg'),
    error: cdnSource('@/assets/img/notification/error.svg')
  };

  get notification(): NotificationStateI {
    return this.$store.state.notification;
  }

  @Watch('notification', { deep: true })
  onNotificationChange() {
    const { timeoutEnded } = this;

    this.timeoutEnded = false;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // If notify isn't closed, we need to animate close and reopen it
    if (!timeoutEnded) {
      this.open = false;

      setTimeout(() => this.open = true, 500);
    } else {
      this.open = true;
    }

    this.timeout = setTimeout(() => {
      this.open = false;
      this.timeoutEnded = true;
    }, 3000);
  }

}
