import { UserI } from "@/types/user";
import axios from "axios";
import {
  Action,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";

import { MailItem } from "@/types/mail";

export interface MailStateI {
  history: {
    messages: Array<MailItem>,
    max_count: number,
    offset: number,
    count: number,
    req_count: number,
    started_from?: string,
    last_message_id: string,
    total_count: number
  } | null,
  info: MailItem | null,
  stat: {
    id: string,
    status: string,
    recipients: Array<{
      user: UserI,
      message_status: string,
      to_transport: string
    }> | null
  } | null
}

@Module
export default class Mail extends VuexModule<MailStateI> {

  history: MailStateI["history"] = null;
  info: MailStateI["info"] = null;
  stat: MailStateI["stat"] = null;

  @Mutation
  setMailHistory(history: MailStateI["history"]) {
    this.history = history;
  }

  @Mutation
  setMailInfo(info: MailStateI["info"]) {
    this.info = info;
  }

  @Mutation
  setMailStat(stat: MailStateI["stat"]) {
    this.stat = stat;
  }

  @Action
  async loadMailHistory() {
    const { commit } = this.context;

    const { data } = await axios.get("/message/sent");

    commit('setMailHistory', data);
  }

  @Action
  async loadMailInfo(id: string) {
    const { commit } = this.context;

    const { data } = await axios.get("/message/sent/" + id);

    commit('setMailInfo', data);
  }

  @Action
  async loadMailStat(
    payload: {
      id: string,
      params: {
        status: string,
        count: number,
        offset: number
      }
    }
  ) {
    const { commit } = this.context;
    const { status } = payload.params;

    const { data } = await axios.get("/message/recipients/" + payload.id, {
      params: {
        ...payload.params,
        status: status !== 'recipient' ? status : null
      }
    });

    console.log(payload.params.status);

    commit('setMailStat', {
      id: payload.id,
      status: payload.params.status,
      recipients: data.recipients || []
    });
  }

}
