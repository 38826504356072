
import {Component, Vue} from "vue-property-decorator";

import {AppStateI} from "@/store/app";
import cdnSource from "@/functions/cdnSource";

interface IState {
  cdn: {
    [key: string]: string
  }
}

@Component
export default class BlockBalance extends Vue {

  cdn: IState["cdn"];

  constructor() {
    super();

    this.cdn = {
      close: cdnSource("@/assets/img/app/close.svg")
    };
  }


  get app(): AppStateI {
    return this.$store.state.app;
  }

  close() {
    this.$store.dispatch('goBackHistory');
  }

}
