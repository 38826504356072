<template>
  <div>
    <div class="stat">
      <div
        v-for="(_, index) in new Array(7).fill(null)"
        class="item"
        :key="index"
      >
        <div class="info">0%</div>
        <skeleton
          class="progress"
          type="rect"
          :height="37"
          :rounded="true"
        />
      </div>
    </div>
    <div class="transports">
      <div class="caption">Каналы</div>
      <div class="list">
        <div
          v-for="(_, index) in new Array(3).fill(null)"
          class="item"
          :key="index"
        >
          <skeleton
            class="icon"
            type="circle"
            :size="34"
          />
          0%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "@/components/Skeleton";

export default {
  components: {
    Skeleton
  }
};
</script>

<style lang="scss" scoped>
.mail-info {
  padding: 60px 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  overflow-y: auto;

  &__inner {
    width: 400px;

    padding: 25px 20px;

    border-radius: 24px;

    background: #FEFEFE;

    @media screen and (max-width: 850px) {
      width: 100%;

      padding: 0 20px;
    }
  }

  .title {
    margin: 20px 0;

    font-family: "Proxima Nova", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    text-align: center;

    color: #1F1F1F;
  }

  .transports {
    padding: 20px 0;

    position: relative;

    &::before {
      width: calc(100% + 20px * 2);
      height: 1px;

      position: absolute;
      top: 0;
      left: -20px;

      content: "";

      background: rgba(#000000, .05);
    }

    .caption {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      color: #585757;
    }

    .list {
      margin-top: 15px;

      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .item {
        display: flex;
        align-items: center;
        gap: 8px;

        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        color: #585757;

        .icon {
          width: 34px;
          height: 34px;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 50%;

          color: royalblue;
          background: #1F1F1F;
        }
      }
    }
  }

  .balance {
    padding: 20px 0;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before, &::after {
      width: calc(100% + 20px * 2);
      height: 1px;

      position: absolute;
      left: -20px;

      content: "";

      background: rgba(#000000, .05);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    .info {
      .amount {
        font-family: "Proxima Nova", sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      .caption {
        display: flex;
        align-items: center;
        gap: 4px;

        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        color: #585757;

        .question {
          width: 13px;
          height: 13px;

          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;

          border-radius: 50%;

          background: #585757;
        }
      }
    }

    .status {
      padding: 6px 12px;

      display: flex;
      align-items: center;
      gap: 6px;

      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      border: none;
      border-radius: 20px;

      color: #FFFFFF;

      &_complete {
        background: #67BF8B;
      }

      &_in_progress {
        background: #7A7A7A;
      }

      &_queued {
        background: #E27F37;
      }
    }
  }

  .stat {
    margin-bottom: 34px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    .item {
      display: flex;
      align-items: center;
      gap: 14px;

      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      .info {
        min-width: 35px;
      }

      .progress {
        width: 100%;

        padding: 10px 20px;

        position: relative;

        border-radius: 20px !important;

        overflow: hidden;

        background: #ECECEC;

        .active {
          height: 100%;

          position: absolute;
          top: 0;
          left: -50%;

          z-index: 15;

          border-radius: 20px;

          &_recipient_count {
            background: #ECECEC;
          }

          &_sent_count {
            background: #CCE1F4;
          }

          &_delivered_count {
            background: #BBF2DD;
          }

          &_read_count {
            background: #8EEACE;
          }

          &_not_delivered_count {
            background: #FFDC81;
          }

          &_failed_count {
            background: #FF794E;
          }

          &_no_data_found_count {
            background: #B76FFF;
          }
        }

        .text {
          position: relative;

          z-index: 20;
        }
      }
    }
  }
}
</style>
