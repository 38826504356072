
import { Component, Prop, Vue } from "vue-property-decorator";

import cdnSource from "@/functions/cdnSource";

@Component
export default class PanelWrapper extends Vue {

  @Prop({ default: false }) disableBack!: boolean;

  cdn = {
    chevronBack: cdnSource('@/assets/img/app/chevron-back.svg')
  };

  get app() {
    return this.$store.state.app;
  }

  get hasBackListener() {
    return this.$listeners && this.$listeners.back;
  }

  goBack() {
    if (this.hasBackListener) {
      this.$emit('back');
      return;
    }

    this.$store.dispatch('goBackHistory');
  }

}
