import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from "axios";

import {UserI} from "@/types/user";
import {ConfigI} from "@/types/config";

interface UserStateI {
  currentUser: UserI | null,
  currentConfig?: ConfigI | null,
  search: Array<UserI> | null,
  selectedProfile: UserI | null
}

@Module
export default class User extends VuexModule<UserStateI> {
  currentUser: UserStateI["currentUser"] = null;
  currentConfig: UserStateI["currentConfig"] = null;
  search: UserStateI["search"] = null;
  selectedProfile: UserStateI["selectedProfile"] = null;

  get getSearch() {
    return this.search;
  }

  get getMe() {
    return this.currentUser;
  }

  get getConfig() {
    return this.currentConfig;
  }

  get getMeUserId() {
    return this.currentUser ? this.currentUser.user_id : null;
  }

  get getProfile() {
    return this.selectedProfile;
  }

  @Mutation
  setSearch(search: UserStateI["search"]) {
    this.search = search;
  }

  @Mutation
  setMe(user: any) {
    this.currentUser = user;
  }

  @Mutation
  setCurrentConfig(config: any) {
    this.currentConfig = config;
  }

  @Mutation
  setSelectedProfile(profile: any) {
    this.selectedProfile = profile;
  }

  @Action({ commit: 'setMe' })
  async loadMe() {
    const { data } = await axios.get("profile");
    return data;
  }

  @Action({ commit: 'setCurrentConfig' })
  async loadConfig() {
    const { data } = await axios.get("/auth/config");
    const config = JSON.parse(data.config);

    const keys = Object.keys(config.interface);
    keys.forEach((key) => {
      const item = config.interface[key];

      if (
        item === "1"
        || item === "0"
      ) {
        config.interface[key] = item === "1";
      }
    });

    return config;
  }

  @Action({ commit: 'setSearch' })
  async loadSearch(
    payload: {
      type: string,
      params: {
        search: string,
        count?: number,
        offset?: number
      }
    }
  ) {
    const params = {
      count: 100,
      offset: 0,
      ...payload.params
    };

    const { data } = await axios.get(`/users${payload.type}`, {
      params: {
        ...params
      }
    });

    if (data.offset !== 0 && this.search) {
      return [...this.search, ...data.users];
    } else {
      return data.users;
    }
  }

  @Action({ commit: 'setSelectedProfile' })
  async loadProfile(payload?: { userId: string }) {
    if (!payload) {
      return;
    }

    const { data } = await axios.get("/users/" + payload.userId);
    return data;
  }
}
