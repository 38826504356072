var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'notification__wrapper': true,
    [`notification__wrapper--position-${_vm.notification.position}`]: true
  }},[_c('div',{class:{
      'notification': true,
      'notification-active': _vm.open
    }},[_c('img',{attrs:{"src":_vm.notification.type === 'success' ? _vm.cdn.success : _vm.cdn.error,"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.notification.text))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }