
import {Component, Prop, Vue} from "vue-property-decorator";

import cdnSource from "@/functions/cdnSource";

import {MailItem} from "@/types/mail";

interface IProps {
  data: MailItem
}

interface IState {
  cdn: {
    [key: string]: string
  },
  expanded: boolean
}

@Component
export default class MailHistoryItem extends Vue {

  @Prop({ required: true }) data!: IProps["data"];

  cdn: IState["cdn"];
  expanded: IState["expanded"] = false;

  constructor() {
    super();

    this.cdn = {
      done: cdnSource("@/assets/img/profile/mail-history/done.svg"),
      mail: cdnSource("@/assets/img/profile/mail-history/mail.svg")
    };
  }

  get isShort() {
    return this.data.text.length >= 192;
  }

  get shortText() {
    const text = this.data.text
      .replaceAll(new RegExp('<[^>]*>', 'g'), '')
      .slice(0, 128);

    return text + "...";
  }

  time(tm: Date) {
    return this.$moment(tm)
      .utc(true)
      .local()
      .format('D MMMM HH:mm:ss');
  }

  expand(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.expanded = !this.expanded;
  }

}
