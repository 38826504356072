
import {Component, Vue} from "vue-property-decorator";

import cdnSource from "@/functions/cdnSource";

import PanelWrapper from "@/components/PanelWrapper.vue";
import SkeletonMailInfo from "@/components/Skeleton/MailInfo.vue";

import statList from "@/data/statList";

import { ConfigI } from "@/types/config";
import { MailItem } from "@/types/mail";
import { MailStateI } from "@/store/mail";

interface IState {
  cdn: {
    [key: string]: string | IState["cdn"]
  }
}

@Component({
  components: {
    PanelWrapper,
    SkeletonMailInfo
  }
})
export default class MailInfo extends Vue {

  cdn: IState["cdn"] = {
    info: cdnSource("@/assets/img/profile/mail-info/info.svg"),
    ruble: cdnSource("@/assets/img/profile/ruble_black.svg"),
    status: {
      done: cdnSource("@/assets/img/profile/mail-history/done.svg"),
      mail: cdnSource("@/assets/img/profile/mail-history/mail.svg")
    },
    transports: {
      FLChat: cdnSource("@/assets/img/profile/mail-info/fl.svg"),
      Telegram: cdnSource("@/assets/img/profile/mail-info/telegram.svg"),
      VK: cdnSource("@/assets/img/profile/mail-info/vk.svg"),
      Email: cdnSource("@/assets/img/profile/mail-info/email.svg"),
      Viber: cdnSource("@/assets/img/profile/mail-info/viber.svg"),
      WhatsApp: cdnSource("@/assets/img/profile/mail-info/whatsapp.svg")
    }
  };

  get config() {
    return this.$store.getters['getConfig'] as ConfigI;
  }

  get dataPanel(): {
    id: string
  } {
    return this.$store.state.app.dataPanel;
  }

  get info(): MailStateI["info"] {
    return this.$store.state.mail.info;
  }

  get statList() {
    const stats = this.info?.stats;

    if (!stats) {
      return null;
    }

    const count = stats.recipient_count;

    return statList.map((item) => {
      const stat = stats[item.key as keyof MailItem["stats"]];

      item.percent = +((stat / count) * 100).toFixed(1);

      return item;
    });
  }

  async created() {
    const { id } = this.dataPanel;

    await this.$store.dispatch('loadMailInfo', id);
  }

  openQuestion() {
    this.$store.dispatch('setActiveModal', { modal: 'blockBalance' });
  }

  pennyToRuble(penny: number) {
    return (penny / 100).toFixed(0);
  }

  time(tm: Date) {
    return this.$moment(tm)
      .utc(true)
      .local()
      .format('D MMMM');
  }

  openInfo(key: string, status: string) {
    return this.$store.dispatch('setActivePanel', {
      panel: 'statInfo',
      data: {
        id: this.dataPanel.id,
        status,
        count: this.info?.stats[key as keyof MailItem["stats"]]
      }
    });
  }

}
