
import cdnSource from "@/functions/cdnSource";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Checkbox extends Vue {

  @Prop({ default: false }) value!: boolean;

  cdn = {
    checkbox: cdnSource('@/assets/img/structure/checkbox.svg')
  }

}
