
import { Component, Vue } from "vue-property-decorator";

@Component
export default class UploadSegments extends Vue {

  file: string | null = null;

  uploadFile() {
    this.file = 'test';
  }

  goBack() {
    this.$store.commit('setSegmentsSelect', false);

    this.$store.dispatch('goBackHistory');
  }

}
