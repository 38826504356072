<template>
  <svg :class="{ 'spinner': true, 'spinner-absolute': absolute }" viewBox="0 0 50 50">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
  </svg>
</template>

<script>
export default {
  props: {
    absolute: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner {
  width: 30px;
  height: 30px;

  z-index: 2;

  animation: rotate 2s linear infinite;

  &-absolute {
    position: absolute;
    top: 50%;
    left: calc(50% - 30px / 2);
  }

  & .path {
    stroke: #E0727F;
    stroke-linecap: round;

    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
