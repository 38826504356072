
import { Component, Prop, Vue } from "vue-property-decorator";

import Structure from "@/components/Structure.vue";
import Checkbox from "@/components/Checkbox.vue";

import cdnSource from "@/functions/cdnSource";
import getStructureSelected from "@/functions/getStructureSelected";

import { SegmentsUserI } from "@/types/segments";
import { StructureUserI } from "@/types/structure";

@Component({
  components: {
    Checkbox,
    SegmentsItem,
    Structure
  }
})
export default class SegmentsItem extends Vue {

  @Prop({ required: true }) item!: SegmentsUserI;
  @Prop({ required: true }) loadStructureById!: Function;
  @Prop({ required: true }) loadStructureOffsetById!: Function;

  cdn = {
    chevron: cdnSource('@/assets/img/structure/chevron.svg'),
    loading: cdnSource('@/assets/img/structure/loading.svg')
  };

  getStructureSelected = getStructureSelected;

  get isMailSelect() {
    return this.$store.getters['getSegmentsSelect'];
  }

  get segmentsSelected() {
    return this.$store.getters['getSegmentsSelected'];
  }

  get segments() {
    return this.$store.getters['getSegments'];
  }

  async loadStructure(e: MouseEvent, item: SegmentsUserI, isOffset = false) {
    e.preventDefault();
    e.stopPropagation();

    if (item.count === 0) {
      return;
    }

    if (!isOffset) {
      await this.loadStructureById(item);
    } else {
      await this.loadStructureOffsetById(item, item.data?.users.length);
    }
  }

  async addSegmentsToSelect(e: MouseEvent, segmentItem: SegmentsUserI) {
    e.preventDefault();
    e.stopPropagation();

    const segmentsSelected = { ...this.segmentsSelected };

    if (segmentItem.data) {
      const segmentUsers = segmentItem.data.users;

      // Check if user exist in another arrays
      ['user', 'structure', 'all'].forEach((segment) => {
        segmentUsers.forEach((segmentItem: StructureUserI) => {
          const findIndex = segmentsSelected[segment].indexOf(segmentItem);

          if (findIndex === -1) {
            return;
          }

          segmentsSelected[segment].splice(findIndex, 1);
        });
      });
    }

    // Check if user exist in another arrays
    const findIndex = segmentsSelected['segments'].findIndex((_item: SegmentsUserI) => _item.id === segmentItem.id);

    if (findIndex === -1) {
      segmentsSelected['segments'].push({ ...segmentItem });
    } else {
      segmentsSelected['segments'].splice(findIndex, 1);
    }

    this.$store.commit('setSegmentsSelected', segmentsSelected);
  }

  isSegmentSelected(item: SegmentsUserI) {
    const findIndex = this.segmentsSelected['segments'].findIndex((_item: SegmentsUserI) => _item.id === item.id);

    return findIndex !== -1;
  }

  getIsAfterShown(item: SegmentsUserI) {
    return this.isMailSelect && item.final && item.count !== 0;
  }

  handleCheckbox(
    payload: { item: StructureUserI, selected: boolean },
    segmentItem: SegmentsUserI
  ) {
    const { item, selected } = payload;

    const segmentsSelected = { ...this.segmentsSelected };

    if (selected) {
      // Selected all segment
      const segments = segmentsSelected['segments'];

      const findSegmentIndex = segmentsSelected['segments']
        .findIndex((item: SegmentsUserI) => item.id === segmentItem.id);

      const segment = segments[findSegmentIndex];

      if (!segment.exclude) {
        segment.exclude = [];
      }

      const checkExcludeIndex = segment.exclude.findIndex((_item: StructureUserI) => _item.user_id === item.user_id);

      if (checkExcludeIndex !== -1) {
        segment.exclude.splice(checkExcludeIndex, 1);
      } else {
        segment.exclude.push({ ...item });
      }
    } else {
      // Selected only structure item
      Object.keys(segmentsSelected).forEach((segment) => {
        const findIndex = segmentsSelected[segment].findIndex((_item: StructureUserI) => _item.user_id === item.user_id);

        if (findIndex === -1) {
          return;
        }

        segmentsSelected[segment].splice(findIndex, 1);
      });
    }

    this.$store.commit('setSegmentsSelected', segmentsSelected);
  }

}
