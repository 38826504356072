<template>
  <div v-if="profile" class="profile">
    <div class="profile-inner">
      <div class="title">
        <Burger class="title__burger"></Burger>
        {{ $t('tabs.profile') }}
      </div>
      <div
        v-if="config.interface.showQR"
        :class="{ qrCode: true, qrCodeOpened: qrOpened }"
        @click="onQrClick"
      >
        <div class="qrCode__line">
          <div class="qrCode__text">
            <img :src="cdn.qrCode" alt="" />
            <span>QR-code</span>
          </div>
          <img class="dropdown" :src="cdn.qrDropdown" alt="">
        </div>
        <div class="content">
          <a class="code" :href="profile.invite_link.url" target="_blank" aria-label="qrcode link">
            <qrcode
              tag="svg"
              :value="profile.invite_link.url"
              :options="{ width: 140, errorCorrectionLevel: 'L' }"
            ></qrcode>
          </a>
          <div class="description">{{ $t('profile.qrCode.placeholder') }}</div>
          <button class="btn" @click="copyQrCode">
            <span v-if="qrCopied">{{ $t('profile.qrCode.copied') }}</span>
            <span v-else>{{ $t('profile.qrCode.copy') }}</span>
          </button>
        </div>
      </div>
      <Avatar class="avatar" :src="profileAvatar" :transport="transport" :size="60"></Avatar>
      <div class="info">
        <div class="name">{{ profile.full_name }}</div>
        <div v-if="profile.location" class="address">{{ profile.location.city }}, {{ profile.location.country }}</div>
        <div v-if="profile.rank" class="profession">{{ profile.rank }}</div>
      </div>
      <div v-if="showProfile" class="block stat">
        <div v-if="profile.number" class="item">
          <div class="text">{{ profile.number }}</div>
          <div class="caption">{{ $t('profile.consNumber') }}</div>
        </div>
        <div v-if="profile.phone" class="item">
          <div class="text">+{{ profile.phone }}</div>
          <div class="caption">{{ $t('profile.phone') }}</div>
        </div>
        <div v-if="profile.email" class="item">
          <div class="text">{{ profile.email }}</div>
          <div class="caption">{{ $t('profile.email') }}</div>
        </div>
        <div v-if="profile.registration_date" class="item">
          <div class="text">{{ getDate(profile.registration_date) }}</div>
          <div class="caption">{{ $t('profile.registerAt') }}</div>
        </div>
        <div v-if="profile.last_order_date" class="item">
          <div class="text">{{ getDate(profile.last_order_date) }}</div>
          <div class="caption">{{ $t('profile.lastOrderAt') }}</div>
        </div>
      </div>
      <div v-if="profile.comment" class="block note">
        <div class="caption">{{ $t('profile.note') }}</div>
        <div class="text">{{ profile.comment }}</div>
      </div>
      <div v-if="profile.segments.length !== 0 || showScore" class="block segments">
        <div v-if="profile.segments.length !== 0">
          <div class="caption">{{ $t('profile.segments') }}</div>
          <div class="tags">
            <div
              v-for="(item, index) in profile.segments"
              class="item"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="showScore">
          <div v-if="profile.segments.length !== 0" class="divider"></div>
          <div class="caption">{{ $t('profile.scores') }}</div>
          <div class="tags">
            <div v-if="profile.lo_bonus_scores" class="item">{{ $t('profile.bonusScores.lo') }}: {{ profile.lo_bonus_scores }}</div>
            <div v-if="profile.olg_bonus_scores" class="item">{{ $t('profile.bonusScores.olg') }}: {{ profile.olg_bonus_scores }}</div>
            <div v-if="profile.go_bonus_scores" class="item">{{ $t('profile.bonusScores.go') }}: {{ profile.go_bonus_scores }}</div>
          </div>
        </div>
      </div>
      <div class="block settings">
        <div v-if="config.interface.paidCampaigns" class="item">
          <div class="icon">
            <img :src="cdn.ruble" alt="">
          </div>
          <span>{{ $t('myBill') }}</span>
          <img class="expanable" :src="cdn.expanable" alt="">
        </div>
        <div class="item" @click="openMailHistory">
          <div class="icon">
            <img :src="cdn.history" alt="">
          </div>
          <span>{{ $t('mailHistory') }}</span>
          <img class="expanable" :src="cdn.expanable" alt="">
        </div>
      </div>
      <div v-if="mode === 'web'" class="block settings">
        <div class="item" @click="openChangePassword">
          <div class="icon">
            <img :src="cdn.password" alt="">
          </div>
          <span>{{ $t('changePassword') }}</span>
          <img class="expanable" :src="cdn.expanable" alt="">
        </div>
      </div>
      <div class="block settings">
        <div class="item" @click="openProhibitionList('broadcast')">
          <div class="icon">
            <img :src="cdn.disablePersons" alt="">
          </div>
          <span>{{ $t('banOnMailStructure') }}</span>
          <img class="expanable" :src="cdn.expanable" alt="">
        </div>
        <div class="item" @click="openProhibitionList('personal')">
          <div class="icon">
            <img :src="cdn.disablePerson" alt="">
          </div>
          <span>{{ $t('banOnMailConsultant') }}</span>
          <img class="expanable" :src="cdn.expanable" alt="">
        </div>
      </div>
      <button v-if="mode === 'web'" class="exit" @click="exit">
        {{ $t('profile.exit') }}
      </button>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar";
import Burger from "@/components/Burger";

import cdnSource from "@/functions/cdnSource";

export default {
  components: {
    Avatar,
    Burger
  },
  data() {
    return {
      qrOpened: false,
      qrCopied: false,
      copyTimeout: null,
      cdn: {
        close: cdnSource('@/assets/img/app/close.svg'),
        qrCode: cdnSource('@/assets/img/qr/code.svg'),
        qrDropdown: cdnSource('@/assets/img/qr/dropdown.svg'),
        history: cdnSource('@/assets/img/profile/history.svg'),
        ruble: cdnSource('@/assets/img/profile/ruble.svg'),
        disablePerson: cdnSource('@/assets/img/profile/disable-person.svg'),
        disablePersons: cdnSource('@/assets/img/profile/disable-persons.svg'),
        password: cdnSource('@/assets/img/profile/change-password.svg'),
        expanable: cdnSource('@/assets/img/profile/expanable.svg')
      }
    };
  },
  beforeUnmount() {
    clearTimeout(this.copyTimeout);
  },
  computed: {
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    config() {
      return this.$store.getters['getConfig'];
    },
    app() {
      return this.$store.state.app;
    },
    profile() {
      return this.$store.getters['getMe'];
    },
    profileAvatar() {
      if (!this.profile.avatar_upload_date) {
        return cdnSource('@/assets/img/default_avatar.svg');
      }

      return this.baseUrl + `/users/${this.profile.user_id}/avatar`;
    },
    transport() {
      return this.profile.preferred_transport;
    },
    showScore() {
      const { lo_bonus_scores, olg_bonus_scores, go_bonus_scores } = this.profile;

      return !!(
        lo_bonus_scores
        || olg_bonus_scores
        || go_bonus_scores
      );
    },
    showProfile() {
      const { phone, email, registration_date, last_order_date } = this.profile;

      return !!(
        phone
        || email
        || registration_date
        || last_order_date
      );
    },
    mode() {
      return this.$store.getters['getMode'];
    }
  },
  methods: {
    goBack() {
      this.$store.dispatch('goBackHistory');
    },
    getDate(date) {
      return this.$moment
        .utc(date)
        .local()
        .format('LLL');
    },
    onQrClick() {
      this.qrOpened = !this.qrOpened;
    },
    copyQrCode(e) {
      e.preventDefault();
      e.stopPropagation();

      this.qrCopied = true;

      navigator.clipboard.writeText(this.profile.invite_link.url);

      this.copyTimeout = setTimeout(() => this.qrCopied = false, 3000);
    },
    openMailHistory() {
      this.$store.dispatch("setActivePanel", { panel: "mailHistory" });
    },
    openProhibitionList(type) {
      this.$store.dispatch("setActivePanel", { panel: "broadcastList", data: { type } });
    },
    openChangePassword() {
      this.$store.dispatch("setActivePanel", { panel: "changePassword" });
    },
    exit() {
      this.$store.dispatch('setActiveModal', { modal: 'confirmLogout' });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile {
  width: 100%;
  height: 100%;

  padding-bottom: 50px;

  position: relative;

  overflow-y: auto;

  background: #EFEFEF;

  &-inner {
    width: 400px;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 850px) {
      width: 100%;

      padding: 0 20px;
    }
  }

  .title {
    width: 100%;

    margin: 26px 0;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Proxima Nova", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    text-align: center;

    &__burger {
      position: absolute;
      left: 0;
    }

    @media screen and (max-width: 850px) {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }
  }

  .qrCode {
    width: 100%;
    //max-height: 57px;

    margin-bottom: 40px;
    padding: 20px;

    cursor: pointer;

    overflow: hidden;
    user-select: none;

    transition: .3s ease;

    border-radius: 24px;
    background: #FEFEFE;

    &__line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dropdown {
        transition: .3s ease;
      }
    }

    &__text {
      display: flex;
      align-items: center;
      gap: 20px;

      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      font-weight: 600;
    }

    .content {
      max-height: 0;

      display: flex;
      flex-direction: column;
      align-items: center;

      transition: .3s ease;

      overflow: hidden;

      .code {
        width: 140px;
        height: 140px;

        margin-top: 20px;
      }

      .description {
        margin: 0 40px;

        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        text-align: center;

        color: #242B32;
      }

      .btn {
        margin: 24px 0 10px 0;
        padding: 7px 18px;

        font-family: "Proxima Nova", sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;

        cursor: pointer;

        border: none;
        outline: none;
        border-radius: 20px;

        color: #FFFFFF;
        background: linear-gradient(0deg, #E0727F, #E0727F);
      }
    }
  }

  .qrCodeOpened {
    max-height: 350px;

    .qrCode__line .dropdown {
      transform: rotate(180deg);
    }

    .content {
      max-height: 300px;
    }
  }

  .info {
    margin-top: 24px;

    text-align: center;

    div {
      margin-top: 2px;
    }

    .name {
      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      color: #242B32;
    }

    .address, .profession {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      color: #585757;
    }
  }

  .block {
    width: 100%;

    margin-top: 8px;
    padding: 20px;

    border-radius: 24px;
    background: #FEFEFE;

    .icon {
      min-width: 22px;

      text-align: center;
    }
  }

  .settings {
    padding: 0;

    .item {
      padding: 20px;

      position: relative;

      display: flex;
      align-items: center;

      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;

      cursor: pointer;

      color: #242B32;

      &:not(:last-child) {
        &:before {
          width: calc(100% - 40px);
          height: 1px;

          position: absolute;
          left: 20px;
          bottom: 0;

          content: "";

          background: rgba(31, 31, 31, 0.1);
        }
      }

      span {
        margin-left: 20px;
      }

      .expanable {
        margin-left: auto;
      }
    }
  }

  .stat {
    margin-top: 20px;

    padding: 8px 20px;

    .item {
      padding: 16px 0;

      position: relative;

      &:not(:last-child) {
        &::before {
          width: 100%;
          height: 1px;

          position: absolute;
          bottom: 0;

          content: "";

          background: rgba(31, 31, 31, 0.1);
        }
      }

      .text {
        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        color: #1F1F1F;
      }

      .caption {
        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        color: #585757;
      }
    }
  }

  .note {
    .caption, .text {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      color: #585757;
    }

    .caption {
      img {
        width: 11px;
        height: 11px;

        margin-left: 4px;
      }
    }

    .text {
      margin-top: 4px;

      color: #000000;
    }
  }

  .segments {
    .caption {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      color: #585757;
    }

    .tags {
      margin: 8px 0;

      display: flex;
      flex-wrap: wrap;

      gap: 8px;

      .item {
        padding: 8px;

        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        border: 1px solid #E8E8E8;
        border-radius: 8px;
      }
    }

    .divider {
      width: 100%;
      height: 1px;

      margin: 16px 0;

      background: rgba(31, 31, 31, 0.1);
    }
  }

  .exit {
    width: 96px;
    height: 34px;

    margin-top: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    cursor: pointer;
    transition: .3s ease;

    outline: none;

    border: 1px solid #E0727F;
    border-radius: 20px;

    color: #E0727F;
    background: transparent;

    &:hover {
      opacity: .8;
    }
  }
}
</style>
