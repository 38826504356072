import store from "@/store";

import { SegmentsUserI } from "@/types/segments";
import { StructureUserI } from "@/types/structure";

export default function getStructureSelected(item: StructureUserI) {
  const segmentsSelected = { ...store.getters['getSegmentsSelected'] };

  let isSelected = false;

  Object.keys(segmentsSelected).forEach((segment: string) => {
    segmentsSelected[segment].forEach((_segmentItem: SegmentsUserI | StructureUserI) => {
      if (segment === 'segments') {
        const segmentItem = _segmentItem as SegmentsUserI;

        if (!segmentItem.data) {
          return;
        }

        const checkIndex = segmentItem.data.users.findIndex((_item) => _item.user_id === item.user_id);

        if (checkIndex === -1) {
          return;
        }

        if (segmentItem.exclude) {
          const excludeUser = segmentItem.exclude.findIndex((_item: StructureUserI) => _item.user_id === item.user_id);

          if (excludeUser !== -1) {
            return;
          }
        }
      } else {
        const segmentItem = _segmentItem as StructureUserI;

        if (segmentItem.user_id !== item.user_id) {
          return;
        }
      }

      isSelected = true;
    });
  });

  return isSelected;
}
