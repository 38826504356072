<template>
  <div class="contact">
    <div class="avatar">
      <slot name="avatar">
        <skeleton
          type="circle"
          :size="36"
        />
      </slot>
    </div>
    <div class="info">
      <div class="row">
        <div class="name">
          <skeleton
            type="rect"
            :width="100"
            :height="18"
          />
        </div>
        <div class="after">
          <div class="time">
            <skeleton
              type="rect"
              :width="12"
              :height="12"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text">
          <span class="message">
            <skeleton
              type="rect"
              :width="50"
              :height="16"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "@/components/Skeleton";

export default {
  components: {
    Skeleton
  }
};
</script>

<style lang="scss" scoped>
.contact {
  margin-bottom: 1px;
  padding: 12px;

  display: flex;

  .info {
    width: 100%;

    margin-left: 12px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    overflow: hidden;

    .name, .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      padding-right: 50px;

      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    .row {
      position: relative;

      .text {
        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        .message {
          color: #585757;
        }

        .you {
          color: #000000;
        }

        .attachment {
          font-style: italic;
        }
      }

      .after {
        position: absolute;
        top: 2px;
        right: 0;

        display: flex;

        .status {
          margin: 2px 4px 0 0;
        }

        .time {
          font-family: "Proxima Nova", sans-serif;
          font-size: 10px;
          line-height: 12px;

          color: #929292;
        }

        .counter {
          padding: 2px 7px;

          font-family: "Proxima Nova", sans-serif;
          font-weight: 600;
          font-size: 10px;
          line-height: 10px;

          border-radius: 12px;

          color: #ffffff;
          background: #1F1F1F;
        }
      }
    }
  }
}
</style>
