import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

export interface NotificationStateI {
  date: Date | null,
  type: string,
  text: string,
  position: "empty" | "tabs"
}

@Module
export default class User extends VuexModule<NotificationStateI> {

  date: NotificationStateI["date"] = null;
  type: NotificationStateI["type"] = "";
  text: NotificationStateI["text"] = "";
  position: NotificationStateI["position"] = "tabs";

  @Mutation
  setNotification(
    payload: {
      type: NotificationStateI["type"],
      text: NotificationStateI["text"],
      position: NotificationStateI["position"]
    }
  ) {
    const {
      type,
      text,
      position = "tabs"
    } = payload;

    this.date = new Date();
    this.type = type;
    this.text = text;
    this.position = position;
  }

  @Action
  async sendNotification(
    payload: NotificationStateI
  ) {
    const { commit } = this.context;

    commit('setNotification', {
      date: new Date(),
      ...payload
    });
  }
}
