
import getBase64 from "@/functions/getBase64";
import axios from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";

import Avatar from "@/components/Avatar.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import SelectDate from "@/components/SelectDate.vue";

import cdnSource from "@/functions/cdnSource";
import handleError from "@/functions/handleError";

import { SegmentsUserI } from "@/types/segments";
import { StructureUserI } from "@/types/structure";

import emojiList from "@/data/emojiList";

@Component({
  components: {
    SelectDate,
    ToggleSwitch,
    Avatar
  }
})
export default class MailSend extends Vue {

  $refs!: {
    text: HTMLTextAreaElement,
    file: HTMLInputElement
  };

  acceptFiles = 'image/png,image/jpg,image/jpeg,image/heic,image/heif,application/zip,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,application/x-zip,application/x-zip-compressed';

  file: File | null = null;
  imagePreview: string | ArrayBuffer | null = null;

  showDatePicker = false;

  date: string | null = null;
  text = "";
  loading = false;
  selectedUsersCount = null;

  emojiList = emojiList;

  cdn = {
    clip: cdnSource('@/assets/img/writebar/clip.svg'),
    emoji: cdnSource('@/assets/img/writebar/emoji.svg'),
    write: cdnSource('@/assets/img/writebar/write_red.svg'),
    remove: cdnSource('@/assets/img/writebar/remove.svg')
  };

  types = {
    doc: cdnSource('@/assets/img/attachment/doc.svg'),
    docx: cdnSource('@/assets/img/attachment/doc.svg'),
    pptx: cdnSource('@/assets/img/attachment/pptx.svg'),
    xls: cdnSource('@/assets/img/attachment/xls.svg'),
    xlsx: cdnSource('@/assets/img/attachment/xlsx.svg'),
    zip: cdnSource('@/assets/img/attachment/zip.svg'),
    pdf: cdnSource('@/assets/img/attachment/zip.svg')
  };

  get segmentsSelected() {
    return this.$store.getters['getSegmentsSelected'];
  }

  get excludedList() {
    const segmentsSelected = { ...this.segmentsSelected };
    const segments = segmentsSelected['segments'];

    const segmentsExclude = segments.map((segment: SegmentsUserI) => segment.exclude);

    let excludedUsers: Array<StructureUserI> = [];

    segmentsExclude.forEach((exclude: SegmentsUserI["exclude"]) => {
      if (!exclude) {
        return;
      }

      excludedUsers = excludedUsers.concat(exclude);
    });

    return excludedUsers;
  }

  get selection() {
    const segmentsSelected = { ...this.segmentsSelected };

    return {
      include_with_structure: [
        ...segmentsSelected['structure'].map((item: StructureUserI) => ({
          type: 'shallow',
          user_id: item.user_id
        })),
        ...segmentsSelected['all'].map((item: StructureUserI) => ({
          type: 'deep',
          user_id: item.user_id
        }))
      ],
      segments: segmentsSelected['segments'].map((item: SegmentsUserI) => item.id),
      include: segmentsSelected['user'].map((item: StructureUserI) => item.user_id),
      exclude: [
        ...this.excludedList.map((item: StructureUserI) => item.user_id),
        ...this.segmentsExcluded.map((item: StructureUserI) => item.user_id)
      ],
      exclude_with_structure: []
    };
  }

  get dataPanel(): { type: 'email' | 'chat' } {
    return this.$store.state.app.dataPanel;
  }

  get segmentsExcluded() {
    return this.$store.getters['getSegmentsExcluded'];
  }

  get dateFormatted() {
    return this.$moment(this.date).format('D MMMM HH:mm');
  }

  get fileType() {
    if (!this.file) {
      return null;
    }

    const file_name = this.file.name;
    const mime_type = this.file.type;

    const nameType = file_name && file_name.substring(file_name.lastIndexOf(".") + 1);
    const mimeType = mime_type && mime_type.substring(mime_type.lastIndexOf("/") + 1);
    const type = nameType || mimeType;

    if (['jpg', 'jpeg', 'png', 'heif', 'hevc'].indexOf(type) !== -1) {
      return "image";
    }

    return type ? type : "image";
  }

  async mounted() {
    await this.getSelectedCount();
  }

  async getSelectedCount() {
    const { data } = await axios.post("/users/selection", {
      ...this.selection
    });

    this.selectedUsersCount = data.count;
  }

  async send() {
    const { text } = this;

    if (
      text.length === 0
      || this.selectedUsersCount === 0
    ) {
      return;
    }

    if (this.loading) {
      return;
    }

    const type = this.dataPanel.type === 'email' ? 'Mailing' : 'Broadcast';

    let file = undefined;
    let delayedStart = this.$moment().utcOffset(0).format();

    this.loading = true;

    if (this.date) {
      delayedStart = this.$moment(this.date).utcOffset(0).format();
    }


    if (this.file) {
      const { name, type } = this.file;
      const fileBase64 = await getBase64(this.file) as string;
      const isImage = this.fileType === "image";

      file = {
        type: isImage ? "image" : "document",
        file_name: name,
        data: fileBase64.replace(`data:${this.file.type};base64,`, ""),
        mime_type: type
      };
    }

    try {
      await axios.post("/message", {
        type,
        text,
        selection: this.selection,
        delayed_start: delayedStart,
        file
      });

      await this.$store.dispatch('sendNotification', {
        type: 'success',
        text: 'Рассылка отправлена'
      });

      await Promise.all([
        this.$store.commit('setSegmentsSelect', false),
        this.$store.dispatch('goBackHistory', { backCount: 2 })
      ]);
    } catch (e) {
      await handleError(e);

      console.log(e);
    }

    this.loading = false;
  }

  setShowDatePicker(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.showDatePicker = true;
  }

  pasteEmoji(emoji: string) {
    const index = this.$refs.text.selectionEnd;

    const text = this.text;

    this.text = text.slice(0, index) + emoji + text.slice(index);

    this.$refs.text.focus();
    this.$nextTick(() => {
      this.$refs.text.setSelectionRange(index + emoji.length,  index + emoji.length);
    });
  }

  choiceFile() {
    if (this.selectedUsersCount === 0) {
      return;
    }

    this.$refs.file.click();
  }

  async fileSelected(e: Event) {
    const target = e.target as HTMLInputElement;

    if (!target.files) {
      return;
    }

    if (target.files.length > 0) {
      this.file = target.files[0];
    }

    if (!this.file) {
      return;
    }

    const { type, size } = this.file;

    if (this.acceptFiles.indexOf(type) === -1) {
      await this.$store.dispatch('sendNotification', {
        type: 'error',
        text: 'Добавление файлов такого типа запрещено'
      });

      this.removeFile();

      return;
    }

    if (
      size === 0
      || size > 1024000
    ) {
      await this.$store.dispatch('sendNotification', {
        type: 'error',
        text: size === 0 ? 'Файл пустой' : 'Файл слишком большой'
      });

      this.removeFile();

      return;
    }

    this.imagePreview = await getBase64(this.file);
  }

  removeFile() {
    this.imagePreview = null;
    this.file = null;
    this.$refs.file.value = "";
  }

  onDateSelect(value: string) {
    this.date = value;
    this.showDatePicker = false;
  }

  goBack() {
    this.$store.dispatch('goBackHistory');
  }

  @Watch('segmentsSelected')
  handleSegmentsSelectedChange() {
    this.getSelectedCount();
  }

}
