
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ViewTabs extends Vue {

  @Prop() value!: number;
  @Prop() tabs!: Array<string>;

  changeTab(index: number) {
    this.$emit('input', index);
  }

}
