<template>
  <div class="communication">
    <div ref="dialogs" class="dialogs" @resize.capture="calculateContactsHeight">
      <div class="title">
        <Burger class="burger"></Burger>
        {{ $t('tabs.communication') }}
      </div>
      <template v-if="!contactsLoading">
        <virtual-list
          v-if="contacts.length !== 0"
          class="contacts scroll"
          :style="'height: ' + contactsHeight + '!important;overflow-y: auto;'"
          :data-key="'user_id'"
          :data-sources="contacts"
          :data-component="itemComponent"
          @tobottom="loadContacts"
        >
          <div slot="footer" v-show="fetching" class="fetching">
            <Spinner :absolute="false" />
          </div>
        </virtual-list>
        <div v-else class="empty-placeholder">
          {{ $t('placeholders.listEmpty') }}
        </div>
      </template>
      <template v-else>
        <div class="contacts">
          <skeleton-contact
            v-for="(_, index) in new Array(10).fill(null)"
            :key="index"
          ></skeleton-contact>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact";
import Burger from "@/components/Burger";
import Spinner from "@/components/Spinner";
import SkeletonContact from "@/components/Skeleton/Contact";

import cdnSource from "@/functions/cdnSource";

export default {
  name: 'Communication',
  components: {
    Burger,
    Spinner,
    SkeletonContact
  },
  data() {
    return {
      fetching: false,
      fetchingDisabled: false,
      itemComponent: Contact,
      contactsHeight: '360px',
      ro: null,
      contactsId: null,
      timeoutId: null
    };
  },
  mounted() {
    this.ro = new ResizeObserver(this.calculateContactsHeight);
    this.ro.observe(this.$refs.dialogs);
    this.$on('clickOnContact', this.selectContact);

    this.contactsId = setInterval(() => {
      this.$store.dispatch('loadContacts', {
        intervalUpdate: true
      });
    }, this.$store.getters['getUpdateInterval']);
  },
  beforeDestroy() {
    this.ro.unobserve(this.$refs.dialogs);

    clearInterval(this.intervalId);
    clearInterval(this.contactsId);
  },
  created() {
    this.$store.dispatch('loadContacts');
  },
  computed: {
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    app() {
      return this.$store.state.app;
    },
    contacts() {
      return this.$store.getters['getContacts'];
    },
    toUser() {
      return this.$store.getters['getToUser'];
    },
    selectedContact() {
      return this.$store.getters['getSelectedContact'];
    },
    contactsLoading() {
      return this.$store.getters['getContactsLoading'];
    },
    firstContactSelected() {
      return this.$store.getters['getFirstContactSelected'];
    },
    selectedContactAvatar() {
      if (!this.selectedContact.avatar_upload_date) {
        return cdnSource('@/assets/img/default_avatar.svg');
      }

      return this.baseUrl + `/users/${this.selectedContact.user_id}/avatar`;
    }
  },
  methods: {
    findOrCreateContact() {
      if (!this.selectedContact) {
        return;
      }

      console.warn(this.selectedContact);

      const checkExist = this.contacts.find((item) => item.user_id === this.selectedContact.user_id);

      if (checkExist) {
        return;
      }

      const contacts = [...this.contacts];

      contacts.unshift(this.selectedContact);

      this.$store.commit('setContacts', contacts);
    },
    async loadContacts() {
      if (this.fetching || this.fetchingDisabled) {
        return;
      }

      this.fetching = true;

      const data = await this.$store.dispatch('loadContacts', { count: 100, offset: this.contacts.length });

      this.fetching = false;
      this.fetchingDisabled = data.users.length !== data.max_count;
    },
    calculateContactsHeight() {
      this.contactsHeight = (this.$refs.dialogs.clientHeight - 72) + 'px';
    },
    selectContact(contact) {
      this.$store.dispatch('setActivePanel', { panel: 'chat' });
      this.$store.commit('setSelectedContact', contact);

      this.$store.dispatch('loadMessages',  {
        user_id: contact.user_id,
        count: 100,
        order: 'asc',
        loading: true
      });

      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        const { user_id } = this.$store.getters['getSelectedContact'];
        this.$store.dispatch('loadMessages',  {
          user_id: user_id,
          count: 100,
          order: 'asc',
          intervalUpdate: true
        });
      }, this.$store.getters['getUpdateInterval']);
    }
  }
};
</script>

<style lang="scss" scoped>
.communication {
  height: 100vh;

  display: flex;

  .dialogs {
    width: 100%;

    position: relative;

    .contacts {
      padding: 0 8px;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .fetching {
        margin: 10px;

        display: flex;
        justify-content: center;
      }
    }

    .empty-placeholder {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      font-family: "Proxima Nova", sans-serif;
      font-size: 16px;
      line-height: 16px;

      z-index: 5;

      color: #585757;
    }
  }
}

.title {
  margin: 26px 20px 22px 20px;

  position: relative;

  display: flex;
  align-items: center;

  font-family: "Proxima Nova", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  z-index: 10;

  .burger {
    margin-right: 18px;
  }
}
</style>
