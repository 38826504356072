import axios from "axios";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {UserI} from "@/types/user";

interface ContactsStateI {
  contacts: Array<UserI>,
  contactsLoading: boolean,
  selectedContact: UserI | null,
  firstContactSelected: boolean
}

@Module
export default class Contacts extends VuexModule<ContactsStateI> {
  contacts: ContactsStateI["contacts"] = [];
  contactsLoading: ContactsStateI["contactsLoading"] = false;
  selectedContact: ContactsStateI["selectedContact"] = null;
  firstContactSelected: ContactsStateI["firstContactSelected"] = false;

  get getContacts() {
    return this.contacts;
  }

  get getContactsLoading() {
    return this.contactsLoading;
  }

  get getSelectedContact() {
    return this.selectedContact;
  }

  get getFirstContactSelected() {
    return this.firstContactSelected;
  }

  @Mutation
  setContacts(users: ContactsStateI["contacts"]) {
    this.contacts = users;
  }

  @Mutation
  setContactsLoading(status: boolean) {
    this.contactsLoading = status;
  }

  @Mutation
  setSelectedContact(contact: UserI) {
    this.selectedContact = contact;
  }

  @Mutation
  setFirstContactSelected(status: boolean) {
    this.firstContactSelected = status;
  }

  @Action
  async loadContacts(
    payload: {
      count?: number,
      offset?: number,
      intervalUpdate?: boolean
    }
  ) {
    const { commit } = this.context;

    const params = {
      count: 100,
      offset: 0,
      ...payload
    };

    if (!payload) {
      commit('setContactsLoading', true);
    }

    const contacts = await axios.get("/profile/contacts", {
      params: {
        count: params.count,
        offset: params.offset
      }
    });

    const data = {
      ...contacts.data,
      users: contacts.data.users.filter((contact: UserI) => !contact.last_message || contact.last_message && contact.last_message.tm)
    };

    if (params.offset !== 0) {
      commit('setContacts', [...this.contacts, ...data.users]);
    } else {
      if (params.intervalUpdate) {
        const contacts = [...this.contacts];

        const newContacts: Array<UserI> = [];

        contacts.concat(data.users).forEach((obj) => {
          const index = newContacts.findIndex(x => x.user_id === obj.user_id);

          if (index !== -1) {
            newContacts.splice(index, 1, obj);
            return;
          }

          newContacts.push(obj);
        });

        commit('setContacts', newContacts);
      } else {
        commit('setContacts', data.users);
      }
    }

    commit('setContactsLoading', false);

    return data;
  }
}
