<template>
  <panel-wrapper class="edit-note">
    <div class="edit-note-inner">
      <div class="title">{{ $t('note') }}</div>
      <textarea
        class="textarea"
        v-model="value"
        rows="5"
        :placeholder="$t('placeholders.noteText')"
      ></textarea>
      <div class="actions">
        <button class="save" @click="save">{{ $t('buttons.save') }}</button>
        <button class="cancel" @click="goBack">{{ $t('buttons.cancel') }}</button>
      </div>
    </div>
  </panel-wrapper>
</template>

<script>
import PanelWrapper from "@/components/PanelWrapper";

export default {
  components: {
    PanelWrapper
  },
  data() {
    return {
      value: ""
    };
  },
  mounted() {
    const { text } = this.app.dataPanel;
    this.value = text;
  },
  computed: {
    profile() {
      return this.$store.getters['getMe'];
    },
    app() {
      return this.$store.state.app;
    }
  },
  methods: {
    async save() {
      const { userId } = this.app.dataPanel;

      try {
        await this.axios.post("users/" + userId, {
          comment: this.value || null
        });

        this.goBack();
      } catch (e) {
        console.log(e);
      }
    },
    goBack() {
      this.$store.dispatch('goBackHistory');
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-note {
  padding-top: 75px;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  &-inner {
    width: 400px;

    padding: 23px 16px 26px 16px;

    border-radius: 24px;

    background: #FEFEFE;
  }

  .title {
    font-family: "Proxima Nova", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    text-align: center;

    color: #1F1F1F;
  }

  .textarea {
    width: 100%;

    margin-top: 22px;
    padding: 16px 12px;

    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    line-height: 17px;

    outline: none;
    border: none;
    border-radius: 12px;

    resize: none;
    overflow: hidden;

    color: #1F1F1F;
    background: #EFEFEF;
  }

  .actions {
    margin-top: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .save, .cancel {
      width: 105px;

      padding: 7px 0;

      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      border: none;
      outline: none;
      border-radius: 20px;

      cursor: pointer;
    }

    .save {
      color: #FEFEFE;
      background: linear-gradient(0deg, #E0727F, #E0727F);
    }

    .cancel {
      border: 1px solid #E0727F;

      color: #E0727F;
      background: transparent;
    }
  }
}
</style>
