import {
  Action,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";

type Screens = "tab" | "auth";

export interface AppStateI {
  subPanel: boolean,
  activeView: string | null,
  activePanel: string | null,
  activeModal: string | null,
  dataView: any,
  dataPanel: any,
  dataModal: any,

  activeScreen: Screens | null,
  dataScreen: any,

  history: Array<{
    view: string,
    panel: string,
    modal: string,
    dataView: any,
    dataPanel: any,
    dataModal: any,
    isViewHide: boolean
  }>,
  isViewHide: boolean,
  isMobile: boolean
}

interface ActiveViewAndPanelPayload {
  view: AppStateI["activeView"],
  dataView: AppStateI["dataView"],
  panel: AppStateI["activePanel"],
  dataPanel: AppStateI["dataPanel"],
  pushHistory: boolean,
  viewHide: boolean
}

@Module
export default class App extends VuexModule<AppStateI> {

  subPanel: AppStateI["subPanel"] = false;
  activeView: AppStateI["activeView"] = 'communication';
  activePanel: AppStateI["activePanel"] = 'empty';
  activeModal: AppStateI["activeModal"] = null;
  dataView: AppStateI["dataView"] = null;
  dataPanel: AppStateI["dataPanel"] = null;
  dataModal: AppStateI["dataModal"] = null;

  activeScreen: AppStateI["activeScreen"] = null;
  dataScreen: AppStateI["dataScreen"] = null;

  history: AppStateI["history"] = [];

  isMobile: AppStateI["isMobile"] = false;
  isViewHide: AppStateI["isViewHide"] = false;

  @Mutation
  setSubPanel(status: boolean) {
    this.subPanel = status;
  }

  @Mutation
  setActiveScreen(
    payload: {
      screen: AppStateI["activeScreen"],
      data?: AppStateI["dataScreen"]
    }
  ) {
    const { screen, data } = payload;

    this.activeScreen = screen;
    this.dataScreen = data;
  }

  @Mutation
  setView(
    payload: {
      view: AppStateI["activeView"],
      data?: AppStateI["dataView"]
    }
  ) {
    const { view, data } = payload;

    this.activeView = view;
    this.dataView = data;
  }

  @Mutation
  setPanel(
    payload: {
      panel: AppStateI["activePanel"],
      data?: AppStateI["dataPanel"]
    }
  ) {
    const { panel, data } = payload;

    this.activePanel = panel;
    this.dataPanel = data;
  }

  @Mutation
  setModal(
    payload: {
      modal: AppStateI["activeModal"],
      data?: AppStateI["dataModal"]
    }
  ) {
    const { modal, data } = payload;

    this.activeModal = modal;
    this.dataModal = data;
  }

  @Mutation
  changeMobile(status: boolean) {
    this.isMobile = status;
  }

  @Mutation
  changeViewHide(status: boolean) {
    this.isViewHide = status;
  }

  @Mutation
  setHistory(history: AppStateI["history"]) {
    this.history = history;
  }

  @Mutation
  setDefaults() {
    this.activeView = 'communication';
    this.activePanel = 'empty';
    this.activeModal = null;
    this.history = [];
  }

  @Action
  async setActiveView({ view, data, pushHistory = true, viewHide = false }: { view: string, data: any, pushHistory: boolean, viewHide: boolean }) {
    const { commit, dispatch } = this.context;

    if (pushHistory) {
      await dispatch('pushHistory');
    }

    commit('setView', {
      view,
      data: data ? data : this.dataView
    });

    commit('setPanel', {
      panel: !this.isMobile ? 'empty' : null
    });

    commit('setSubPanel', false);
    commit('changeViewHide', viewHide);
  }

  @Action
  async setActivePanel({ panel, data, pushHistory = true, viewHide = false }: { panel: string, data: any, pushHistory: boolean, viewHide: boolean }) {
    const { commit, dispatch } = this.context;

    if (pushHistory) {
      await dispatch('pushHistory');
    }

    commit('setPanel', {
      panel,
      data: data ? data : this.dataPanel
    });
  }

  @Action
  async setActiveViewAndPanel(payload: ActiveViewAndPanelPayload) {
    const { commit, dispatch } = this.context;

    const {
      view,
      dataView,
      panel,
      dataPanel,
      pushHistory = true,
      viewHide = false
    } = payload;

    if (pushHistory) {
      await dispatch('pushHistory');
    }

    commit('setView', {
      view,
      data: dataView
    });

    commit('setPanel', {
      panel,
      data: dataPanel
    });

    commit('changeViewHide', viewHide);
  }

  @Action
  async setActiveModal({ modal, data }: { modal: string, data: any }) {
    const { commit, dispatch } = this.context;

    await dispatch('pushHistory');

    commit('setModal', {
      modal,
      data: data ? data : this.dataModal
    });
  }

  @Action
  pushHistory(
    params?: {
      view: string,
      panel: string,
      modal: string
    }
  ) {
    const {
      view,
      panel,
      modal
    } = <{
      view: string,
      panel: string,
      modal: string
    }>{ view: this.activeView, panel: this.activePanel, modal: this.activeModal } || params;

    const history = [...this.history];
    history.push({
      view,
      panel,
      modal,
      dataPanel: this.dataPanel,
      dataView: this.dataView,
      dataModal: this.dataModal,
      isViewHide: this.isViewHide
    });

     console.log(history);

    this.context.commit('setHistory', history);
  }

  @Action
  goBackHistory(
    params?: {
      backCount: number
    }
  ) {
    const { commit } = this.context;

    const backCount = params?.backCount || 1;

    console.log('goBackHistory', this.history);

    const canBack = (this.history.length - backCount) >= 0;
    const history = [...this.history];

    if (!canBack) {
      return;
    }

    const {
      view,
      panel,
      modal,
      dataPanel,
      dataView,
      dataModal,
      isViewHide
    } = history[history.length - backCount];

    commit('setView', {
      view,
      data: dataView
    });

    commit('setPanel', {
      panel,
      data: dataPanel
    });

    commit('setModal', {
      modal,
      data: dataModal
    });

    commit('changeViewHide', isViewHide);

    // if (panel === "empty" && this.isMobile) {
    //   commit('setPanel', { panel: "" });
    // }

    history.splice(history.length - backCount, backCount);

    commit('setHistory', history);
  }
}
