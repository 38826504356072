import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import axios from "axios";

import {StructureI, StructureUserI} from "@/types/structure";

interface StructureStateI {
  structure: StructureI | null,
  childsCount: number,
  structureLoading: boolean,
  fetchingDisabled: boolean
}

@Module
export default class Structure extends VuexModule<StructureStateI> {
  structure: StructureStateI["structure"] = null;
  childsCount: StructureStateI["childsCount"] = 0;
  structureLoading: StructureStateI["structureLoading"] = false;
  fetchingDisabled: StructureStateI["fetchingDisabled"] = false;

  get getStructure() {
    return this.structure;
  }

  get getStructureLoading() {
    return this.structureLoading;
  }

  get getStructureChildsCount() {
    return this.childsCount;
  }

  get getStructureFetchingDisabled() {
    return this.fetchingDisabled;
  }

  @Mutation
  setChildsCount(count: number) {
    if (this.childsCount) {
      return;
    }

    this.childsCount = count;
  }

  @Mutation
  setStructure(structure: StructureStateI["structure"]) {
    this.structure = structure;
  }

  @Mutation
  setStructureLoading(status: boolean) {
    this.structureLoading = status;
  }

  @Mutation
  setFetchingDisabled(status: boolean) {
    this.fetchingDisabled = status;
  }

  @Action
  async loadStructure(payload = { userId: undefined, updateParents: true, offset: 0 }) {
    const { commit } = this.context;

    const structure = {
      ...this.structure
    };

    const child = structure.childs ? { ...structure.childs.user } : null;

    const params = {
      offset: payload.offset || 0
    };

    if (params.offset === 0) {
      commit('setStructureLoading', true);
    }

    if (payload.updateParents) {
      const { data } = await axios.get(`/users/${payload.userId}/parents`);
      structure.parents = data;
      structure.parents.users = structure.parents.users.reverse();
    }

    const { data } = await axios.get(`/users/${payload.userId}/childs2`, {
      params: {
        offset: params.offset
      }
    });

    if (params.offset !== 0) {
      structure.childs.childs = structure.childs.childs.concat(data.childs);
    } else {
      structure.childs = data;
    }

    commit('setChildsCount', data.total_count);
    commit('setFetchingDisabled', data.childs.length !== data.max_count);

    if (!payload.updateParents) {
      if (params.offset === 0) {
        const [parent] = structure.parents.users.filter((item) => item.user_id === payload.userId);

        if (!parent) {
          if (!child) {
            return;
          }

          structure.parents.users.push(child);
        } else {
          const { length } = structure.parents.users;
          const index = structure.parents.users.indexOf(parent);

          structure.parents.users.splice(index, length - index);
        }
      }
    } else {
      commit('setChildsCount', structure.childs.childs.length);
    }

    commit('setStructureLoading', false);
    commit('setStructure', structure);
  }
}
