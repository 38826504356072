
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import cdnSource from "@/functions/cdnSource";

interface IProps {
  name?: string,
  value: string,
  mask?: string,
  type?: string,
  placeholder: string,
  autocomplete?: string,
  isError?: boolean
}

interface IState {
  localValue: string,
  localType: string,
  isFocus: boolean,
  isVisible: boolean
}

@Component
export default class Input extends Vue {

  // TODO: Исправить анимацию фокуса при автофокусе
  // TODO: Исправить дизейбл кнопки при автофокусе посредством хендла его
  // TODO: Определение номера в зависимости от локализации или дефолтный

  @Prop() name!: IProps["name"];
  @Prop() value!: IProps["value"];
  @Prop({ default: 'text' }) type: IProps["type"];
  @Prop({ required: true }) placeholder!: IProps["placeholder"];
  @Prop({ default: undefined }) mask: IProps["mask"];
  @Prop({ default: undefined }) autocomplete: IProps["autocomplete"];
  @Prop({ default: false }) isError: IProps["isError"];

  cdn = {
    visibility: cdnSource('@/assets/img/input/visibility.svg'),
    visibilityOff: cdnSource('@/assets/img/input/visibility-off.svg')
  };

  localValue: IState["localValue"] = "";
  localType: IState["localType"] = "";

  isFocus: IState["isFocus"] = false;
  isVisible: IState["isVisible"] = false;

  constructor() {
    super();

    this.localType = this.type || "text";

    this.localValue = this.value || "";
  }

  handleAnimation() {
    this.isFocus = true;
  }

  handleVisibleClick() {
    this.localType = !this.isVisible ? 'text' : 'password';
    this.isVisible = !this.isVisible;
  }

  @Watch('value')
  onValueChange(value: string) {
    this.localValue = value;
  }

  @Watch('localValue')
  onLocalValueChange(value: string) {
    this.$emit('input', value);
  }

}
