<template>
  <div class="message-row" :class="{'outgoing': !incoming}">
    <div class="avatar">
      <slot name="avatar">
        <Avatar v-if="hasAvatar" :user-id="userId" :transport="transport" :size="36" />
        <Avatar v-else :src="defaultAvatar" :transport="transport" :size="36" />
      </slot>
    </div>
    <div class="message">
      <a
        v-if="attachmentType === 'img'"
        :href="attachmentUrl"
        target="_blank"
      >
        <v-lazy-image
          :width="attachmentSize.width"
          :height="attachmentSize.height"
          :src="attachmentUrl"
          alt=""
        />
      </a>
      <a
        v-if="attachmentType && attachmentType !== 'img'"
        class="file"
        :href="attachmentUrl"
      >
        <v-lazy-image :src="types[attachmentType]" alt="" />
        <div class="name">{{ source.file.file_name }}</div>
      </a>
      <div v-if="message || attachmentUrl" class="text" v-html="message" />
      <div v-else>{{ $t('empty_message') }}</div>
      <div class="time">{{ time }}</div>
      <Status v-if="!incoming" class="status" :fill="fill"></Status>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";

import Status from "@/components/Contact/Status";
import Avatar from "@/components/Avatar";

import cdnSource from "@/functions/cdnSource";

export default {
  name: 'Message',
  components: {
    Status,
    Avatar,
    VLazyImage
  },
  props: {
    index: Number,
    source: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      defaultAvatar: cdnSource('@/assets/img/default_avatar.svg'),
      types: {
        doc: cdnSource('@/assets/img/attachment/doc.svg'),
        docx: cdnSource('@/assets/img/attachment/doc.svg'),
        pptx: cdnSource('@/assets/img/attachment/pptx.svg'),
        xls: cdnSource('@/assets/img/attachment/xls.svg'),
        xlsx: cdnSource('@/assets/img/attachment/xlsx.svg'),
        zip: cdnSource('@/assets/img/attachment/zip.svg'),
        pdf: cdnSource('@/assets/img/attachment/pdf.svg')
      }
    };
  },
  computed: {
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    fill() {
      return this.isRead ? "#1ffd42" : "#fefefe";
    },
    isRead() {
      return this.source.status === 'read';
    },
    time() {
      return this.$moment
        .utc(this.source.tm)
        .local()
        .format('HH:mm');
    },
    message() {
      if (!this.source.text) {
        return;
      }

      const { text } = this.source;

      let replacedText, replacePattern1, replacePattern2, replacePattern3;

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = text.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

      return replacedText;
    },
    incoming() {
      return this.source.incoming;
    },
    userId() {
      return this.source.from;
    },
    transport() {
      return this.source.transport;
    },
    hasAvatar() {
      const getter = this.incoming ? 'getSelectedContact' : 'getMe';
      const { avatar_upload_date } = this.$store.getters[getter];
      return !!avatar_upload_date;
    },
    sourceExist() {
      return !(this.source.file && this.source.file.file_id);
    },
    attachmentType() {
      const { file } = this.source;

      if (!file) {
        return;
      }

      const { file_name, mime_type } = file;
      const nameType = file_name && file_name.substring(file_name.lastIndexOf(".") + 1);
      const mimeType = mime_type && mime_type.substring(mime_type.lastIndexOf("/") + 1);

      if (file.width) {
        return "img";
      }

      return nameType || mimeType;
    },
    attachmentUrl() {
      if (this.sourceExist) {
        return;
      }

      return `${this.baseUrl}/file/${this.source.file.file_id}`;
    },
    attachmentSize() {
      if (this.sourceExist) {
        return;
      }

      const { width, height } = this.source.file;

      const lWidth = width < 221 ? width : 221;
      const lHeight = width < 221 ? height : (height * 221 / width);

      return {
        width: lWidth,
        height: lHeight
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.message-row {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin: 0 3px 8px;
  &.outgoing {
    flex-direction: row-reverse;
    .message {
      max-width: 245px;
      min-width: 70px;

      padding: 12px 12px 20px 12px;

      position: relative;

      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      border-radius: 8px;

      color: #ffffff;
      background: #E0727F;

      & > img {
        max-width: 100%;
      }

      .text::v-deep a {
        color: #ffffff;
        text-decoration: underline;
      }

      .time, .status {
        opacity: .7;
      }

      .file {
        color: #ffffff;

        img {
          filter: invert(100%) sepia(0%) saturate(7479%) hue-rotate(70deg) brightness(99%) contrast(107%);
        }
      }

      .time {
        position: absolute;
        right: 22px;
        bottom: 4px;

        font-size: 10px;
        line-height: 12px;

        color: #fefefe;
      }

      .status {
        position: absolute;
        right: 4px;
        bottom: 7px;
      }
    }
  }

  .message {
    max-width: 245px;
    min-width: 70px;

    padding: 12px 12px 20px 12px;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    line-height: 17px;

    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;

    border-radius: 8px;

    background: #EFEFEF;

    .text::v-deep {
      a {
        color: #000000;
        text-decoration: underline;
      }

      .emoji {
        width: 16px;
        height: 16px;

        margin: 0 1px;

        display: inline-block;

        border: 0;

        overflow: hidden;

        vertical-align: -3px;
      }
    }

    & > img {
      max-width: 100%;
    }

    .text {
      width: 100%;

      overflow: hidden;
      text-overflow: ellipsis;
    }

    .time {
      position: absolute;
      right: 4px;
      bottom: 4px;

      font-size: 10px;
      line-height: 12px;

      color: #929292;
    }

    .file {
      width: 100%;

      display: flex;
      align-items: center;

      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 18px;

      cursor: pointer;

      color: #242B32;

      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        width: 29px;
        height: 29px;

        margin-right: 8px;

        filter: invert(13%) sepia(8%) saturate(1313%) hue-rotate(169deg) brightness(99%) contrast(91%);
      }
    }
  }

  .avatar {
    margin-bottom: 1px;
  }
}
</style>
