var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{class:{
    'input': true,
    'input--active': _vm.localValue.length !== 0 || _vm.isFocus,
    'input--errored': _vm.isError,
    'input--focused': _vm.isFocus,
    'input--password': _vm.type === 'password'
  }},[_c('span',{staticClass:"input__caption"},[_vm._v(_vm._s(_vm.placeholder))]),((_vm.localType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"name":_vm.name,"autocomplete":_vm.autocomplete,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"animationstart":_vm.handleAnimation,"focusin":function($event){_vm.isFocus = true},"focusout":function($event){_vm.isFocus = false},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):((_vm.localType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"name":_vm.name,"autocomplete":_vm.autocomplete,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"animationstart":_vm.handleAnimation,"focusin":function($event){_vm.isFocus = true},"focusout":function($event){_vm.isFocus = false},"change":function($event){_vm.localValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"name":_vm.name,"autocomplete":_vm.autocomplete,"type":_vm.localType},domProps:{"value":(_vm.localValue)},on:{"animationstart":_vm.handleAnimation,"focusin":function($event){_vm.isFocus = true},"focusout":function($event){_vm.isFocus = false},"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}}),(_vm.type === 'password')?_c('span',{class:{
      'input__visible': true,
      'input__visible-show': _vm.localValue.length !== 0
    },on:{"click":_vm.handleVisibleClick}},[_c('img',{attrs:{"src":!_vm.isVisible ? _vm.cdn.visibility : _vm.cdn.visibilityOff,"alt":""}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }