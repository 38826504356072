<template>
  <div class="message-row" :class="{'outgoing': isOutgoing}">
    <div class="avatar">
      <slot name="avatar">
        <skeleton
          type="circle"
          :size="36"
        />
      </slot>
    </div>
    <skeleton
      class="text"
      :width="245"
      :height="200"
    />
  </div>
</template>

<script>
import Skeleton from "@/components/Skeleton";

export default {
  components: {
    Skeleton
  },
  props: {
    isOutgoing: Boolean
  }
};
</script>

<style lang="scss" scoped>
.message-row {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin: 0 3px 8px;
  &.outgoing {
    flex-direction: row-reverse;
    .message {
      max-width: 245px;
      min-width: 70px;

      padding: 12px 12px 20px 12px;

      position: relative;

      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      border-radius: 8px;

      color: #ffffff;
      background: #E0727F;
    }
  }

  .message {
    max-width: 245px;
    min-width: 70px;

    padding: 12px 12px 20px 12px;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    line-height: 17px;

    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;

    border-radius: 8px;

    background: #EFEFEF;

    .text::v-deep a {
      color: #000000;
      text-decoration: underline;
    }

    & > img {
      max-width: 100%;
    }

    .text {
      width: 100%;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .avatar {
    margin-bottom: 1px;
  }
}
</style>
