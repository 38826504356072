
import {Component, Vue} from "vue-property-decorator";

import cdnSource from "@/functions/cdnSource";

import PanelWrapper from "@/components/PanelWrapper.vue";
import MailHistoryItem from "@/components/MainHistory/Item.vue";
import SkeletonMailHistory from "@/components/Skeleton/MailHistory.vue";

import { MailStateI } from "@/store/mail";

interface IState {
  cdn: {
    [key: string]: string
  }
}

@Component({
  components: {
    PanelWrapper,
    MailHistoryItem,
    SkeletonMailHistory
  }
})
export default class MailHistory extends Vue {

  cdn: IState["cdn"];

  constructor() {
    super();

    this.cdn = {
      done: cdnSource("@/assets/img/profile/mail-history/done.svg")
    };
  }

  get history(): MailStateI["history"] {
    return this.$store.state.mail.history;
  }

  created() {
    this.$store.dispatch('loadMailHistory');
  }

  openInfo(id: string) {
    return this.$store.dispatch('setActivePanel', { panel: 'mailInfo', data: { id } });
  }

}
