export interface PhoneCodeItem {
  code: string,
  name: string,
  img: string,
  length: number | undefined
}

const phoneCodes: Array<PhoneCodeItem> = [
  {
    code: "+90",
    name: "TR",
    img: "ic_flag_turkey",
    length: 10
  },
  {
    code: "+359",
    name: "BG",
    img: "ic_flag_bulgaria",
    length: undefined
  },
  {
    code: "+39",
    name: "IT",
    img: "ic_flag_italy",
    length: undefined
  },
  {
    code: "+36",
    name: "HU",
    img: "ic_flag_hungary",
    length: 8
  },
  {
    code: "+33",
    name: "FR",
    img: "ic_flag_france",
    length: 9
  },
  {
    code: "+49",
    name: "DE",
    img: "ic_flag_germany",
    length: undefined
  },
  {
    code: "+976",
    name: "MN",
    img: "ic_flag_mongolia",
    length: undefined
  },
  {
    code: "+40",
    name: "RO",
    img: "ic_flag_romania",
    length: 9
  },
  {
    code: "+48",
    name: "PL",
    img: "ic_flag_poland",
    length: 9
  },
  {
    code: "+420",
    name: "CZ",
    img: "ic_flag_czech_republic",
    length: 9
  },
  {
    code: "+374",
    name: "AM",
    img: "ic_flag_armenia",
    length: 8
  },
  {
    code: "+373",
    name: "MD",
    img: "ic_flag_moldova",
    length: 8
  },
  {
    code: "+372",
    name: "EE",
    img: "ic_flag_estonia",
    length:8
  },
  {
    code: "+371",
    name: "LV",
    img: "ic_flag_latvia",
    length: 8
  },
  {
    code: "+370",
    name: "LT",
    img: "ic_flag_lithuania",
    length: 8
  },
  {
    code: "+994",
    name: "AZ",
    img: "ic_flag_azerbaijan",
    length: 9
  },
  {
    code: "+995",
    name: "GE",
    img: "ic_flag_georgia",
    length: 10
  },
  {
    code: "+996",
    name: "KG",
    img: "ic_flag_kyrgyzstan",
    length: 9
  },
  {
    code: "+992",
    name: "TJ",
    img: "ic_flag_tajikistan",
    length: 9
  },
  {
    code: "+998",
    name: "UZ",
    img: "ic_flag_uzbekistan",
    length: 9
  },
  {
    code: "+993",
    name: "TM",
    img: "ic_flag_turkmenistan",
    length: 8
  },
  {
    code: "+77",
    name: "KZ",
    img: "ic_flag_kazakhstan",
    length: 9
  },
  {
    code: "+375",
    name: "BY",
    img: "ic_flag_belarus",
    length: 9
  },
  {
    code: "+380",
    name: "UA",
    img: "ic_flag_ukraine",
    length: 9
  },
  {
    code: "+7",
    name: "RU",
    img: "ic_flag_russia",
    length: 10
  }
];

export function findCode(name: string): PhoneCodeItem {
  const index = phoneCodes.findIndex((item) => item.name === name);

  return phoneCodes[index];
}

export default phoneCodes;
