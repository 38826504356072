<template>
  <div class="profile">
    <div class="profile-inner">
      <skeleton
        class="avatar"
        type="circle"
        :size="60"
      />
      <div class="info">
        <div class="name">
          <skeleton
            class="text"
            :width="220"
            :height="20"
          />
        </div>
      </div>
      <div class="actions">
        <skeleton
          class="text"
          :width="280"
          :height="34"
        />
      </div>
      <skeleton
        class="block-skeleton-stat"
        :height="346"
      />
      <skeleton
        class="block-skeleton"
        :height="200"
      />
    </div>
  </div>
</template>

<script>
import Skeleton from "@/components/Skeleton";

export default {
  components: {
    Skeleton
  }
};
</script>

<style lang="scss" scoped>
.profile {
  width: 100%;
  height: 100%;

  padding: 50px 0;

  position: relative;

  overflow-y: auto;

  background: #EFEFEF;

  &-inner {
    width: 400px;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 850px) {
      width: 100%;

      padding: 0 20px;
    }
  }

  .info {
    margin-top: 24px;

    text-align: center;

    div {
      margin-top: 2px;
    }
  }

  .actions {
    margin-top: 16px;
  }

  .block {
    width: 100%;

    margin-top: 8px;
    padding: 20px;

    border-radius: 24px;
    background: #FEFEFE;

    &-skeleton {
      margin-top: 8px;
    }

    &-skeleton-stat {
      margin-top: 20px;
    }
  }
}
</style>
