<template>
  <div :class="{ 'contact': true, 'contact-active': active }" @click="onClick">
    <div class="avatar">
      <slot name="avatar">
        <Avatar :src="avatarUrl" :transport="transport" :size="36" />
      </slot>
    </div>
    <div class="info">
      <div class="row">
        <div class="name">
          <slot name="name">{{ source.full_name }}</slot>
        </div>
        <div class="after">
          <Status class="status" :fill="fill"></Status>
          <div class="time">{{ time }}</div>
        </div>
      </div>
      <div class="row">
        <div class="text" :style="{ 'padding-right': counter && '25px' }">
          <span v-if="lastMessageOwner" class="you">
            {{ lastMessageOwner }}
          </span>
          <span v-if="lastMessageText" class="message">{{ lastMessageText }}</span>
          <span v-else class="attachment message">{{ attachmentType === 'img' ? $t('photo') : $t('document') }}</span>
        </div>
        <div v-if="counter" class="after">
          <div class="counter">{{ counter }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollMixin from "@/mixins/virtual-scroll-mixin";

import cdnSource from "@/functions/cdnSource";

import Avatar from "@/components/Avatar";
import Status from "@/components/Contact/Status";

import declNum from "@/functions/declNum";

export default {
  mixins: [scrollMixin],
  components: {
    Status,
    Avatar
  },
  props: {
    index: Number,
    source: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    onClick() {
      if (this.active) {
        return;
      }

      this.dispatch('Communication', 'clickOnContact', this.source);
    }
  },
  computed: {
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    me() {
      return this.$store.getters['getMe'];
    },
    app() {
      return this.$store.state.app;
    },
    active() {
      const selected = this.$store.getters['getSelectedContact'];

      if (!selected) {
        return;
      }

      if (this.app.activePanel !== "chat") {
        return;
      }

      return selected.user_id === this.source.user_id;
    },
    fill() {
      let status = 0;

      if (this.source.last_message) {
        status = this.source.last_message.is_read ? 1 : 0;
      }

      if (status === 1) {
        return "#E0727F";
      }

      return "#929292";
    },
    counter() {
      return this.source.unread_count ?? 0;
    },
    lastMessageOwner() {
      const { last_message } = this.source;
      const { user_id } = this.me || {};

      return last_message && last_message.from === user_id && (this.$t('you') + ":");
    },
    lastMessageText() {
      if (!this.source.last_message) {
        return '';
      }

      return this.source.last_message.text;
    },
    avatarUrl() {
      if (!this.source.avatar_upload_date)
        return cdnSource('@/assets/img/default_avatar.svg');
      return this.baseUrl + `/users/${this.source.user_id}/avatar`;
    },
    attachmentType() {
      const { file } = this.source.last_message || {};

      if (!file) {
        return;
      }

      return file.width ? "img" : "doc";
    },
    transport() {
      if (!this.source.last_message) {
        return false;
      }

      return this.source.preferred_transport;
    },
    time() {
      if (!this.source.last_message) {
        return false;
      }

      const time = this.$moment
        .utc(this.source.last_message.tm)
        .local();

      const current = this.$moment()
        .utcOffset(0);

      const between = this.$moment.duration(current.diff(time));
      const daysBetween = between.asDays();
      const weeksBetween = between.asWeeks();
      const monthsBetween = between.asMonths();
      const yearsBetween = between.asYears();

      let format = time.format('HH:mm');

      if (daysBetween >= 1) {
        format = Math.round(daysBetween) + "д";
      }

      if (daysBetween >= 7) {
        format = Math.round(weeksBetween) + "н";
      }

      if (daysBetween >= 30) {
         format = Math.round(monthsBetween) + "м";
      }

      if (daysBetween >= 365) {
        const years = Math.round(yearsBetween);

        format = years + declNum(years, ['г', 'г', 'л']);
      }

      return format;
    }
  }
};
</script>

<style lang="scss" scoped>
.contact {
  margin-bottom: 1px;
  padding: 12px;

  display: flex;

  @media screen and (min-width: 850px) {
    &:hover {
      cursor: pointer;
      border-radius: 16px;
      background: #EFEFEF;
    }
  }

  &-active {
    border-radius: 16px;
    background: #EFEFEF;

    @media screen and (max-width: 850px) {
      border-radius: 0;
      background: transparent;
    }
  }

  .info {
    width: 100%;

    margin-left: 12px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    overflow: hidden;

    .name, .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      padding-right: 50px;

      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    .row {
      position: relative;

      .text {
        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        .message {
          color: #585757;
        }

        .you {
          color: #000000;
        }

        .attachment {
          font-style: italic;
        }
      }

      .after {
        position: absolute;
        top: 2px;
        right: 0;

        display: flex;

        .status {
          margin: 2px 4px 0 0;
        }

        .time {
          font-family: "Proxima Nova", sans-serif;
          font-size: 10px;
          line-height: 12px;

          color: #929292;
        }

        .counter {
          padding: 2px 7px;

          font-family: "Proxima Nova", sans-serif;
          font-weight: 600;
          font-size: 10px;
          line-height: 10px;

          border-radius: 12px;

          color: #ffffff;
          background: #1F1F1F;
        }
      }
    }
  }
}

.contact-active {
  .info {
    .time {
      color: #000000 !important;
    }
  }
}
</style>
