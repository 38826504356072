const emojiList = [
  {
    "slug": "smileysEmotion",
    "emojis": [
      "😀",
      "😃",
      "😄",
      "😁",
      "😅",
      "😆",
      "😂",
      "🤣",
      "😉",
      "😊",
      "🙂",
      "🙃",
      "😇",
      "😗",
      "😙",
      "😚",
      "😘",
      "😍",
      "🥰",
      "🤩",
      "🤗",
      "😋",
      "😜",
      "🤪",
      "😛",
      "😝",
      "🤑",
      "🤭",
      "🤐",
      "🤫",
      "😶",
      "🤔",
      "🤨",
      "🧐",
      "😐",
      "😑",
      "🙄",
      "😬",
      "🤥",
      "😏",
      "😌",
      "🤤",
      "😴",
      "🤓",
      "😎",
      "🥳",
      "🤠",
      "😒",
      "😔",
      "😪",
      "😕",
      "😟",
      "🙁",
      "😮",
      "😯",
      "😲",
      "😳",
      "🥺",
      "😦",
      "😧",
      "😨",
      "😰",
      "😥",
      "😢",
      "😭",
      "😱",
      "😖",
      "😣",
      "😞",
      "😓",
      "😩",
      "😫",
      "😷",
      "🤒",
      "🤕",
      "🤢",
      "🤮",
      "🤧",
      "🥶",
      "🥵",
      "🥴",
      "😵",
      "🤯",
      "😤",
      "😠",
      "😡",
      "🤬",
      "😈",
      "👿",
      "💀",
      "💩",
      "🤡",
      "👹",
      "👺",
      "👻",
      "👽",
      "👾",
      "🤖",
      "😺",
      "😸",
      "😹",
      "😻",
      "😼",
      "😽",
      "🙀",
      "😿",
      "😾",
      "🙈",
      "🙉",
      "🙊",
      "🦠"
    ]
  },
  {
    "slug": "peopleBody",
    "emojis": [
      "👍",
      "👎",
      "👌",
      "✌",
      "🤞",
      "🤟",
      "🤘",
      "🤙",
      "🖕",
      "✊",
      "👊",
      "🤛",
      "🤜",
      "👈",
      "👉",
      "👆",
      "👇",
      "☝",
      "👋",
      "🤚",
      "🖐",
      "✋",
      "🖖",
      "👏",
      "🙌",
      "👐",
      "🤲",
      "🤝🏻",
      "🙏",
      "💪",
      "🦵",
      "🦶",
      "👂",
      "👃",
      "🧠",
      "🦷",
      "🦴",
      "👀",
      "👁",
      "👅",
      "👄",
      "✍",
      "💅",
      "🤳",
      "👫",
      "👭",
      "👬",
      "👩‍❤️‍💋‍👨",
      "👨‍❤️‍💋‍👨",
      "👩‍❤️‍💋‍👩",
      "👩‍❤️‍👨",
      "👨‍❤️‍👨",
      "👩‍❤️‍👩",
      "👪",
      "👶",
      "🧒",
      "👦",
      "👧",
      "🧑",
      "👱",
      "👨",
      "🧔",
      "👱‍♂️",
      "👨‍🦰",
      "👨‍🦱",
      "👨‍🦳",
      "👨‍🦲",
      "👩",
      "👱‍♀️",
      "👩‍🦰",
      "👩‍🦱",
      "👩‍🦳",
      "👩‍🦲",
      "🧓️",
      "👴️",
      "👵️",
      "🙍‍♂️️",
      "🙍‍♀️️",
      "🙎‍♂️",
      "🙎‍♀️️",
      "🙅‍♂️",
      "🙅‍♀️",
      "🙆‍♂️",
      "🙆‍♀️",
      "💁‍♂️",
      "💁‍♀️️",
      "🙋‍♂️",
      "🙋‍♀️️",
      "🙇‍♂️",
      "🙇‍♀️️",
      "🤦‍♂️",
      "🤦‍♀️",
      "🤷‍♂️",
      "🤷‍♀️",
      "💆‍♂️️",
      "💆‍♀️",
      "💇‍♂️️",
      "💇‍♀️",
      "🚶‍♂️️",
      "🚶‍♀️️",
      "🏃‍♂️️",
      "🏃‍♀️",
      "💃",
      "🕺",
      "🕴️",
      "👯‍♂️️",
      "👯‍♀️",
      "🧖‍♂️",
      "🧖‍♀️️",
      "👼",
      "🎅",
      "🤶",
      "🦸‍♂️",
      "🦸‍♀️",
      "🦹‍♂️",
      "🦹‍♀️",
      "🧙‍♂️",
      "🧙‍♀️",
      "🧚‍♂️",
      "🧚‍♀️",
      "🧛‍♂️",
      "🧛‍♀️",
      "🧜‍♂️",
      "🧜‍♀️",
      "🧝‍♂️",
      "🧝‍♀️",
      "🧞‍♂️",
      "🧞‍♀️",
      "🧟‍♂️",
      "🧟‍♀️",
      "👨‍⚕️",
      "👩‍⚕️",
      "👨‍🎓",
      "👩‍🎓",
      "👨‍🏫",
      "👩‍🏫",
      "👨‍⚖️",
      "👩‍⚖️",
      "👨‍🌾",
      "👩‍🌾",
      "👨‍🍳",
      "👩‍🍳",
      "👨‍🔧",
      "👩‍🔧",
      "👨‍🏭",
      "👩‍🏭",
      "👨‍💼",
      "👩‍💼",
      "👨‍🔬",
      "👩‍🔬",
      "👨‍💻",
      "👩‍💻",
      "👨‍🎤",
      "👩‍🎤",
      "👨‍🎨",
      "👩‍🎨",
      "👨‍✈️",
      "👩‍✈️",
      "👨‍🚀",
      "👩‍🚀",
      "👨‍🚒",
      "👩‍🚒",
      "👮‍♂️",
      "👮‍♀️",
      "🕵️‍♂️",
      "🕵️‍♀️",
      "💂‍♂️",
      "💂‍♀️",
      "👷‍♂️",
      "👷‍♀️",
      "🤴",
      "👸",
      "👳‍♂️",
      "👳‍♀️",
      "👲",
      "🧕",
      "🤵",
      "👰",
      "🤰",
      "🤱",
      "🛀",
      "🛌"
    ]
  },
  {
    "slug": "symbols",
    "emojis": [
      "💋",
      "❤",
      "💔",
      "❣",
      "💘",
      "💝",
      "💖",
      "💗",
      "💓",
      "💞",
      "💕",
      "💟",
      "💜",
      "🧡",
      "💛",
      "💚",
      "💙",
      "🖤",
      "💯",
      "💢",
      "💥",
      "💫",
      "🕳",
      "💣",
      "💬",
      "👁️‍🗨️",
      "🗨",
      "🗯",
      "💭",
      "💤",
      "🗣",
      "👤",
      "👥",
      "👣",
      "🔇",
      "🔊",
      "📢",
      "📣",
      "🔔",
      "🔕",
      "🎼",
      "🎵",
      "🎶",
      "⚠",
      "🚸",
      "☢",
      "☣",
      "🆚",
      "🆓",
      "🆕",
      "🚮",
      "🚾",
      "🚭",
      "✅",
      "♻",
      "⚕",
      "🔱",
      "‼",
      "⁉",
      "❓",
      "❗",
      "🆘",
      "⛔",
      "🚫",
      "🚳",
      "🚯",
      "🚱",
      "🚷",
      "📵",
      "🔞"
    ]
  },
  {
    "slug": "animalsNature",
    "emojis": [
      "🐵",
      "🐒",
      "🦍",
      "🐶",
      "🐕",
      "🐩",
      "🐺",
      "🦊",
      "🦝",
      "🐱",
      "🐈",
      "🦁",
      "🐯",
      "🐅",
      "🐆",
      "🐴",
      "🐎",
      "🦄",
      "🦓",
      "🦌",
      "🐮",
      "🐂",
      "🐃",
      "🐄",
      "🐷",
      "🐽",
      "🐖",
      "🐗",
      "🐏",
      "🐑",
      "🐐",
      "🐪",
      "🐫",
      "🦙",
      "🦒",
      "🐘",
      "🦏",
      "🦛",
      "🐭",
      "🐁",
      "🐀",
      "🐹",
      "🐰",
      "🐇",
      "🐿",
      "🦔",
      "🦇",
      "🐻",
      "🐨",
      "🐼",
      "🦘",
      "🦡",
      "🐾",
      "🦃",
      "🐔",
      "🐓",
      "🐣",
      "🐤",
      "🐥",
      "🐦",
      "🐧",
      "🕊",
      "🦅",
      "🦆",
      "🦢",
      "🦉",
      "🦚",
      "🦜",
      "🐸",
      "🐊",
      "🐢",
      "🦎",
      "🐍",
      "🐲",
      "🐉",
      "🦕",
      "🦖",
      "🐳",
      "🐋",
      "🐬",
      "🐟",
      "🐠",
      "🐡",
      "🦈",
      "🐙",
      "🦀",
      "🦞",
      "🦐",
      "🦑",
      "🐚",
      "🐌",
      "🦋",
      "🐛",
      "🐜",
      "🐝",
      "🐞",
      "🦗",
      "🕷",
      "🕸",
      "🦂",
      "🦟",
      "💐",
      "🌸",
      "💮",
      "🏵",
      "🌹",
      "🥀",
      "🌺",
      "🌻",
      "🌼",
      "🌷",
      "🌳",
      "🌲",
      "🎄",
      "🌴",
      "🌵",
      "🌾",
      "🌱",
      "🌿",
      "☘",
      "🍀",
      "🍁",
      "🍂",
      "🍃",
      "🌑",
      "🌒",
      "🌓",
      "🌔",
      "🌕",
      "🌖",
      "🌗",
      "🌘",
      "🌙",
      "🌚",
      "🌛",
      "🌜",
      "🌡",
      "☀",
      "🌝",
      "🌞",
      "⭐",
      "🌟",
      "🌠",
      "☁",
      "⛅",
      "⛈",
      "🌤",
      "🌥",
      "🌦",
      "🌧",
      "🌨",
      "🌩",
      "🌪",
      "🌫",
      "🌬",
      "💨",
      "🌀",
      "🌈",
      "🌂",
      "☂",
      "☔",
      "⛱",
      "⚡",
      "❄",
      "☃",
      "⛄",
      "☄",
      "🔥",
      "💦",
      "💧",
      "🌊"
    ]
  },
  {
    "slug": "foodDrink",
    "emojis": [
      "🍏",
      "🍎",
      "🍐",
      "🍅",
      "🥝",
      "🍑",
      "🍒",
      "🍓",
      "🍇",
      "🍈",
      "🍉",
      "🍊",
      "🍋",
      "🍌",
      "🍍",
      "🥭",
      "🥥",
      "🥑",
      "🍆",
      "🥔",
      "🥕",
      "🌽",
      "🌶",
      "🥒",
      "🥬",
      "🥦",
      "🍄",
      "🥜",
      "🌰",
      "🍞",
      "🥐",
      "🥖",
      "🥨",
      "🥯",
      "🥞",
      "🧀",
      "🍖",
      "🍗",
      "🥩",
      "🥓",
      "🍔",
      "🍟",
      "🍕",
      "🌭",
      "🥪",
      "🌮",
      "🌯",
      "🥙",
      "🥚",
      "🍳",
      "🥘",
      "🍲",
      "🥣",
      "🥗",
      "🍿",
      "🧂",
      "🥫",
      "🍱",
      "🍘",
      "🍙",
      "🍚",
      "🍛",
      "🍜",
      "🍝",
      "🍠",
      "🍢",
      "🍣",
      "🍤",
      "🍥",
      "🥮",
      "🍡",
      "🥟",
      "🥠",
      "🥡",
      "🍦",
      "🍧",
      "🍨",
      "🍩",
      "🍪",
      "🎂",
      "🍰",
      "🧁",
      "🥧",
      "🍫",
      "🍬",
      "🍭",
      "🍮",
      "🍯",
      "🍼",
      "🥛",
      "☕",
      "🍵",
      "🍶",
      "🍾",
      "🍷",
      "🍸",
      "🍹",
      "🍺",
      "🍻",
      "🥂",
      "🥃",
      "🥤",
      "🥢",
      "🍽",
      "🍴",
      "🥄"
    ]
  },
  {
    "slug": "activities",
    "emojis": [
      "⚽",
      "⚾",
      "🥎",
      "🏀",
      "🏐",
      "🏈",
      "🏉",
      "🎾",
      "🥏",
      "🎳",
      "🏏",
      "🏑",
      "🏒",
      "🥍",
      "🏓",
      "🏸",
      "🥊",
      "🥋",
      "🥅",
      "⛳",
      "⛸",
      "🎣",
      "🎽",
      "🛹",
      "🎿",
      "🛷",
      "🥌",
      "🎯",
      "🎱",
      "🎮",
      "🕹",
      "🎰",
      "🎲",
      "🧩",
      "🧗‍♂️️",
      "🧗‍♀️",
      "🤺",
      "🏇",
      "⛷",
      "🏂",
      "🏌️‍♂️",
      "🏌️‍♀️",
      "🏄‍♂️",
      "🏄‍♀️",
      "🚣‍♂️",
      "🚣‍♀️",
      "🏊‍♂️",
      "🏊‍♀️",
      "⛹️‍♂️",
      "⛹️‍♀️",
      "🏋️‍♂️",
      "🏋️‍♀️",
      "🚴‍♂️",
      "🚴‍♀️",
      "🚵‍♂️",
      "🚵‍♀️",
      "🤸",
      "🤼‍♂️",
      "🤼‍♀️",
      "🤽‍♂️",
      "🤽‍♀️",
      "🤾‍♂️",
      "🤾‍♀️",
      "🤹‍♂️",
      "🤹‍♀️",
      "🧘‍♂️",
      "🧘‍♀️",
      "🎖",
      "🏆",
      "🏅",
      "🥇",
      "🥈",
      "🥉"
    ]
  },
  {
    "slug": "travelPlaces",
    "emojis": [
      "🚂",
      "🚃",
      "🚄",
      "🚅",
      "🚆",
      "🚇",
      "🚈",
      "🚉",
      "🚊",
      "🚝",
      "🚞",
      "🚋",
      "🚌",
      "🚍",
      "🚎",
      "🚐",
      "🚑",
      "🚒",
      "🚓",
      "🚔",
      "🚕",
      "🚖",
      "🚗",
      "🚘",
      "🚙",
      "🚚",
      "🚛",
      "🚜",
      "🏎",
      "🏍",
      "🛵",
      "🚲",
      "🛴",
      "🚏",
      "🛣",
      "🛤",
      "🛢",
      "⛽",
      "🚨",
      "🚥",
      "🚦",
      "🛑",
      "🚧",
      "⚓",
      "⛵",
      "🛶",
      "🚤",
      "🛳",
      "⛴",
      "🛥",
      "🚢",
      "✈",
      "🛩",
      "🛫",
      "🛬",
      "💺",
      "🚁",
      "🚟",
      "🚠",
      "🚡",
      "🛰",
      "🚀",
      "🛸",
      "🌍",
      "🌎",
      "🌏",
      "🌐",
      "🗺",
      "🗾",
      "🧭",
      "🏔",
      "⛰",
      "🌋",
      "🗻",
      "🏕",
      "🏖",
      "🏜",
      "🏝",
      "🏞",
      "🏟",
      "🏛",
      "🏗",
      "🧱",
      "🏘",
      "🏚",
      "🏠",
      "🏡",
      "🏢",
      "🏣",
      "🏤",
      "🏥",
      "🏦",
      "🏨",
      "🏩",
      "🏪",
      "🏫",
      "🏬",
      "🏭",
      "🏯",
      "🏰",
      "💒",
      "🗼",
      "🗽",
      "⛪",
      "🕌",
      "🕍",
      "⛩",
      "🕋",
      "⛲",
      "⛺",
      "🌁",
      "🌃",
      "🏙",
      "🌄",
      "🌅",
      "🌆",
      "🌇",
      "🌉",
      "♨",
      "🌌",
      "🎠",
      "🎡",
      "🎢",
      "💈",
      "🎪"
    ]
  },
  {
    "slug": "objects",
    "emojis": [
      "🎙",
      "🎚",
      "🎛",
      "🎤",
      "🎧",
      "📻",
      "🎷",
      "🎸",
      "🎹",
      "🎺",
      "🎻",
      "🥁",
      "📯",
      "🎭",
      "🖼",
      "🎨",
      "🧵",
      "🧶",
      "🔮",
      "🧿",
      "🧸",
      "🃏",
      "🀄",
      "🎴",
      "🎃",
      "🎆",
      "🎇",
      "🧨",
      "✨",
      "🎈",
      "🎉",
      "🎊",
      "🎋",
      "🎍",
      "🎎",
      "🎏",
      "🎐",
      "🎑",
      "🧧",
      "🎀",
      "🎁",
      "🎗",
      "🎟",
      "🎫",
      "🛎",
      "🧳",
      "⌛",
      "⏳",
      "⌚",
      "⏰",
      "⏱",
      "⏲",
      "🕰",
      "👓",
      "🕶",
      "🥽",
      "🥼",
      "👔",
      "👕",
      "👖",
      "🧣",
      "🧤",
      "🧥",
      "🧦",
      "👗",
      "👘",
      "👙",
      "👚",
      "👛",
      "👜",
      "👝",
      "🛍",
      "🎒",
      "👞",
      "👟",
      "🥾",
      "🥿",
      "👠",
      "👡",
      "👢",
      "👑",
      "👒",
      "🎩",
      "🎓",
      "🧢",
      "⛑",
      "📿",
      "💄",
      "💍",
      "💎",
      "📱",
      "📲",
      "☎",
      "📞",
      "📟",
      "📠",
      "🔋",
      "🔌",
      "💻",
      "🖥",
      "🖨",
      "⌨",
      "🖱",
      "🖲",
      "💽",
      "💾",
      "💿",
      "📀",
      "🧮",
      "🎥",
      "🎞",
      "📽",
      "🎬",
      "📺",
      "📷",
      "📸",
      "📹",
      "📼",
      "🔍",
      "🔎",
      "🕯",
      "💡",
      "🔦",
      "🏮",
      "📔",
      "📕",
      "📖",
      "📗",
      "📘",
      "📙",
      "📚",
      "📓",
      "📒",
      "📃",
      "📜",
      "📄",
      "📰",
      "🗞",
      "📑",
      "🔖",
      "🏷",
      "💰",
      "💴",
      "💵",
      "💶",
      "💷",
      "💸",
      "💳",
      "🧾",
      "💹",
      "💱",
      "💲",
      "✉",
      "💌",
      "📧",
      "📨",
      "📩",
      "📤",
      "📥",
      "📦",
      "📫",
      "📪",
      "📬",
      "📭",
      "📮",
      "🗳",
      "✏",
      "✒",
      "🖋",
      "🖊",
      "🖌",
      "🖍",
      "📝",
      "💼",
      "📁",
      "📂",
      "🗂",
      "📅",
      "📆",
      "🗒",
      "🗓",
      "📇",
      "📈",
      "📉",
      "📊",
      "📋",
      "📌",
      "📍",
      "📎",
      "🖇",
      "📏",
      "📐",
      "✂",
      "🗃",
      "🗄",
      "🗑",
      "🔒",
      "🔓",
      "🔏",
      "🔐",
      "🔑",
      "🗝",
      "🔨",
      "⛏",
      "⚒",
      "🛠",
      "🗡",
      "⚔",
      "🔪",
      "🔫",
      "🏹",
      "🛡",
      "🔧",
      "🔩",
      "⚙",
      "🗜",
      "⚖",
      "🔗",
      "⛓",
      "🧰",
      "🧲",
      "⚗",
      "🧪",
      "🧫",
      "🧬",
      "🔬",
      "🔭",
      "📡",
      "💉",
      "💊",
      "🚪",
      "🛏",
      "🛋",
      "🚽",
      "🚿",
      "🛁",
      "🧴",
      "🧷",
      "🧹",
      "🧺",
      "🧻",
      "🧼",
      "🧽",
      "🧯",
      "🛒",
      "🚬",
      "⚰",
      "⚱",
      "🏺",
      "🗿"
    ]
  },
  {
    "slug": "flags",
    "emojis": [
      "🏁",
      "🚩",
      "🎌",
      "🏴",
      "🏳️",
      "🏳️‍🌈",
      "🏳️‍⚧️",
      "🏴‍☠️",
      "🇦🇨",
      "🇦🇩",
      "🇦🇪",
      "🇦🇫",
      "🇦🇬",
      "🇦🇮",
      "🇦🇱",
      "🇦🇲",
      "🇦🇴",
      "🇦🇶",
      "🇦🇷",
      "🇦🇸",
      "🇦🇹",
      "🇦🇺",
      "🇦🇼",
      "🇦🇽",
      "🇦🇿",
      "🇧🇦",
      "🇧🇧",
      "🇧🇩",
      "🇧🇪",
      "🇧🇫",
      "🇧🇬",
      "🇧🇭",
      "🇧🇮",
      "🇧🇯",
      "🇧🇱",
      "🇧🇲",
      "🇧🇳",
      "🇧🇴",
      "🇧🇶",
      "🇧🇷",
      "🇧🇸",
      "🇧🇹",
      "🇧🇻",
      "🇧🇼",
      "🇧🇾",
      "🇧🇿",
      "🇨🇦",
      "🇨🇨",
      "🇨🇩",
      "🇨🇫",
      "🇨🇬",
      "🇨🇭",
      "🇨🇮",
      "🇨🇰",
      "🇨🇱",
      "🇨🇲",
      "🇨🇳",
      "🇨🇴",
      "🇨🇵",
      "🇨🇷",
      "🇨🇺",
      "🇨🇻",
      "🇨🇼",
      "🇨🇽",
      "🇨🇾",
      "🇨🇿",
      "🇩🇪",
      "🇩🇬",
      "🇩🇯",
      "🇩🇰",
      "🇩🇲",
      "🇩🇴",
      "🇩🇿",
      "🇪🇦",
      "🇪🇨",
      "🇪🇪",
      "🇪🇬",
      "🇪🇭",
      "🇪🇷",
      "🇪🇸",
      "🇪🇹",
      "🇪🇺",
      "🇫🇮",
      "🇫🇯",
      "🇫🇰",
      "🇫🇲",
      "🇫🇴",
      "🇫🇷",
      "🇬🇦",
      "🇬🇧",
      "🇬🇩",
      "🇬🇪",
      "🇬🇫",
      "🇬🇬",
      "🇬🇭",
      "🇬🇮",
      "🇬🇱",
      "🇬🇲",
      "🇬🇳",
      "🇬🇵",
      "🇬🇶",
      "🇬🇷",
      "🇬🇸",
      "🇬🇹",
      "🇬🇺",
      "🇬🇼",
      "🇬🇾",
      "🇭🇰",
      "🇭🇲",
      "🇭🇳",
      "🇭🇷",
      "🇭🇹",
      "🇭🇺",
      "🇮🇨",
      "🇮🇩",
      "🇮🇪",
      "🇮🇱",
      "🇮🇲",
      "🇮🇳",
      "🇮🇴",
      "🇮🇶",
      "🇮🇷",
      "🇮🇸",
      "🇮🇹",
      "🇯🇪",
      "🇯🇲",
      "🇯🇴",
      "🇯🇵",
      "🇰🇪",
      "🇰🇬",
      "🇰🇭",
      "🇰🇮",
      "🇰🇲",
      "🇰🇳",
      "🇰🇵",
      "🇰🇷",
      "🇰🇼",
      "🇰🇾",
      "🇰🇿",
      "🇱🇦",
      "🇱🇧",
      "🇱🇨",
      "🇱🇮",
      "🇱🇰",
      "🇱🇷",
      "🇱🇸",
      "🇱🇹",
      "🇱🇺",
      "🇱🇻",
      "🇱🇾",
      "🇲🇦",
      "🇲🇨",
      "🇲🇩",
      "🇲🇪",
      "🇲🇫",
      "🇲🇬",
      "🇲🇭",
      "🇲🇰",
      "🇲🇱",
      "🇲🇲",
      "🇲🇳",
      "🇲🇴",
      "🇲🇵",
      "🇲🇶",
      "🇲🇷",
      "🇲🇸",
      "🇲🇹",
      "🇲🇺",
      "🇲🇻",
      "🇲🇼",
      "🇲🇽",
      "🇲🇾",
      "🇲🇿",
      "🇳🇦",
      "🇳🇨",
      "🇳🇪",
      "🇳🇫",
      "🇳🇬",
      "🇳🇮",
      "🇳🇱",
      "🇳🇴",
      "🇳🇵",
      "🇳🇷",
      "🇳🇺",
      "🇳🇿",
      "🇴🇲",
      "🇵🇦",
      "🇵🇪",
      "🇵🇫",
      "🇵🇬",
      "🇵🇭",
      "🇵🇰",
      "🇵🇱",
      "🇵🇲",
      "🇵🇳",
      "🇵🇷",
      "🇵🇸",
      "🇵🇹",
      "🇵🇼",
      "🇵🇾",
      "🇶🇦",
      "🇷🇪",
      "🇷🇴",
      "🇷🇸",
      "🇷🇺",
      "🇷🇼",
      "🇸🇦",
      "🇸🇧",
      "🇸🇨",
      "🇸🇩",
      "🇸🇪",
      "🇸🇬",
      "🇸🇭",
      "🇸🇮",
      "🇸🇯",
      "🇸🇰",
      "🇸🇱",
      "🇸🇲",
      "🇸🇳",
      "🇸🇴",
      "🇸🇷",
      "🇸🇸",
      "🇸🇹",
      "🇸🇻",
      "🇸🇽",
      "🇸🇾",
      "🇸🇿",
      "🇹🇦",
      "🇹🇨",
      "🇹🇩",
      "🇹🇫",
      "🇹🇬",
      "🇹🇭",
      "🇹🇯",
      "🇹🇰",
      "🇹🇱",
      "🇹🇲",
      "🇹🇳",
      "🇹🇴",
      "🇹🇷",
      "🇹🇹",
      "🇹🇻",
      "🇹🇼",
      "🇹🇿",
      "🇺🇦",
      "🇺🇬",
      "🇺🇲",
      "🇺🇳",
      "🇺🇸",
      "🇺🇾",
      "🇺🇿",
      "🇻🇦",
      "🇻🇨",
      "🇻🇪",
      "🇻🇬",
      "🇻🇮",
      "🇻🇳",
      "🇻🇺",
      "🇼🇫",
      "🇼🇸",
      "🇽🇰",
      "🇾🇪",
      "🇾🇹",
      "🇿🇦",
      "🇿🇲",
      "🇿🇼",
      "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
      "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
      "🏴󠁧󠁢󠁷󠁬󠁳󠁿"
    ]
  }
];

export default emojiList;
