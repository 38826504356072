
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ToggleSwitch extends Vue {

  @Prop() value!: boolean;
  @Prop({ default: false }) disabled!: boolean;

}
