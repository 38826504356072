
import handleError from "@/functions/handleError";
import axios from "axios";
import {Component, Vue, Watch} from "vue-property-decorator";

import PanelWrapper from "@/components/PanelWrapper.vue";
import Input from "@/components/Input.vue";

@Component({
  components: {
    PanelWrapper,
    Input
  }
})
export default class ChangePassword extends Vue {

  form = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: ""
  };

  isError = false;

  get changeDisabled() {
    const { oldPassword, newPassword, newPasswordRepeat } = this.form;

    return (
      oldPassword.length === 0
      || newPassword.length === 0
      || newPassword !== newPasswordRepeat
    );
  }

  async change(e: HTMLFormElement) {
    const { oldPassword, newPassword } = this.form;

    e.preventDefault();

    try {
      await axios.post("/profile/password", {
        old_password: oldPassword,
        password: newPassword
      });

      await this.$store.dispatch('goBackHistory');
    } catch (e) {
      await handleError(e);

      this.isError = true;
    }
  }

  @Watch('form.oldPassword')
  onFormChange() {
    this.isError = false;
  }

}
