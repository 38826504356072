import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { StructureUserI } from "@/types/structure";
import { SegmentsDataI, SegmentsUserI } from "@/types/segments";

interface SegmentsStateI {
  segments: SegmentsDataI | null,
  segmentsLoading: boolean,
  segmentsSelect: boolean,
  segmentsSelected: {
    user: Array<StructureUserI>,
    structure: Array<StructureUserI>,
    all: Array<StructureUserI>,
    segments: Array<SegmentsUserI>
  },
  segmentsExcluded: Array<StructureUserI>
}

@Module
export default class Segments extends VuexModule<SegmentsStateI> {
  segments: SegmentsStateI["segments"] = null;
  segmentsLoading: SegmentsStateI["segmentsLoading"] = false;
  segmentsSelect: SegmentsStateI["segmentsSelect"] = false;
  segmentsSelected: SegmentsStateI["segmentsSelected"] = {
    user: [],
    structure: [],
    all: [],
    segments: []
  };
  segmentsExcluded: SegmentsStateI["segmentsExcluded"] = [];

  get getSegments() {
    return this.segments;
  }

  get getSegmentsLoading() {
    return this.segmentsLoading;
  }

  get getSegmentsSelect() {
    return this.segmentsSelect;
  }

  get getSegmentsSelected() {
    return this.segmentsSelected;
  }

  get getSegmentsExcluded() {
    return this.segmentsExcluded;
  }

  @Mutation
  setSegments(segments: SegmentsStateI["segments"]) {
    this.segments = segments;
  }

  @Mutation
  setStructureLoading(status: boolean) {
    this.segmentsLoading = status;
  }

  @Mutation
  setSegmentsSelect(status: boolean) {
    this.segmentsSelect = status;
  }

  @Mutation
  setSegmentsSelected(segments: SegmentsStateI["segmentsSelected"]) {
    this.segmentsSelected = segments;
  }

  @Mutation
  setSegmentsExcluded(segments: SegmentsStateI["segmentsExcluded"]) {
    this.segmentsExcluded = segments;
  }

  @Mutation
  clearSegmentsSelected() {
    this.segmentsSelected = {
      user: [],
      structure: [],
      all: [],
      segments: []
    };
    this.segmentsExcluded = [];
  }
}
