import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=791dcc56&scoped=true&"
import script from "./Tabs.vue?vue&type=script&lang=ts&"
export * from "./Tabs.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./Tabs.vue?vue&type=style&index=0&id=791dcc56&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "791dcc56",
  null
  ,true
)

export default component.exports