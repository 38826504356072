<template>
  <div id="app">
    <app-child
      v-if="errorMessages.length === 0"
      v-bind="$props"
    ></app-child>
    <div v-else class="error-messages">
      <div
        class="error-messages__item"
        v-for="item in errorMessages"
        :key="item"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import AppChild from "@/AppChild.vue";

export default {
  props: {
    baseUrl: {
      type: String
    },
    cdnUrl: {
      type: String
    },
    locale: {
      type: String
    },
    mode: {
      type: String
    },
    token: {
      type: String
    },
    toUser: {
      type: String
    },
    updateInterval: {
      type: [Number, String],
      default: 30
    },
    readOnly: {
      type: String
    }
  },
  components: {
    AppChild
  },
  beforeMount() {
    const baseUrl = this.baseUrl ?? process.env.VUE_APP_BASE_URL;
    const cdnUrl = this.cdnUrl ?? process.env.VUE_APP_CDN_URL;
    const token = this.token ?? process.env.VUE_APP_TOKEN;
    const mode = this.mode ?? process.env.VUE_APP_MODE;
    const locale = this.locale ?? process.env.VUE_APP_I18N_LOCALE;
    const toUser = this.toUser ?? process.env.VUE_APP_TO_USER;

    if (!baseUrl) {
      this.errorMessages.push("BaseUrl is required");
    }

    if (!cdnUrl) {
      this.errorMessages.push("CdnUrl is required");
    }

    if (!mode) {
      this.errorMessages.push("Mode is required, options ('web', 'widget', 'chat')");
    }

    if (!locale) {
      this.errorMessages.push("Locale is required, options ('ru', 'en')");
    }

    if (mode !== "web") {
      if (!token) {
        this.errorMessages.push("Token is required in ('chat', 'widget') modes");
      }
    }

    if (mode === "chat") {
      if (!toUser) {
        this.errorMessages.push("ToUser is required in 'chat' mode");
      }
    }
  },
  data() {
    return {
      errorMessages: []
    };
  }
};
</script>
