
import axios from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";
import PanelWrapper from "@/components/PanelWrapper.vue";

import SelectPhone from "@/components/SelectPhone.vue";
import Input from "@/components/Input.vue";

import handleError from "@/functions/handleError";

import { findCode } from "@/data/phoneCodes";

@Component({
  components: {
    PanelWrapper,
    SelectPhone,
    Input
  }
})
export default class Login extends Vue {

  form = {
    codeItem: findCode("RU"),
    phone: "",
    password: ""
  };

  isError = false;

  get formValid() {
    const { phone, password } = this.form;

    return !(phone.length === 0 || password.length === 0);
  }

  async login(e: HTMLFormElement) {
    const { codeItem, phone, password } = this.form;

    e.preventDefault();

    const _code = codeItem.code.replace('+', '');
    const _phone = Number(_code + phone);

    try {
      const { data: { token } } = await axios.post("/auth/token", {
        phone: _phone,
        sms_code: password
      });

      localStorage.setItem('token', token);

      await this.$store.commit('setWidgetConfig', {
        token
      });

      this.$store.commit('setActiveScreen', { screen: 'tab' });
    } catch (e) {
      console.log(e);

      await handleError(e, "empty");

      this.isError = true;
    }
  }

  forgotPassword() {
    this.$store.commit('setActiveScreen', {
      screen: 'forgotPassword',
      data: {
        phone: this.form.phone
      }
    });
  }

  @Watch('form', { deep: true })
  onFormChange() {
    this.isError = false;
  }

}
