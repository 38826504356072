var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'skeleton': true,
    [`skeleton-${_vm.type}`]: true
  },style:({
    width: _vm.toPx(_vm.cWidth),
    height: _vm.toPx(_vm.cHeight),
    borderRadius: _vm.type !== 'circle' ? _vm.toPx(_vm.radius) : '50%'
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }