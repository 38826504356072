
import {Component, Vue} from "vue-property-decorator";

import cdnSource from "@/functions/cdnSource";

import { AppStateI } from "@/store/app";

interface IState {
  cdn: {
    [key: string]: string
  }
}

@Component
export default class ConfirmLogout extends Vue {

  cdn: IState["cdn"] = {
    close: cdnSource("@/assets/img/app/close.svg")
  };

  get app(): AppStateI {
    return this.$store.state.app;
  }

  async confirm() {
    localStorage.removeItem('token');

    window.location.reload();
  }

  close() {
    this.$store.dispatch('goBackHistory');
  }

}
