
import { Component, Vue } from "vue-property-decorator";

import Login from "@/panels/Login.vue";
import ForgotPassword from "@/panels/ForgotPassword.vue";
import ConfirmForgot from "@/panels/ConfirmForgot.vue";

@Component({
  components: {
    Login,
    ForgotPassword,
    ConfirmForgot
  }
})
export default class AuthScreen extends Vue {

  get activeScreen() {
    return this.$store.state.app.activeScreen;
  }

}
