<template>
  <div>
    <div class="time">
      {{ source.date }}
    </div>
    <message v-for="m in source.messagesSet" :key="m.id" :source="m" />
  </div>
</template>

<script>
import Message from "@/components/Chat/Message";

export default {
  components: {
    Message
  },
  props: {
    index: Number,
    source: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.time {
  margin: 8px 0;

  font-family: "Proxima Nova", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  text-align: center;

  color: #585757;
}
</style>
