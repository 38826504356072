import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import axios from "axios";

import {MessageI} from "@/types/message";

interface ChatStateI {
  messages: Array<MessageI>,
  loading: boolean,
  wasLoaded: boolean,
  fetchingDisabled: boolean
}

@Module
export default class Chat extends VuexModule<ChatStateI> {
  messages: ChatStateI["messages"] = [];
  loading: ChatStateI["loading"] = false;
  wasLoaded: ChatStateI["wasLoaded"] = false;
  fetchingDisabled: ChatStateI["fetchingDisabled"] = false;

  get getMessages() {
    return this.messages;
  }

  get getMessagesLoading() {
    return this.loading;
  }

  get getMessagesWasLoaded() {
    return this.wasLoaded;
  }

  get getMessagesFetchingDisabled() {
    return this.fetchingDisabled;
  }

  @Mutation
  setMessages(messages: ChatStateI["messages"]) {
    this.messages = messages;
  }

  @Mutation
  addMessageItem(message: MessageI) {
    this.messages.push(message);
  }

  @Mutation
  setLoading(status: boolean) {
    this.loading = status;
  }

  @Mutation
  setWasLoaded(status: boolean) {
    this.wasLoaded = status;
  }

  @Mutation
  setFetchingDisabled(status: boolean) {
    this.fetchingDisabled = status;
  }

  @Action
  async loadMessages(
    payload: {
      user_id: string,
      count: number,
      message_id?: string,
      order: string,
      loading?: boolean,
      intervalUpdate?: boolean
    }
  ) {
    const { commit } = this.context;

    if (payload.loading) {
      commit('setLoading', true);
    }

    let data = {
      messages: []
    };

    try {
      const history = await axios.post("/message/history", {
        ...payload
      });

      data = {
        ...history.data,
        messages: history.data.messages.filter((message: any) => message.tm)
      };
    } catch (e) {
      console.log(e);
    }

    console.info('Messages loaded', data);
    if (payload && payload.message_id) {
      // If scroll loading
      const messages = [...data.messages, ...this.messages];
      commit('setMessages', messages);
    } else {
      // If update messages
      if (payload.intervalUpdate) {
        const messages = [...this.messages];

        const newMessages: ChatStateI["messages"] = [];

        messages.concat(data.messages).forEach((obj) => {
          const index = newMessages.findIndex(x => x.id === obj.id);

          if (index !== -1) {
            newMessages.splice(index, 1, obj);
            return;
          }

          newMessages.push(obj);
        });

        commit('setMessages', newMessages);
      } else {
        commit('setLoading', false);
        commit('setMessages', data.messages);
      }
    }

    commit('setFetchingDisabled', data.messages.length !== Math.abs(payload.count));
    commit('setWasLoaded', true);

    return data;
  }

  @Action({ commit: 'setMessages' })
  async readMessages(payload: Array<string>) {
    await axios.post("/message/read", {
      messages: payload
    });

    const messages = [...this.messages];
    messages.forEach((item) => {
      if (item.incoming && !item.is_read) {
        item.is_read = true;
      }
    });

    return messages;
  }

  @Action
  async sendMessage(
    payload: {
      toUserId: string,
      text: string,
      file?: {
        type: "image" | "document",
        file_name: string,
        data: string,
        mime_type: string
      }
    }
  ) {
    return axios.post("/message", {
      type: 'Personal',
      to_user: payload.toUserId,
      text: payload.text,
      file: payload.file
    });
  }
}
