<template>
  <panel-wrapper v-if="profile && profile.user_id === app.dataPanel" class="profile">
    <div class="profile-inner">
      <Avatar class="avatar" :src="profileAvatar" :transport="transport" :size="60"></Avatar>
      <div class="info">
        <div class="name">{{ profile.full_name }}</div>
        <template v-if="isChildStructure">
          <div v-if="profile.location" class="address">{{ profile.location.city }}, {{ profile.location.country }}</div>
          <div v-if="profile.rank" class="profession">{{ profile.rank }}</div>
        </template>
      </div>
      <div class="actions">
        <button @click="openChat">{{ $t('profile.write') }}</button>
        <button v-if="isChildStructure" @click="openStructure">{{ $t('profile.inStructure') }}</button>
      </div>
      <div v-if="showProfile" class="block stat">
        <div v-if="profile.number" class="item">
          <div class="text">{{ profile.number }}</div>
          <div class="caption">{{ $t('profile.consNumber') }}</div>
        </div>
        <template v-if="isChildStructure">
          <div v-if="profile.phone" class="item">
            <div class="text">+{{ profile.phone }}</div>
            <div class="caption">{{ $t('profile.phone') }}</div>
          </div>
          <div v-if="profile.email" class="item">
            <div class="text">{{ profile.email }}</div>
            <div class="caption">{{ $t('profile.email') }}</div>
          </div>
        </template>
        <div v-if="profile.registration_date" class="item">
          <div class="text">{{ getDate(profile.registration_date) }}</div>
          <div class="caption">{{ $t('profile.registerAt') }}</div>
        </div>
        <div v-if="profile.last_order_date" class="item">
          <div class="text">{{ getDate(profile.last_order_date) }}</div>
          <div class="caption">{{ $t('profile.lastOrderAt') }}</div>
        </div>
      </div>
      <div class="block note" @click="editNote">
        <div class="caption">{{ $t('profile.note') }} <img :src="cdn.editNote" alt=""></div>
        <div v-if="profile.comment" class="text">{{ profile.comment }}</div>
        <div v-else class="placeholder">{{ $t('placeholders.noteText') }}</div>
      </div>
      <div v-if="profile.segments.length !== 0 || showScore" class="block segments">
        <div v-if="profile.segments.length !== 0">
          <div class="caption">{{ $t('profile.segments') }}</div>
          <div class="tags">
            <div
              v-for="(item, index) in profile.segments"
              class="item"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="showScore && isChildStructure">
          <div v-if="profile.segments.length !== 0" class="divider"></div>
          <div class="caption">{{ $t('profile.scores') }}</div>
          <div class="tags">
            <div v-if="profile.lo_bonus_scores" class="item">{{ $t('profile.bonusScores.lo') }}: {{ profile.lo_bonus_scores }}</div>
            <div v-if="profile.olg_bonus_scores" class="item">{{ $t('profile.bonusScores.olg') }}: {{ profile.olg_bonus_scores }}</div>
            <div v-if="profile.go_bonus_scores" class="item">{{ $t('profile.bonusScores.go') }}: {{ profile.go_bonus_scores }}</div>
          </div>
        </div>
      </div>
      <div v-if="isChildStructure" class="block ban">
        <div v-if="profile.has_childs && config.interface?.lockChildren" class="ban__item" @click="changeProhibition('broadcast')">
          <span class="ban__text">{{ $t('placeholders.allowBroadcast') }} - <span v-if="profile.broadcast_prohibition">{{ $t('mailingsProhibited') }}</span><span v-else>{{ $t('prohibitionAllowed') }}</span></span>
          <ToggleSwitch :value="!profile.broadcast_prohibition" />
        </div>
        <div class="ban__item" @click="changeProhibition('personal')">
          <span class="ban__text">{{ $t('placeholders.allowPersonal') }} - <span v-if="profile.personal_prohibition">{{ $t('mailingsProhibited') }}</span><span v-else>{{ $t('prohibitionAllowed') }}</span></span>
          <ToggleSwitch :value="!profile.personal_prohibition" />
        </div>
      </div>
    </div>
  </panel-wrapper>
  <skeleton-user v-else></skeleton-user>
</template>

<script>
import axios from "axios";

import Avatar from "@/components/Avatar";
import PanelWrapper from "@/components/PanelWrapper";
import SkeletonUser from "@/components/Skeleton/User";
import ToggleSwitch from "@/components/ToggleSwitch";

import cdnSource from "@/functions/cdnSource";
import handleError from "@/functions/handleError";

export default {
  components: {
    ToggleSwitch,
    Avatar,
    SkeletonUser,
    PanelWrapper
  },
  data() {
    return {
      banLoading: false,
      cdn: {
        close: cdnSource('@/assets/img/app/close.svg'),
        editNote: cdnSource('@/assets/img/profile/edit-note.svg')
      }
    };
  },
  async mounted() {
    await this.$store.dispatch('loadProfile',  {
      userId: this.app.dataPanel
    });
  },
  watch: {
    'app.dataPanel': {
      handler: function() {
        this.$store.dispatch('loadProfile',  {
          userId: this.app.dataPanel
        });
      }
    }
  },
  computed: {
    isChildStructure() {
      return this.profile.child_depth > 0;
    },
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    app() {
      return this.$store.state.app;
    },
    config() {
      return this.$store.getters['getConfig'];
    },
    profile() {
      return this.$store.getters['getProfile'];
    },
    profileAvatar() {
      if (!this.profile.avatar_upload_date) {
        return cdnSource('@/assets/img/default_avatar.svg');
      }

      return this.baseUrl + `/users/${this.profile.user_id}/avatar`;
    },
    transport() {
      return this.profile.preferred_transport;
    },
    showScore() {
      const { lo_bonus_scores, olg_bonus_scores, go_bonus_scores } = this.profile;

      return !!(
        lo_bonus_scores
        || olg_bonus_scores
        || go_bonus_scores
      );
    },
    showProfile() {
      const { registration_date, last_order_date } = this.profile;

      return !!(
        this.isChildStructure
        || registration_date
        || last_order_date
      );
    }
  },
  methods: {
    async changeProhibition(_type) {
      const userId = this.app.dataPanel;

      const type = `${_type}_prohibition`;
      const value = !this.profile[type];

      if (this.banLoading) {
        return;
      }

      this.banLoading = true;

      try {
        await axios.post("/users/" + userId, {
          [type]: value
        });

        await this.$store.commit('setSelectedProfile',  {
          ...this.profile,
          [type]: value
        });
      } catch (e) {
        await handleError(e);
      }

      this.banLoading = false;
    },
    goBack() {
      this.$store.dispatch('goBackHistory');
    },
    openChat() {
      this.$store.dispatch('setActiveViewAndPanel', {
        view: "communication",
        panel: "chat"
      });
      this.$store.commit('setSelectedContact', this.profile);
      this.$store.dispatch('loadMessages',  {
        user_id: this.profile.user_id,
        count: 100,
        order: 'asc',
        loading: true
      });
    },
    openStructure() {
      const { user_id } = this.profile;

      if (this.app.isMobile) {
        this.$store.dispatch('setActivePanel', { panel: "" });
      }

      this.$store.commit('setSubPanel', false);
      this.$store.dispatch('setActiveView', { view: 'structure' });
      this.$store.dispatch('loadStructure', { userId: user_id, updateParents: true });
    },
    getDate(date) {
      return this.$moment
        .utc(date)
        .local()
        .format('LLL');
    },
    editNote() {
      const { user_id, comment } = this.profile;

      this.$store.dispatch('setActivePanel', {
        panel: 'editNote',
        data: {
          userId: user_id,
          text: comment
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile {
  width: 100%;
  height: 100%;

  padding: 50px 0;

  position: relative;

  overflow-y: auto;

  background: #EFEFEF;

  &-inner {
    width: 400px;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 850px) {
      width: 100%;

      padding: 0 8px;
    }
  }

  .info {
    margin-top: 24px;

    text-align: center;

    div {
      margin-top: 2px;
    }

    .name {
      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      color: #242B32;
    }

    .address, .profession {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      color: #585757;
    }
  }

  .actions {
    margin-top: 16px;

    button {
      width: 136px;
      height: 34px;

      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      cursor: pointer;

      border: none;
      outline: none;
      border-radius: 20px;

      &:nth-child(1) {
        margin-right: 8px;

        color: #FEFEFE;
        background: linear-gradient(0deg, #E0727F, #E0727F);
      }

      &:nth-child(2) {
        border: 1px solid #E0727F;

        color: #E0727F;
        background: transparent;
      }
    }
  }

  .block {
    width: 100%;

    margin-top: 8px;
    padding: 20px;

    border-radius: 24px;
    background: #FEFEFE;
  }

  .stat {
    margin-top: 20px;

    padding: 4px 20px;

    .item {
      padding: 16px 0;

      position: relative;

      &:not(:last-child) {
        &::before {
          width: 100%;
          height: 1px;

          position: absolute;
          bottom: 0;

          content: "";

          background: rgba(31, 31, 31, 0.1);
        }
      }

      .text {
        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        color: #1F1F1F;
      }

      .caption {
        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        color: #585757;
      }
    }
  }

  .note {
    cursor: pointer;

    .caption, .text, .placeholder {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      color: #585757;
    }

    .caption {
      img {
        width: 11px;
        height: 11px;

        margin-left: 4px;
      }
    }

    .text, .placeholder {
      margin-top: 4px;

      color: #000000;
    }

    .placeholder {
      color: #929292;
    }
  }

  .segments {
    .caption {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      color: #585757;
    }

    .tags {
      margin: 8px 0;

      display: flex;
      flex-wrap: wrap;

      gap: 8px;

      .item {
        padding: 8px;

        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        border: 1px solid #E8E8E8;
        border-radius: 8px;
      }
    }

    .divider {
      width: 100%;
      height: 1px;

      margin: 16px 0;

      background: rgba(31, 31, 31, 0.1);
    }
  }

  .ban {
    margin-top: 8px;

    padding: 4px 20px;

    &__item {
      padding: 16px 0;

      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;

      &:not(:last-child) {
        &::before {
          width: 100%;
          height: 1px;

          position: absolute;
          bottom: 0;

          content: "";

          background: rgba(31, 31, 31, 0.1);
        }
      }
    }

    &__text {
      width: 70%;

      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      color: #585757;

      span {
        font-weight: 600;

        color: #1F1F1F;
      }
    }
  }
}
</style>
