
import axios from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";

import StructureList from "@/views/Structure/List.vue";
import StructureSegments from "@/views/Structure/Segments.vue";

import Burger from "@/components/Burger.vue";
import Search from "@/components/Search.vue";
import ViewTabs from "@/components/ViewTabs.vue";

import cdnSource from "@/functions/cdnSource";

import { AppStateI } from "@/store/app";
import { ConfigI } from "@/types/config";

@Component({
  components: {
    ViewTabs,
    Burger,
    Search,
    StructureList,
    StructureSegments
  }
})
export default class StructureView extends Vue {

  activeTab = 0;
  cdn = {
    search: cdnSource('@/assets/img/app/search.svg'),
    checked: cdnSource('@/assets/img/structure/checked.svg'),
    upload: cdnSource('@/assets/img/structure/upload.svg')
  };

  get segmentsSelected() {
    return this.$store.getters['getSegmentsSelected'];
  }

  get search() {
    return this.$store.state.app.subPanel;
  }

  get dataView() {
    return this.$store.state.app.dataView;
  }

  get app(): AppStateI {
    return this.$store.state.app;
  }

  get count() {
    let count = 0;

    ['user', 'structure', 'all', 'segments'].forEach((type) => count += this.segmentsSelected[type].length);

    return count;
  }

  get appConfig(): ConfigI {
    return this.$store.getters['getConfig'];
  }

  get showActionButton() {
    const { allowedMailingType } = this.appConfig.interface;

    return !allowedMailingType || allowedMailingType === 0;
  }

  get isMailSelect() {
    return this.$store.getters['getSegmentsSelect'];
  }

  async mounted() {
    const { dataView } = this.$store.state.app;

    if (!dataView) {
      const { data } = await axios.get("/structure");

      await this.$store.commit('setSegments', data);
    } else {
      this.activeTab = dataView.value;
    }
  }

  async destroyed() {
    this.$store.commit('setSegmentsSelect', false);
  }

  send(type: 'email' | 'chat') {
    this.$store.commit('clearSegmentsSelected');
    this.$store.dispatch('setActivePanel', {
      panel: "mailSend",
      data: {
        type
      }
    });
  }

  changeSearch(value: boolean) {
    this.$store.commit('setSubPanel', value);
  }

  handleSelectMail() {
    const { isMobile } = this.app;

    this.$store.commit('clearSegmentsSelected');

    if (!isMobile) {
      this.$store.dispatch('setActivePanel', { panel: "mailSelect" });
    }

    this.$store.commit('setSegmentsSelect', true);
  }

  handleUploadSegments() {
    this.$store.dispatch('setActiveViewAndPanel', {
      view: "structure",
      panel: "uploadSegments",
      viewHide: true
    });

    this.$store.commit('setSegmentsSelect', true);
  }

  goBack() {
    this.$store.commit('setSegmentsSelect', false);
  }

  @Watch('activeTab')
  onActiveTabChange(value: number) {
    this.$store.commit('clearSegmentsSelected');
  }

}
