
import axios from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";

import PanelWrapper from "@/components/PanelWrapper.vue";

import handleError from "@/functions/handleError";

@Component({
  components: {
    PanelWrapper
  }
})
export default class StatSend extends Vue {

  email = "";
  valid = false;

  constructor() {
    super();

    const { email } = this.profile;

    if (email) {
      this.email = email;
      this.valid = true;
    }
  }

  @Watch('email')
  onEmailChange(value: string) {
    this.valid = false;

    this.email = this.email
      .replace(/\s/g, '');

    if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/.test(value)) {
      this.valid = true;
    }
  }

  get profile() {
    return this.$store.getters['getMe'];
  }

  get dataPanel(): {
    id: string,
    status: string
  } {
    return this.$store.state.app.dataPanel;
  }

  async send() {
    const { id, status } = this.dataPanel;

    try {
      await axios.post("reporting/campaign_recipients/" + id, {
        parameters: {
          status: status !== 'recipient' ? status : undefined,
          email: this.email
        }
      });

      await this.$store.dispatch('setActivePanel', { panel: "successStatSend", data: { email: this.email } });
    } catch (e) {
      await handleError(e);

      console.log(e);
    }
  }

}
