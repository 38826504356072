
import { Component, Vue } from "vue-property-decorator";

import Avatar from "@/components/Avatar.vue";

import cdnSource from "@/functions/cdnSource";

import { ConfigI } from "@/types/config";
import { SegmentsUserI } from "@/types/segments";
import { StructureUserI } from "@/types/structure";

@Component({
  components: {
    Avatar
  }
})
export default class MailSelect extends Vue {

  cdn = {
    chevron: cdnSource('@/assets/img/structure/chevron.svg'),
    clear: cdnSource('@/assets/img/structure/clear.svg'),
    segment: cdnSource('@/assets/img/structure/segment.svg')
  }

  get segmentsSelected() {
    return this.$store.getters['getSegmentsSelected'];
  }

  get baseUrl() {
    return this.$store.getters['getBaseUrl'];
  }

  get count() {
    let count = 0;

    ['user', 'structure', 'all', 'segments'].forEach((type) => count += this.segmentsSelected[type].length);

    return count;
  }

  get appConfig(): ConfigI {
    return this.$store.getters['getConfig'];
  }

  get showActionButton() {
    const { allowedMailingType } = this.appConfig.interface;

    return !allowedMailingType || allowedMailingType === 1;
  }

  get segmentsExcluded() {
    return this.$store.getters['getSegmentsExcluded'];
  }

  get excludedList() {
    const segmentsSelected = { ...this.segmentsSelected };
    const segments = segmentsSelected['segments'];

    const segmentsExclude = segments.map((segment: SegmentsUserI) => segment.exclude);

    let excludedUsers: Array<StructureUserI> = [];

    segmentsExclude.forEach((exclude: SegmentsUserI["exclude"]) => {
      if (!exclude) {
        return;
      }

      excludedUsers = excludedUsers.concat(exclude);
    });

    return excludedUsers;
  }

  send(type: 'email' | 'chat') {
    this.$store.dispatch('setActivePanel', {
      panel: "mailSend",
      data: {
        type
      }
    });
  }

  removeUser(item: StructureUserI, type: string) {
    const segmentsSelected = { ...this.segmentsSelected };

    const index = segmentsSelected[type].findIndex((_item: StructureUserI) => item.user_id === _item.user_id);

    segmentsSelected[type].splice(index, 1);

    this.$store.commit('setSegmentsSelected', segmentsSelected);
  }

  removeUserFromExclude(item: StructureUserI) {
    const segmentsExcluded = [ ...this.segmentsExcluded ];

    const findIndex = segmentsExcluded.findIndex((_item: StructureUserI) => _item.user_id === item.user_id);

    if (findIndex !== -1) {
      segmentsExcluded.splice(findIndex, 1);
    }

    this.$store.commit('setSegmentsExcluded', segmentsExcluded);
  }

  removeSegment(item: SegmentsUserI) {
    const segmentsSelected = { ...this.segmentsSelected };

    const index = segmentsSelected['segments'].findIndex((_item: SegmentsUserI) => item.id === _item.id);

    segmentsSelected['segments'].splice(index, 1);

    this.$store.commit('setSegmentsSelected', segmentsSelected);
  }

  goBack() {
    this.$store.commit('setSegmentsSelect', false);

    this.$store.dispatch('goBackHistory');
  }

}
