<template>
  <div>
    <div
      :class="{ 'c-structure': true, 'c-divider': divider }"
    >
      <skeleton
        type="circle"
        :size="36"
      />
      <div class="info">
        <div class="name">
          <skeleton
            class="text"
            :width="100"
            :height="17"
          />
        </div>
        <div v-if="showText" class="text">
          <skeleton
            class="text"
            :width="60"
            :height="17"
          />
        </div>
      </div>
    </div>
    <div class="c-structure-list">
      <slot name="list"></slot>
    </div>
  </div>
</template>

<script>
import Skeleton from "@/components/Skeleton";

export default {
  components: {
    Skeleton
  },
  props: {
    divider: Boolean,
    showText: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.c-structure {
  padding: 12px;

  position: relative;

  display: flex;
  align-items: center;

  &-list {
    margin-left: 16px;
  }

  .info {
    margin-left: 12px;

    flex: 1;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1px;

    overflow: hidden;

    .name, .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    .text {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .after {
    padding: 5px;

    position: absolute;
    right: 10px;

    cursor: pointer;

    &:hover {
      .action {
        display: block;
        animation: fadeIn .5s forwards;
      }
    }

    .action {
      padding: 12px 0;

      position: absolute;
      top: -5px;
      right: 0;

      display: none;
      opacity: 0;

      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
      border-radius: 8px;

      z-index: 1000;

      transition: visibility 0s, opacity 1s linear;

      background: #FFFFFF;

      .item {
        padding: 6px 14px;

        font-family: "Proxima Nova", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        &:hover {
          background: #EFEFEF;
        }
      }
    }
  }
}

.c-divider {
  // padding + avatar size + design
  $padding: 12px + 36px;

  &:before, &:after {
    content: "";
    z-index: 100;

    background: #E0727F;
  }

  &:before {
    width: 2px;
    height: 14px;

    position: absolute;
    top: $padding + 5px;
    left: 30px;

    border-radius: 20px;
  }

  &:after {
    width: 6px;
    height: 6px;

    position: absolute;
    top: $padding + 9px;
    left: 28px;

    border-radius: 50%;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
