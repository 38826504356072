import Vue, {ComponentOptions} from 'vue';
import vueCustomElement from 'vue-custom-element';

import App from './App.vue';

import store from '@/store';

import i18n from '@/plugins/i18n';
import moment from '@/plugins/moment';

// import '@/plugins/axios';
import '@/plugins/virtual-list';
import '@/plugins/qrcode';
import '@/plugins/maxLength';
import '@/plugins/vmask';

Vue.use(vueCustomElement);
Vue.config.productionTip = false;

type appType = typeof App;

interface AppI extends appType {
  store?: typeof store,
  i18n?: typeof i18n,
  moment?: typeof moment
}

const AppWrapper: AppI = App;

AppWrapper.store = store;
AppWrapper.i18n = i18n;
AppWrapper.moment = moment;

Vue.customElement('chat-widget', App, {
  shadow: true,
  shadowCss: '', // TODO: Global css not working
  beforeCreateVueInstance(root) {
    const { el } = <{
      el: Element
    }>root;

    interface RootI extends ComponentOptions<Vue> {
      shadowRoot: any
    }

    const mainRoot = root as RootI;
    const rootNode = el.getRootNode();

    if (rootNode instanceof ShadowRoot) {
      mainRoot.shadowRoot = rootNode;
    } else {
      mainRoot.shadowRoot = document.head;
    }
    return root;
  },
});
