
import axios from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";

import PanelWrapper from "@/components/PanelWrapper.vue";
import SelectPhone from "@/components/SelectPhone.vue";
import Input from "@/components/Input.vue";

import handleError from "@/functions/handleError";

import { findCode } from "@/data/phoneCodes";

@Component({
  components: {
    PanelWrapper,
    SelectPhone,
    Input
  }
})
export default class ForgotPassword extends Vue {

  form = {
    codeItem: findCode("RU"),
    phone: ""
  };

  isError = false;

  constructor() {
    super();

    const { phone } = this.dataScreen;

    console.log(this.dataScreen);

    if (phone) {
      this.form.phone = phone;
    }
  }

  get dataScreen(): {
    phone: string
  } {
    return this.$store.state.app.dataScreen;
  }

  get forgotDisabled() {
    const { phone } = this.form;

    return phone.length === 0;
  }

  async forgotPassword(e: HTMLFormElement) {
    const { codeItem, phone } = this.form;

    e.preventDefault();

    const _code = codeItem.code.replace('+', '');
    const _phone = Number(_code + phone);

    try {
      await axios.post("/auth/login", {
        phone: _phone
      });

      this.$store.commit('setActiveScreen', {
        screen: 'confirmForgot',
        data: {
          code: codeItem.code,
          phone
        }
      });
    } catch (e) {
      console.log(e);

      await handleError(e, "empty");

      this.isError = true;
    }
  }

  goBack() {
    this.$store.commit('setActiveScreen', { screen: 'login' });
  }

  @Watch('form', { deep: true })
  onFormChange() {
    this.isError = false;
  }

}
