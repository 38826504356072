
import axios from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";

import PanelWrapper from "@/components/PanelWrapper.vue";
import SelectPhone from "@/components/SelectPhone.vue";
import Input from "@/components/Input.vue";

import declNum from "@/functions/declNum";

@Component({
  components: {
    PanelWrapper,
    SelectPhone,
    Input
  }
})
export default class ConfirmForgot extends Vue {

  $refs!: {
    inputs: Array<HTMLInputElement>
  };

  password = "";
  oneTimeCode = "";

  code = new Array(6).fill("");

  resendDelay = 60;

  interval: number | null = null;

  declNum = declNum;

  get dataPanel(): {
    code: string,
    phone: string
  } {
    return this.$store.state.app.dataScreen;
  }

  get username() {
    return this.dataPanel.phone;
  }

  get phone() {
    const { code, phone } = this.dataPanel;

    const _code = code.replace('+', '');

    return Number(_code + phone);
  }

  mounted() {
    this.interval = setInterval(() => {
      let newDelay = this.resendDelay - 1;

      if (newDelay < 0) {
        newDelay = 0;
      }

      this.resendDelay = newDelay;
    }, 1000);

    this.$refs.inputs[0].focus();
  }

  destroyed() {
    if (!this.interval) {
      return;
    }

    clearInterval(this.interval);
  }

  formatPhoneNumber(str: string) {
    //Filter only numbers from the input
    let cleaned = str.replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (match) {
      return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
    }

    return null;
  }

  handleInputChange(e: Event) {
    const { value, dataset } = e.target as HTMLInputElement;
    const { index } = dataset as {
      index: string
    };

    const _index = +index;

    // If paste code
    if (/^\d+$/.test(value) && value.length >= 6) {
      console.log(value);

      let pasteCode = value.split("");

      if (pasteCode.length > 6) {
        pasteCode = pasteCode.splice(-6);
      }

      this.$refs.inputs[5].focus();

      this.code = pasteCode;

      this.confirmForgot();

      return;
    }

    // Replace value
    if (value.length > 1) {
      const valueSplit = value.split("");

      this.code[_index] = valueSplit[1];
    }
  }

  handleInputKeyUp(e: KeyboardEvent) {
    const { dataset } = e.target as HTMLInputElement;
    const { index } = dataset as {
      index: string
    };

    const allowedKeys = new Array(10)
      .fill("")
      .map((_, index) => String(index));

    allowedKeys.push('Backspace');

    if (!allowedKeys.includes(e.key)) {
      return;
    }

    const isNext = e.key !== 'Backspace';

    let nextIndex = +index;

    if (isNext) {
      nextIndex += 1;

      if (nextIndex > 5) {
        nextIndex = 0;

        this.confirmForgot();
      }
    } else {
      nextIndex -= 1;

      if (nextIndex < 0) {
        nextIndex = 0;
      }
    }

    this.$refs.inputs[nextIndex].focus();
  }

  async confirmForgot(e?: HTMLFormElement) {
    const code = this.code.join("");

    if (e) {
      e.preventDefault();
    }

    try {
      const { data: { token } } = await axios.post("/auth/token", {
        phone: this.phone,
        sms_code: +code
      });

      localStorage.setItem('token', token);

      await this.$store.commit('setWidgetConfig', {
        token
      });

      this.$store.commit('setActiveScreen', { screen: 'tab' });
    } catch (e) {
      console.log(e);

      await this.$store.dispatch('sendNotification', {
        type: 'error',
        text: 'Неверный код',
        position: 'empty'
      });
    }
  }

  async resendCode() {
    this.code = [];

    try {
      await axios.post("/auth/login", {
        phone: this.phone
      });

      await this.$store.dispatch('sendNotification', {
        type: 'success',
        text: 'Код отправлен',
        position: 'empty'
      });

      this.resendDelay = 60;
    } catch (e) {
      console.log(e);
    }
  }

  goBack() {
    this.$store.commit('setActiveScreen', { screen: 'forgotPassword' });
  }

  @Watch('code')
  onCodeChange(value: Array<string>) {
    const code = value.join("");

    this.oneTimeCode = code;
    this.password = code;
  }

  @Watch('oneTimeCode')
  onOneTimeCodeChange(value: string) {
    this.code = value.split("");
  }

}
