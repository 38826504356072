
import { UserI } from "@/types/user";
import { Component, Prop, Vue } from "vue-property-decorator";

import Avatar from "@/components/Avatar.vue";

import cdnSource from "@/functions/cdnSource";

interface IProps {
  source: UserI
}

interface IState {
  cdn: {
    [key: string]: string
  }
}

@Component({
  components: {
    Avatar
  }
})
export default class User extends Vue {

  @Prop({ required: true }) source!: IProps["source"];

  cdn: IState["cdn"];

  constructor() {
    super();

    this.cdn = {
      after: cdnSource('@/assets/img/app/after.svg')
    };
  }

  get userId() {
    return this.source.user_id;
  }

  get baseUrl() {
    return this.$store.getters['getBaseUrl'];
  }

  get avatarUrl() {
    if (!this.source.avatar_upload_date) {
      return cdnSource('@/assets/img/default_avatar.svg');
    }

    return this.baseUrl + `/users/${this.userId}/avatar`;
  }

  goProfile(e: MouseEvent) {
    e.preventDefault();

    this.$store.dispatch('setActivePanel', { panel: "user", data: this.userId });
  }

  goChat(e: MouseEvent) {
    e.preventDefault();

    this.$store.dispatch('setActiveView', { view: "communication" });
    this.$store.dispatch('setActivePanel', { panel: "chat" });
    this.$store.commit('setSelectedContact', this.source);
    this.$store.dispatch('loadMessages',  {
      user_id: this.userId,
      count: 100,
      order: 'asc',
      loading: true
    });
  }

}
