
import {Component, Vue} from "vue-property-decorator";

import PanelWrapper from "@/components/PanelWrapper.vue";
import User from "@/components/User.vue";

@Component({
  components: {
    PanelWrapper,
    User
  }
})
export default class BroadcastList extends Vue {

  get dataPanel(): {
    type: "broadcast" | "personal"
  } {
    console.log(this.$store.state.app);
    return this.$store.state.app.dataPanel;
  }

  get prohibitionList() {
    const listType = this.dataPanel.type === "broadcast" ? "broadcast_prohibition_list" : "broadcast_prohibition_list";

    return this.$store.getters['getMe'][listType];
  }

  get showList() {
    return this.prohibitionList.length !== 0;
  }

}
