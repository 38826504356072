<template>
  <div>
    <skeleton-structure divider></skeleton-structure>
    <skeleton-structure divider></skeleton-structure>
    <skeleton-structure>
      <template #list>
        <skeleton-structure></skeleton-structure>
        <skeleton-structure></skeleton-structure>
        <skeleton-structure></skeleton-structure>
      </template>
    </skeleton-structure>
  </div>
</template>

<script>
import SkeletonStructure from "@/components/Skeleton/Structure";

export default {
  components: {
    SkeletonStructure
  }
};
</script>