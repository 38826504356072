<template>
  <div class="c-search" ref="search" @resize.capture="calculateSearchHeight">
    <div class="input">
      <img
        class="back"
        :src="cdn.back"
        alt=""
        @click="close"
      >
      <label>
        <input
          v-model="value"
          type="text"
          :placeholder="$t('placeholders.search')"
          :disabled="loading"
        >
        <img
          :class="{ 'clear': true, 'clear-active': showClear }"
          :src="cdn.clear"
          alt=""
          @click="clear"
        >
      </label>
    </div>
    <ViewTabs
      v-if="showTabs && config.interface.globalSearchAvailable"
      v-model="activeTab"
      :tabs="tabs"
    />
    <div class="content">
      <div v-if="!loading">
        <virtual-list
          v-if="search && search.length !== 0"
          class="list scroll"

          :style="'height: ' + searchHeight + '!important;overflow-y: auto;'"

          :data-key="'user_id'"
          :data-sources="search"
          :data-component="itemComponent"

          @tobottom="paginationLoadSearch"
        >
          <div slot="footer" v-show="fetching" class="fetching">
            <Spinner :absolute="false" />
          </div>
        </virtual-list>
        <div v-else class="placeholder">
          <div class="img">
            <img :src="cdn.person" alt="">
          </div>
          <div class="text">{{ $t('placeholders.searchWrite') }}</div>
        </div>
      </div>
      <div v-else class="list">
        <skeleton-structure
          v-for="(_, index) in new Array(5).fill(null)"
          :key="index"
          :showText="false"
        ></skeleton-structure>
      </div>
    </div>
  </div>
</template>

<script>
import cdnSource from "@/functions/cdnSource";

import Structure from "@/components/Structure";
import Spinner from "@/components/Spinner";
import SkeletonStructure from "@/components/Skeleton/Structure";
import ViewTabs from "@/components/ViewTabs";

export default {
  components: {
    ViewTabs,
    Spinner,
    SkeletonStructure
  },
  data() {
    return {
      tabs: [this.$t('searchTabs.structure')],
      activeTab: 0,
      searchHeight: '200px',
      fetching: false,
      fetchingDisabled: false,
      cdn: {
        back: cdnSource('@/assets/img/app/back.svg'),
        person: cdnSource('@/assets/img/app/person.svg'),
        clear: cdnSource('@/assets/img/app/clear.svg')
      },
      value: '',
      timeout: null,
      loading: false,
      itemComponent: Structure
    };
  },
  beforeCreate() {
    this.$store.commit('setSearch', null);
  },
  mounted() {
    const { globalSearchCaption, globalSearchAvailable } = this.config.interface;

    if (globalSearchAvailable) {
      this.tabs.push(
        String(this.$t('searchTabs.network'))
          .replace('%{name}%', globalSearchCaption)
      );
    }

    this.ro = new ResizeObserver(this.calculateSearchHeight);
    this.ro.observe(this.$refs.search);
  },
  computed: {
    config() {
      return this.$store.getters['getConfig'];
    },
    structure() {
      return this.$store.getters['getStructure'];
    },
    search() {
      return this.$store.getters['getSearch'];
    },
    type() {
      return this.activeTab !== 0 ? '/global' : '';
    },
    showClear() {
      return this.value.length > 0;
    },
    childs() {
      return this.structure && this.structure.childs.childs;
    },
    showTabs() {
      return this.childs && this.childs.length !== 0;
    }
  },
  methods: {
    async loadSearch() {
      if (this.value.length <= 2) {
        return;
      }

      if (this.loading) {
        return;
      }

      this.loading = true;

      await this.$store.dispatch('loadSearch', {
        type: this.type,
        params: {
          search: this.value
        }
      });

      this.loading = false;
    },
    async paginationLoadSearch() {
      if (this.fetching || this.fetchingDisabled) {
        return;
      }

      this.fetching = true;

      const data = await this.$store.dispatch('loadSearch', {
        type: this.type,
        params: {
          search: this.value,
          offset: this.search.length
        }
      });

      this.fetching = false;
      this.fetchingDisabled = data.count !== data.max_count;
    },
    calculateSearchHeight() {
      if (!this.$refs.search) {
        return;
      }

      this.searchHeight = (this.$refs.search.clientHeight - (this.showTabs ? 110 : 70)) + 'px';
    },
    clear() {
      if (this.loading) {
        return;
      }

      this.value = "";
    },
    close() {
      setTimeout(() => {
        clearTimeout(this.timeout);

        this.value = "";
        this.loading = false;
        this.$store.commit('setSearch', null);
      }, 300);

      this.$emit('close');
    }
  },
  watch: {
    value() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(this.loadSearch, 1000);
    },
    childs(value) {
      this.activeTab = value.length === 0 ? 1 : 0;
    },
    activeTab() {
      this.loadSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
.c-search {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: -100%;

  transition: .3s ease-in-out;

  z-index: 900;

  background: #FFFFFF;

  @media screen and (max-width: 850px) {
    &::before {
      display: none;
    }
  }

  &::before {
    width: 1px;
    height: 100%;

    position: absolute;
    top: 0;
    right: 0;

    content: "";

    background: rgba(31, 31, 31, 0.1);
  }

  &-active {
    left: 0;
  }

  .input {
    margin: 24px 8px 8px 8px;

    display: flex;
    align-items: center;

    .back {
      cursor: pointer;
    }

    img {
      padding: 9px;
    }

    label {
      position: relative;

      display: flex;
      align-items: center;
      flex: 1;

      overflow: hidden;

      input {
        width: 100%;

        padding: 10px 30px 10px 14px;

        font-family: "Proxima Nova", sans-serif;
        font-size: 14px;
        line-height: 17px;

        border: none;
        outline: none;
        border-radius: 12px;

        background: #EFEFEF;

        &::placeholder {
          color: #929292;
        }
      }

      .clear {
        position: absolute;
        right: -30px;

        transition: .5s ease-in-out;

        cursor: pointer;

        &-active {
          right: 0;
        }
      }
    }
  }

  .content {
    .placeholder {
      margin: 55px 16px 0 16px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .img {
        width: 51px;
        height: 51px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;

        background: #EFEFEF;
      }

      .text {
        font-family: "Proxima Nova", sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;

        text-align: center;

        color: #585757;
      }
    }

    .list {
      padding: 12px 8px;

      .fetching {
        margin: 10px;

        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
