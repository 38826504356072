
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";

import SegmentsItem from "@/components/Segments.vue";

import { findNodeById } from "@/functions/segments";
import cdnSource from "@/functions/cdnSource";

import { SegmentsDataI, SegmentsUserI } from "@/types/segments";
import { AppStateI } from "@/store/app";

@Component({
  components: {
    SegmentsItem
  }
})
export default class StructureSegments extends Vue {

  cdn = {
    chevron: cdnSource('@/assets/img/structure/chevron.svg')
  }

  get app(): AppStateI {
    return this.$store.state.app;
  }

  get dataView() {
    return this.$store.state.app.dataView;
  }

  get segments(): SegmentsDataI | null {
    return this.$store.getters['getSegments'];
  }

  get segmentsSelected() {
    return this.$store.getters['getSegmentsSelected'];
  }

  async loadStructureById(_item: SegmentsUserI) {
    const segments = JSON.parse(JSON.stringify(this.segments));

    const item = findNodeById(segments, _item.id);

    if (!item) {
      return;
    }

    item.isOpen = !item.isOpen;

    if (!item.isOpen) {
      await this.$store.commit('setSegments', segments);
      return;
    }

    item.isLoading = true;

    await this.$store.commit('setSegments', segments);

    const { data } = await axios.get("/structure/" + item.id);

    item.isLoading = false;
    item.data = data;

    await this.updateSelectedSegment(item);
    await this.$store.commit('setSegments', segments);
  }

  async loadStructureOffsetById(_item: SegmentsUserI, offset = 0) {
    const segments = JSON.parse(JSON.stringify(this.segments));

    const item = findNodeById(segments, _item.id);

    if (!item) {
      return;
    }

    const { data } = await axios.get("/structure/" + _item.id, {
      params: {
        offset
      }
    });

    item.data = {
      ...data,
      users: item.data?.users ? [...item.data?.users, ...data.users] : []
    };

    await this.$store.commit('setSegments', segments);
  }

  async updateSelectedSegment(item: SegmentsUserI) {
    const segmentsSelected = { ...this.segmentsSelected };

    const findIndex = segmentsSelected['segments'].findIndex((_item: SegmentsUserI) => _item.id === item.id);

    if (findIndex === -1) {
      return;
    }

    segmentsSelected['segments'][findIndex] = item;

    await this.$store.commit('setSegmentsSelected', segmentsSelected);
  }

}
