<template>
  <div>
    <div
      :class="{
        'c-structure': true,
        'c-divider': divider,
        'c-structure-active': active,
        'c-structure-disabled': disabled
      }"
    >
      <div class="c-structure__inner" @click="!disabled && $emit('click')">
        <Avatar
          :src="avatarUrl"
          :transport="source.preferred_transport"
          :size="36"
        ></Avatar>
        <div class="info" :style="`margin-right: ${isAfterShown ? 30 : 0}px`">
          <div class="name">
            <span :class="{ 'name-personal': disableBroadcast }">{{ source.full_name }}</span>
            <img v-if="source.has_childs" class="name__structure" :src="cdn.hasChilds" alt="">
            <img
              v-if="source.broadcast_prohibition || source.personal_prohibition"
              class="name__lock"
              :src="cdn.lock" alt=""
            >
          </div>
          <div class="text">{{ source.rank }}</div>
        </div>
      </div>
      <template v-if="isAfterShown">
        <div
          v-if="!isSelect"
          class="after"
          @mouseover="showAction = true"
          @mouseout="showAction = false"
        >
          <img :src="cdn.after" alt="" />
        </div>
        <div
          v-if="isSelect && !disableBroadcast"
          class="after"
          @mouseover="showAction = true"
          @mouseout="showAction = false"
        >
          <checkbox :value="isSelected" @click.native="handleCheckbox" />
        </div>
        <div
          v-if="!isSelect || !structureSelected && !isSelected"
          :class="{
            'action': true,
            'action-checkbox': true,
            'action-shown': showAction
          }"
          @mouseover="showAction = true"
          @mouseout="showAction = false"
        >
          <div class="action__popup">
            <template v-if="!isSelect">
              <div class="item" @click="goProfile">{{ $t('structure.profile') }}</div>
              <div class="item" @click="goChat">{{ $t('structure.chat') }}</div>
            </template>
            <template v-else>
              <div class="item" @click="(e) => addUserToSelect(e, source, 'user')">Выбрать пользователя</div>
              <div class="item" @click="(e) => addUserToSelect(e, source, 'structure')">В прямом подчинении</div>
              <div class="item" @click="(e) => addUserToSelect(e, source, 'all')">Всю структуру</div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="c-structure-list">
      <slot name="list"></slot>
    </div>
  </div>
</template>

<script>
import cdnSource from "@/functions/cdnSource";

import Avatar from "@/components/Avatar";
import Checkbox from "@/components/Checkbox";

export default {
  components: {
    Checkbox,
    Avatar
  },
  props: {
    active: Boolean,
    divider: Boolean,
    disabled: Boolean,
    source: Object,
    structureSelected: Boolean,
    isSelect: Boolean,
    isSelected: Boolean
  },
  data() {
    return {
      cdn: {
        after: cdnSource('@/assets/img/app/after.svg'),
        lock: cdnSource('@/assets/img/structure/lock.svg'),
        hasChilds: cdnSource('@/assets/img/structure/has-childs.svg')
      },
      showAction: false
    };
  },
  computed: {
    me() {
      return this.$store.getters['getMe'];
    },
    baseUrl() {
      return this.$store.getters['getBaseUrl'];
    },
    avatarUrl() {
      return `${this.baseUrl}/users/${this.source.user_id}/avatar`;
    },
    isAfterShown() {
      const { me, source } = this;

      return me.user_id !== source.user_id;
    },
    segments() {
      return this.$store.getters['getSegments'];
    },
    segmentsSelected() {
      return this.$store.getters['getSegmentsSelected'];
    },
    disableBroadcast() {
      const {
        personal_prohibition,
        broadcast_prohibition,
        broadcast_prohibition_structure
      } = this.source;

      return personal_prohibition || broadcast_prohibition || broadcast_prohibition_structure;
    }
  },
  watch: {
    isSelect() {
      this.showAction = false;
    }
  },
  methods: {
    preventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    goProfile(e) {
      const { source } = this;

      this.preventDefault(e);

      this.$store.dispatch('setActiveViewAndPanel', {
        view: "communication",
        panel: "user",
        dataPanel: source.user_id,
        viewHide: true
      });
    },
    goChat(e) {
      const { source } = this;

      this.preventDefault(e);

      this.$store.dispatch('setActiveViewAndPanel', {
        view: "communication",
        panel: "chat"
      });

      this.$store.commit('setSelectedContact', source);
      this.$store.dispatch('loadMessages',  {
        user_id: source.user_id,
        count: 100,
        order: 'asc',
        loading: true
      });
    },
    addUserToSelect(e, item, type) {
      const segmentsSelected = { ...this.segmentsSelected };

      e.preventDefault();
      e.stopPropagation();

      segmentsSelected[type].push({ ...item });

      this.$store.commit('setSegmentsSelected', segmentsSelected);
    },
    handleCheckbox(e) {
      e.preventDefault();
      e.stopPropagation();

      this.showAction = !this.isSelected;

      this.$emit('checkbox', {
        item: this.source,
        selected: this.structureSelected
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.c-structure {
  padding: 12px;

  position: relative;

  display: flex;
  align-items: center;

  @media screen and (min-width: 850px) {
    &:hover {
      cursor: pointer;
      border-radius: 16px;
      background: #EFEFEF;
    }
  }

  &-disabled {
    &:hover {
      cursor: default;
      background: transparent;
    }
  }

  &-active {
    border-radius: 16px;
    background: #EFEFEF;
  }

  &-list {
    margin-left: 16px;
  }

  &__inner {
    width: 100%;

    display: flex;
    align-items: center;
  }

  .info {
    margin-left: 12px;

    flex: 1;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    overflow: hidden;

    .name {
      display: flex;
      align-items: center;
      gap: 5px;

      font-family: "Proxima Nova", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      &-personal {
        opacity: .5;
      }

      &__structure {
        width: 9px;
        height: 10px;
      }

      &__lock {
        width: 8px;
        height: 12px;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .text {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      line-height: 17px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .after {
    padding: 5px 10px;

    position: absolute;
    right: 5px;

    cursor: pointer;
  }

  .action {
    position: absolute;
    top: 10px;
    right: 0;

    display: none;
    opacity: 0;

    z-index: 1000;

    transition: visibility 0s, opacity 1s linear;

    &-checkbox {
      width: 185px;
    }

    &-shown {
      display: block;
      animation: fadeIn .5s forwards;
    }

    &__popup {
      padding: 12px 0;

      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
      border-radius: 8px;

      background: #FFFFFF;

      @media screen and (max-width: 850px) {
        margin-right: 30px;
      }
    }

    .item {
      padding: 6px 14px;

      font-family: "Proxima Nova", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      &:hover {
        background: #EFEFEF;
      }
    }
  }

  .checkbox {
    width: 18px;
    height: 18px;

    border-radius: 50%;

    transition: border .3s ease, background .3s ease;

    border: 2px solid rgba(0, 0, 0, 0.2);

    &-active {
      border: 2px solid #E0727F;

      background: #E0727F;
    }
  }
}

.c-divider {
  // padding + avatar size + design
  $padding: 12px + 36px;

  &:before, &:after {
    content: "";
    z-index: 100;

    background: #E0727F;
  }

  &:before {
    width: 2px;
    height: 14px;

    position: absolute;
    top: $padding + 5px;
    left: 30px;

    border-radius: 20px;
  }

  &:after {
    width: 6px;
    height: 6px;

    position: absolute;
    top: $padding + 9px;
    left: 28px;

    border-radius: 50%;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
