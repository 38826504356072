import store from '@/store';

export default function cdnSource(file: string) {
  const path = String(file).replace('@/assets/', '');

  if (process.env.NODE_ENV === 'production') {
    const cdUrl = store.getters['getCdnUrl'];

    if (!cdUrl) {
      return path;
    }

    return `${cdUrl}/${path}`;
  } else {
    return require("@/assets/" + path);
  }

}
