<template>
  <div v-if="app.isMobile" class="c-burger">
    <div class="c-burger__icon" @click="menu = true">
      <img :src="cdn.burger" alt="">
    </div>
    <div :class="{ 'menu': true, 'menu-active': menu }">
      <img
        class="close"
        :src="cdn.close"
        alt=""
        @click="menu = false"
      >
      <div class="title">{{ $t('menu') }}</div>
      <div class="list">
        <div
          v-for="(item, index) in list"
          :class="{
            'item': true,
            'item-active': item.view === app.activeView
          }"
          :key="index"
          @click="changeTab(item)"
        >
          {{ $t(`tabs.${item.view}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cdnSource from "@/functions/cdnSource";

export default {
  data() {
    return {
      menu: false,
      list: [
        {
          view: "communication"
        },
        {
          view: "structure"
        },
        {
          view: "profile",
          panel: "profile",
          disableView: true
        }
      ],
      cdn: {
        burger: cdnSource("@/assets/img/app/burger.svg"),
        close: cdnSource("@/assets/img/app/close.svg")
      }
    };
  },
  computed: {
    me() {
      return this.$store.getters['getMe'];
    },
    app() {
      return this.$store.state.app;
    }
  },
  methods: {
    changeTab(item) {
      const { activeView } = this.app;

      if (item.view === activeView) {
        return;
      }

      if (item.view === 'structure') {
        this.$store.dispatch('loadStructure', { userId: this.me.user_id, updateParents: true });
      }

      this.$store.dispatch('setActiveView', {
        view: item.view,
        viewHide: item.disableView
      });

      if (item.panel) {
        this.$store.dispatch('setActivePanel', { panel: item.panel });
      }

      this.menu = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.c-burger {
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu {
    width: 100%;
    height: 100%;

    padding: 30px 20px;

    position: fixed;
    top: 0;
    left: -100%;

    z-index: 500;
    transition: .3s left ease-in-out;

    background: #1F1F1F;

    &-active {
      left: 0;
    }

    .close {
      position: absolute;
      top: 37px;
      left: 22px;

      z-index: 1050;
    }

    .title {
      font-family: "Proxima Nova", sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;

      text-align: center;

      color: #ffffff;
    }

    .list {
      width: 100vw;
      height: 100vh;

      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      z-index: 1040;

      .item {
        font-family: "Proxima Nova", sans-serif;
        font-size: 24px;
        line-height: 29px;

        color: rgba(#FFFFFF, .55);

        &-active {
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
