
import {Component, Vue} from "vue-property-decorator";

import cdnSource from "@/functions/cdnSource";

import { AppStateI } from "@/store/app";
import { AxiosError } from "axios";

interface IState {
  copyTimeout: number | null,
  copied: boolean,
  cdn: {
    [key: string]: string
  }
}

@Component
export default class ErrorResponse extends Vue {

  copyTimeout: IState["copyTimeout"] = null;
  copied: IState["copied"] = false;

  cdn: IState["cdn"];

  constructor() {
    super();

    this.cdn = {
      close: cdnSource("@/assets/img/app/close.svg")
    };
  }

  get app(): AppStateI {
    return this.$store.state.app;
  }

  get dataModal(): AxiosError {
    return this.$store.state.app.dataModal;
  }

  beforeUnmount() {
    if (this.copyTimeout) {
      clearTimeout(this.copyTimeout);
    }
  }

  copy() {
    this.copied = true;

    navigator.clipboard.writeText(JSON.stringify(this.dataModal));

    this.copyTimeout = setTimeout(() => this.copied = false, 3000);
  }

  close() {
    this.$store.dispatch('goBackHistory');
  }

}
