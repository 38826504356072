
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

interface IProps {
  type?: 'rect' | 'circle',
  width?: number,
  height?: number,
  size?: number,
  radius?: number
}

@Component
export default class Skeleton extends Vue {

  @Prop({ default: 'rect' }) type: IProps["type"];
  @Prop() width: IProps["width"];
  @Prop() height: IProps["height"];
  @Prop() size: IProps["size"];
  @Prop({ default: 8 }) radius: IProps["radius"];

  get cWidth() {
    return this.width ? this.width : this.size;
  }

  get cHeight() {
    return this.height ? this.height : this.size;
  }

  toPx(number: number) {
    return number + "px";
  }

}
