
import { Component, Vue } from "vue-property-decorator";

import PanelWrapper from "@/components/PanelWrapper.vue";

@Component({
  components: {
    PanelWrapper
  }
})
export default class SuccessStatSend extends Vue {

  get dataPanel(): {
    email: string
  } {
    return this.$store.state.app.dataPanel;
  }

  async goBack() {
    await this.$store.dispatch('goBackHistory', { backCount: 3 });
  }

}
