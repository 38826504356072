<template>
  <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.76465 4.45882L9.11759 6.76471L14.9999 1" :stroke="fill" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 4.45882L3.35294 6.76471L9.23529 1" :stroke="fill" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    fill: String
  }
};
</script>
