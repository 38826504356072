import { SegmentsDataI, SegmentsUserI } from "@/types/segments";
import { StructureUserI } from "@/types/structure";

export function findNodeById(
  data: SegmentsDataI,
  id: string
): SegmentsUserI | null {
  if (!data) {
    return null;
  }

  const stack: SegmentsUserI[] = [...data.nodes];

  while (stack.length > 0) {

    const item = stack.pop()!!;
    if (item.id === id) {
      return item ?? null;
    }

    const { data } = item;
    if (!data) {
      continue;
    }

    data.nodes?.forEach((it) => stack.push(it));

  }

  return null;
}

export function findUserById(
  data: SegmentsDataI,
  id: string
): StructureUserI | null {
  if (!data) {
    return null;
  }

  const stack: SegmentsUserI[] = [...data.nodes];

  while (stack.length > 0) {

    const item = stack.pop()!!;

    const { data } = item;
    if (!data) {
      continue;
    }

    const foundUser = data.users?.find((it) => it.user_id === id);
    if (foundUser) {
      return foundUser;
    }

    data.nodes?.forEach((it) => stack.push(it));

  }

  return null;
}
