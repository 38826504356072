import Vue from "vue";
import VueMoment from "vue-moment";
import moment from 'moment';

Vue.use(VueMoment, { moment });

export default function install(Vue: any) {
  Object.defineProperties(Vue.prototype, {
    $moment: {
      get() {
        return moment;
      }
    }
  });
}
