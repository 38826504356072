
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import cdnSource from "@/functions/cdnSource";

import phoneCodes, { PhoneCodeItem } from "@/data/phoneCodes";

@Component
export default class SelectPhone extends Vue {

  @Prop({ required: true }) value!: PhoneCodeItem;

  $refs!: {
    list: HTMLDivElement
  };

  phoneCodes = phoneCodes;

  isOpened = false;

  cdn = {
    triangle: cdnSource("@/assets/img/app/triangle.svg")
  };

  mounted() {
    document.addEventListener('keyup', this.handleKeyListener);
  }

  destroyed() {
    document.removeEventListener('keyup', this.handleKeyListener);
  }

  getFlag(img: string) {
    return cdnSource(`@/assets/img/flags/${img}.svg`);
  }

  handleKeyListener(e: any) {
    console.log(e);
  }

  handleClick(item: PhoneCodeItem) {
    this.$emit('input', item);
  }

  @Watch('isOpened')
  onOpenedChange(value: boolean) {
    if (!this.$refs.list || value) {
      return;
    }

    setTimeout(() => this.$refs.list.scrollTop = 0, 300);
  }

}
