
import { Component, Prop, Vue } from "vue-property-decorator";

import ucFirst from "@/functions/ucFirst";
import cdnSource from "@/functions/cdnSource";

@Component
export default class SelectDate extends Vue {

  @Prop({ required: true }) value!: string;
  @Prop({ default: 'view' }) position!: string;
  @Prop({ default: false }) disablePrevious!: boolean;

  $refs!: {
    selectDate: HTMLDivElement
  };

  type = "date";

  selectedDate = "";

  hour = 12;
  minute = 0;

  day = 0;
  month = 0;
  year = 0;
  monthLocalized = "";

  date = this.$moment().utcOffset(0);
  calendar = new Array(42).fill(null);

  cdn = {
    chevron: cdnSource('@/assets/img/app/calendar-chevron.svg')
  };

  get formattedDate() {
    return this.$moment(this.selectedDate)
      .utc(true)
      .local()
      .format('LL');
  }

  get formattedHour() {
    if (this.hour < 10) {
      return  "0" + this.hour;
    }

    return this.hour;
  }

  get formattedMinute() {
    if (this.minute < 10) {
      return  "0" + this.minute;
    }

    return this.minute;
  }

  mounted() {
    this.generateCalendar(new Date());
  }

  isDateDisabled(date: string) {
    const currentDate = this.$moment().utcOffset(0);
    const difDates = currentDate.utcOffset(0).diff(date, 'days');

    return difDates > 0;
  }

  setInfo(_date: Date | string) {
    const date = this.getLocalDate(_date);

    this.day = date.day();
    this.month = date.month();
    this.year = date.year();

    this.monthLocalized = ucFirst(this.date.format('MMMM'));
  }

  getLocalDate(date: Date | string) {
    return this.$moment(date)
      .utc(true)
      .local();
  }

  getDayFormat(day: number) {
    const date = this.$moment();
    const dayString = date.day(day).format("dd");

    return ucFirst(dayString);
  }

  generateCalendar(date: Date | string) {
    const currentMonth = this.getLocalDate(date);

    // Set current month
    const daysInCurMonth = currentMonth.daysInMonth();

    const startOfCurMonth = currentMonth.startOf('month').format('YYYY-MM-DD');

    let _startOfCurWeekDay = this.getLocalDate(startOfCurMonth).day() - 1;
    const startOfCurWeekDay = _startOfCurWeekDay !== -1 ? _startOfCurWeekDay : 6;

    const calendar = new Array(42).fill(null);
    calendar.splice(startOfCurWeekDay, daysInCurMonth, ...this.generateMonthDays(date, daysInCurMonth, 'current'));

    // Set prev month
    const daysInPrevMonth = this.$moment(currentMonth)
      .subtract(1, 'month')
      .daysInMonth();

    const lastDaysInPrevMonth = this.generateMonthDays(date, daysInPrevMonth, 'previous').splice(-startOfCurWeekDay, startOfCurWeekDay);
    calendar.splice(0, startOfCurWeekDay, ...lastDaysInPrevMonth);

    // Set next month
    const daysInNextMonth = this.$moment(currentMonth)
      .add(1, 'month')
      .daysInMonth();

    const daysReady = calendar.filter((item) => item && item).length;

    const leftDaysInNextMonth = calendar.length - daysReady;
    const lastDaysInNextMonth = this.generateMonthDays(date, daysInNextMonth, 'next').splice(0, leftDaysInNextMonth);

    calendar.splice(daysReady, leftDaysInNextMonth, ...lastDaysInNextMonth);

    this.calendar = calendar;

    this.setInfo(currentMonth.format('YYYY-MM-DD'));
  }

  generateMonthDays(_date: Date | string, days: number, type: string) {
    return new Array(days).fill(null).map((_, index) => {

      const currentMonth = this.getLocalDate(_date);
      const day = index + 1;

      let date = null;

      if (type === 'current') {
        const startOfCurMonth = currentMonth.startOf('month');
        date = startOfCurMonth.add(day - 1, 'day');
      }

      if (type === 'previous') {
        const startOfPrevMonth = currentMonth
          .subtract(1, 'month')
          .startOf('month');

        date = startOfPrevMonth.add(day - 1, 'day');
      }

      if (type === 'next') {
        const startOfNextMonth = currentMonth
          .add(1, 'month')
          .startOf('month');

        date = startOfNextMonth.add(day - 1, 'day');
      }

      if (!date) {
        return;
      }

      return {
        day,
        type,
        date: date.format('YYYY-MM-DD')
      };

    });
  }

  changeMonth(type: 'prev' | 'next') {
    this.date = type === 'prev' ? (
      this.date.subtract(1, 'month')
    ) : this.date.add(1, 'month');

    this.generateCalendar(this.date.format('YYYY-MM-DD'));
  }

  dateClick(date: string) {
    this.selectedDate = date;
    this.type = "time";
  }

  changeTime(type: 'hour' | 'minute', action: 'inc' | 'dec') {
    let min = 0;
    let max = 24;

    if (type === 'minute') {
      max = 60;
    }

    if (action === 'inc') {
      if (type === 'hour') {
        this[type] += 1;
      } else {
        this[type] += 5;
      }

      if (this[type] > max) {
        this[type] = min;
      }
    } else {
      if (type === 'hour') {
        this[type] -= 1;
      } else {
        this[type] -= 5;
      }

      if (this[type] < min) {
        this[type] = max;
      }
    }
  }

  resetDatePicker() {
    this.type = "date";

    this.selectedDate = "";

    this.hour = 12;
    this.minute = 0;

    this.day = 0;
    this.month = 0;
    this.year = 0;
    this.monthLocalized = "";

    this.date = this.$moment().utcOffset(0);
    this.calendar = new Array(42).fill(null);

    this.generateCalendar(new Date());
  }

  onClickModal(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  onClickOutside() {
    this.resetDatePicker();

    this.$emit('input', false);
  }

  apply() {
    const { hour, minute, selectedDate } = this;

    const dateAndTime = this.$moment(selectedDate)
      .set({
        hour,
        minute,
        second: 0,
        millisecond: 0
      })
      .format();

    this.resetDatePicker();
    this.$emit('change', dateAndTime);
  }

  back() {
    this.type = "date";
  }

}
