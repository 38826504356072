<template>
  <div class="empty">
    <img :src="cdn.message" alt="">
    <div v-if="app.activeView === 'communication'" class="text">{{ $t('placeholders.selectChat') }}</div>
    <div v-if="app.activeView === 'structure'" class="text">{{ $t('placeholders.selectChatMultiple') }}</div>
  </div>
</template>

<script>
import cdnSource from "@/functions/cdnSource";

export default {
  props: {
    message: {
      type: String
    }
  },
  data() {
    return {
      cdn: {
        message: cdnSource('@/assets/img/message.svg')
      }
    };
  },
  computed: {
    app() {
      return this.$store.state.app;
    }
  }
};
</script>

<style lang="scss" scoped>
.empty {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 26px;

  .text {
    padding: 8px 16px;

    font-family: "Proxima Nova", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    border-radius: 111px;
    background: #EFEFEF;
  }
}
</style>
