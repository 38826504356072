
import axios from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";

import i18n from "@/plugins/i18n";

import TabsScreen from "@/screens/Tabs.vue";
import AuthScreen from "@/screens/Auth.vue";

import Notification from "@/components/Notification.vue";

import cdnSource from "@/functions/cdnSource";

interface IProps {
  baseUrl: string,
  cdnUrl: string,
  locale: string,
  token: string,
  toUser: string,
  mode: "web" | "widget" | "chat",
  updateInterval: number | string,
  readOnly: string
}

@Component({
  components: {
    Notification,
    TabsScreen,
    AuthScreen
  }
})
export default class AppChild extends Vue {

  @Prop() baseUrl!: IProps["baseUrl"];
  @Prop() cdnUrl!: IProps["cdnUrl"];
  @Prop() token!: IProps["token"];

  @Prop() locale!: IProps["locale"];
  @Prop({ default: 30 }) updateInterval!: IProps["updateInterval"];

  @Prop() toUser!: IProps["toUser"];
  @Prop() mode!: IProps["mode"];

  @Prop() readOnly!: IProps["readOnly"];

  get activeScreen() {
    return this.$store.state.app.activeScreen;
  }

  async created() {
    await this.initApp();
    this.initFont();
  }

  async initApp() {
    const baseUrl = this.baseUrl ?? process.env.VUE_APP_BASE_URL;
    const cdnUrl = this.cdnUrl ?? process.env.VUE_APP_CDN_URL;
    const token = this.token ?? process.env.VUE_APP_TOKEN;
    const mode = this.mode ?? process.env.VUE_APP_MODE;
    const locale = this.locale ?? process.env.VUE_APP_I18N_LOCALE;
    const toUser = this.toUser ?? process.env.VUE_APP_TO_USER;
    const readOnly = (this.readOnly ?? process.env.VUE_APP_READ_ONLY) === "true";

    this.$store.commit('setWidgetConfig', {
      baseUrl,
      cdnUrl,
      token,
      toUser: mode !== 'web' ? toUser : undefined,
      updateInterval: this.updateInterval,
      mode,
      readOnly
    });

    i18n.locale = locale.toLocaleLowerCase();
    i18n.fallbackLocale = locale.toLocaleLowerCase();
    this.$i18n.locale = locale.toLocaleLowerCase();

    axios.defaults.baseURL = baseUrl;

    await this.$store.dispatch('loadConfig');

    this.$root.$moment.locale(this.$i18n.locale);

    if (mode === 'web') {
      const token = localStorage.getItem('token');

      if (token) {
        try {
          const { data } = await axios.get("profile", {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          await Promise.all([
            this.$store.commit('setMe', data),
            this.$store.commit('setWidgetConfig', {
              token
            })
          ]);

          this.$store.commit('setActiveScreen', { screen: 'tab' });
        } catch (e) {
          localStorage.removeItem('token');

          this.$store.commit('setActiveScreen', { screen: 'login' });

          console.log(e);
        }
      } else {
        this.$store.commit('setActiveScreen', { screen: 'login' });
      }

      return;
    }

    this.$store.commit('setActiveScreen', { screen: 'tab' });
  }

  initFont() {
    const fonts = document.createElement("link");

    fonts.type = "text/css";
    fonts.rel = "stylesheet";
    fonts.href = cdnSource('@/assets/fonts/index.css');

    document.head.appendChild(fonts);
  }

}
